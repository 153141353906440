import Vue from 'vue';
import App from './App.vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import store from './store/index';
import request from './request/api';
import router from './router';
import VueI18n from 'vue-i18n';
import common from './utils/index';
// 导入格式化时间的模块
import moment from 'moment';
import ws from './utils/ws';

Vue.use(ElementUI);
Vue.prototype.$axios = request;
Vue.prototype.$common = common;
Vue.config.productionTip = false;
Vue.prototype.$ws = ws;

// 定义全局的时间过滤器
Vue.filter('dateFormat', function(dateStr, formatStr = 'YYYY-MM-DD HH:mm') {
    // 在过滤器的处理函数中，最后，必须 return 一个东西，这样，才是一个合法的过滤器
    return moment(dateStr).format(formatStr);
})
Vue.filter('dateEndFormat', function(dateStr, formatStr = 'HH:mm') {
        // 在过滤器的处理函数中，最后，必须 return 一个东西，这样，才是一个合法的过滤器
        return moment(dateStr).format(formatStr);
    })
    //多语言
Vue.use(VueI18n);
// 多语言实例
const i18n = new VueI18n({
    locale: (function() {
        // if (localStorage.getItem('lang')) {
        //     return localStorage.getItem('lang');
        // }
        return 'zh';
    }()),
    messages: {
        'zh': require('./assets/i18n/zh'), // 中文语言包
        'en': require('./assets/i18n/en'), // 英文语言包 
    }
});

let that = null;
//检查object是否为空
function isEmptyObject(obj) {
    for (var key in obj) {
        return false;
    }
    return true;
}

// // 全局导航钩子
router.beforeResolve(function(to, from, next) {
    // console.log(to.meta.requireAuth)
    // console.log(to.name)
    // 判断该路由是否需要登录权限
    if (to.meta.requireAuth) {
        var userSession = '';
        var ckLg = store.state.user;
        if (ckLg && ckLg != 'undefined') {
            userSession = common.toObj(ckLg);
        }
        // console.log(userSession)
        if (userSession) {
            // 通过vuex state获取当前的token是否存在 store.state.user
            if (!isEmptyObject(userSession)) {
                next();
            } else {
                next({ // 需要登录信息，未登录时的跳转
                    path: '/login'
                })
            }
        } else {
            next({ // 用户session是不存在
                path: '/login'
            })
        }
    } else {
        next();
    }
    if (!to.name) {
        next({ // 用户session是不存在
            path: '/login'
        })
    }
});

new Vue({
    i18n, //多语言
    router,
    store,
    render: h => h(App),
}).$mount('#app');