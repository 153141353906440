<template>
  <div>
    <div class="import">
      <div class="text">
        导入考生
        <span style="color: #5b95bd">{{ studentNum }}人</span>
        ，考生照片<span style="color: #fa0d0d">{{ photoNum }}张</span>
      </div>
      <div style="margin-top: 90px">
        <div class="uploadBtn">
          <span style="margin-right: 20px">考生名单导入</span>
          <el-upload
            class="upload-demo"
            action="#"
            :before-upload="handlePreview"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            multiple
            :limit="1"
            :on-exceed="handleExceed"
            :file-list="fileList"
          >
            <el-button size="small" class="shangchuan">导入</el-button>
          </el-upload>
          <span
            v-show="tableData.length"
            class="watchStudent"
            @click="watchStudent"
            >查看考生详情</span
          >
        </div>
      </div>
      <div class="zhuyi">
        <div>注：</div>
        <div class="point">
          <div>1、可以将考生、监考房间、监考老师编排一起导入；</div>
          <div>
            2、导入时如果只有一个名单文件，则可以只导入Excel文件，需要导入多个文件时则需放到一个ZIP包中导入；
          </div>
          <div>
            3、导入时如果包含考生照片，需要将考生名单和照片放到一个ZIP包中导入。
          </div>
        </div>
      </div>
      <div class="upload">
        <a href="考生导入.zip" download="考生导入.zip"> 下载导入模板 </a>
      </div>
    </div>
    <div class="foot">
      <el-button class="creatExam" @click="createExam('top')">上一步</el-button>
      <el-button class="creatExam" @click="createExam('bottom')"
        >下一步</el-button
      >
    </div>
    <el-dialog
      title="考生名单导入详情"
      :visible.sync="dialogVisible"
      width="80%"
    >
      <div class="table">
        <el-table
          ref="multipleTable"
          id="exportTab"
          :data="tableData"
          :header-cell-style="{
            textAlign: 'center',
            background: '#5B95BD',
            fontSize: ' 16px',
            fontFamily: ' PingFang SC',
            fontWeight: 500,
            color: '#FFFFFF',
          }"
          height="500px"
          :cell-style="{ textAlign: 'center' }"
          :row-class-name="tableRowClassName"
          style="width: 100%"
        >
          <el-table-column prop="姓名" label="考生姓名" width="120">
            <template slot-scope="scope">
              <span>{{ scope.row["姓名"] }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="报名号" label="考生准考证号">
            <template slot-scope="scope">
              <span>{{ scope.row["报名号"] }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="证件号" label="考生身份证号">
            <template slot-scope="scope">
              <span>{{ scope.row["证件号"] }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="考生照片">
            <template slot-scope="scope">
              <el-button
                v-show="scope.row['照片']"
                @click="watchFun(scope.row)"
                type="text"
                size="small"
                >查看</el-button
              >
              <span v-show="!scope.row['照片']" style="color: red">未上传</span>
            </template>
          </el-table-column>
          <el-table-column prop="密码" label="考生初始密码">
            <template slot-scope="scope">
              <span>{{ scope.row["密码"] }}</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="footer">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[20, 50, 100]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="考生照片" :visible.sync="photoDialog" width="25%">
      <img :src="imgUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
let that;
let import_examineeUrl = "/jiankao/import_examinee";
let roomUrl = "/jiankao/room";
let examineeUrl = "/jiankao/examinee";
let userUrl = "/jiankao/user";
import { getFun, postFun, deleteFun, putFun } from "../../../request/request";
export default {
  name: "StudentImport",
  data() {
    return {
      photoDialog: false,
      dialogVisible: false,
      fileList: [],
      value: "",
      input2: "",
      tableData: [
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-08",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-06",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-07",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-07",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-07",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-07",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
      ],
      currentPage4: 1,
      pagesize: 20,
      total: 0,
      studentObj: {},
      studentNum: 0,
      photoNum: 0,
      imgUrl: "",
      ishow: false,
    };
  },
  methods: {
    /**
     * 查看考生照片
     */
    watchFun(val) {
      that.photoDialog = true;
      that.imgUrl = `https://jiankao.zhifz.com/file/show/${val["照片"]}`;
    },
    /**
     * 查看考生名单
     */
    getStudent() {
      let param = {
        考试ID: that.$route.query["考试ID"],
        批次ID: that.$route.query["批次ID"],
        机构ID: that.$route.query["机构ID"],
        最大记录数: that.pagesize,
        跳过记录数: that.pagesize * (that.currentPage4 - 1),
      };
      that.photoNum = 0;
      that.studentNum = 0;
      that.tableData = [];
      getFun(examineeUrl, param).then((res) => {
        if (res.data && res.result) {
          that.total = res.total_records;
          that.tableData = res.data;
          let arr = [];
          that.tableData.forEach((item) => {
            arr.push(item.UID);
          });
          let param = {
            UID: JSON.stringify(arr),
            用户类型: 2,
            返回照片: true,
          };
          getFun(userUrl, param).then((user) => {
            if (user.result && user.data) {
              that.tableData.forEach((item) => {
                item["照片"] = null;
                user.data.forEach((items) => {
                  if (item.UID == items.UID) {
                    item["照片"] = items["照片"];
                  }
                });
                if (item["照片"]) {
                  that.photoNum += 1;
                }
              });
              that.studentNum = that.tableData.length;

              that.$forceUpdate();
            }
          });
        }
      });
    },
    watchStudent() {
      that.dialogVisible = true;
      that.getStudent();
    },

    handleClick(row) {
      console.log(row);
    },

    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex; //为每行数据对象添加“索引”属性，在handleSelectionChange方法中会用到
      row = this.tableData;
      if (row[rowIndex].isHighLight === true) {
        return "rowHighLight"; //若isHighLight为true，则使用“rowHighLight”样式
      } else {
        return "";
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      if (!that.$route.query["批次ID"]) {
        this.$message({
          type: "error",
          message: "无批次信息，请先选择批次",
        });
        return;
      }
      let formDt = new FormData();
      formDt.append("批次ID", that.$route.query["批次ID"]);
      formDt.append("机构ID", that.$route.query["机构ID"]);
      formDt.append("考试ID", that.$route.query["考试ID"]);
      formDt.append("考生数据文件", file);
      formDt.append("调试模式", "ZDFGsdxcvsdf");
      // return;
      postFun(import_examineeUrl, formDt).then((res) => {
        console.log(res)
        if (res.result && res.data) {
          let url = "https://jiankao.zhifz.com" + res.data["任务URL"];
          let interval = setInterval(() => {
            getFun(url).then((data) => {
              console.log(data);
              if (data.data["已结束"] && data.result) {
                that.getStudent();
                this.$message({
                  type: "success",
                  message: "导入考生成功",
                });
                clearInterval(interval);
              }
            });
          }, 3000);
          // getFun(url).then((data) => {
          //   if (data.data && data.result) {
          //     if (data.data["任务数据"]) {
          //       if (
          //         data.data["任务数据"].result &&
          //         data.data["任务数据"].data
          //       ) {
          //         that.studentObj = data.data["任务数据"].data;
          //       }
          //     }
          //   }
          // });
        }
      });
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    submitFun() {
      that.dialogVisible = false;
    },
    // 路由跳转
    createExam(val) {
      if (val == "top") {
        that.$router.push({ path: "/addExam", query: that.$route.query });
      } else {
        that.$router.push({ path: "/createRoom", query: that.$route.query });
      }
    },
    handleSizeChange(val) {
      that.pagesize = val;
      that.getStudent();
    },
    handleCurrentChange(val) {
      that.currentPage4 = val;
      that.getStudent();
    },
  },
  mounted() {
    that.getStudent();
  },
  created() {
    that = this;
  },
};
</script>

<style scoped>

/deep/.el-icon-upload-success {
  display: none !important;
}
/deep/.el-upload-list__item .el-icon-close {
  display: none !important;
}
.el-dialog__body img {
  width: 240px;
}
/deep/.el-dialog__body {
  text-align: center;
}
.watchStudent:hover {
  color: #5b95bd;
}
.watchStudent {
  cursor: pointer;
  margin-left: 20px;
  font-size: 12px;
}
.footer {
  display: flex;
  margin-top: 15px;
  justify-content: flex-end;
}
/deep/.search .el-input__inner {
  background: #f6f6f6 !important;
}
.dialogTop {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.dialogText {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #1d394d;
}
.text {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #1d394d;
  position: absolute;
  top: 10px;
  left: 15px;
}
.text span {
  font-size: 16px;
  font-weight: bold;
}
.uploadBtn {
  display: flex;
  /* align-items: center; */
  line-height: 30px;
  height: 100px;
  overflow-y: hidden;
}
.upload {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #5b95bd;
  position: absolute;
  bottom: 15px;
  right: 40px;
  cursor: pointer;
}
.point div {
  margin-bottom: 8px;
}
.zhuyi {
  width: 730px;
  height: 106px;
  background: #fff8f8;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #dd1c1c;
  padding: 30px 0 0 30px;
  display: flex;
}
.export {
  width: 170px;
  height: 30px;
  background: #eef8ff;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #5b95bd;
  margin-left: 15px;
}
.foot {
  margin-top: 50px;
  text-align: center;
}
.shangchuan {
  width: 170px;
  height: 30px;
  background: #eef8ff;
  border-radius: 4px;
}
.import {
  width: 840px;
  height: 488px;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: relative;
}
.creatExam {
  width: 300px;
  height: 44px;
  background: #5b95bd;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
/deep/.el-dialog__title {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin-left: 20px;
}
/deep/.el-dialog__headerbtn {
  top: 15px;
}
/deep/.el-dialog__header {
  background: #1d394d;
  height: 40px;
  padding: 0 20px;
  display: flex;
  align-items: center;
}
</style>

