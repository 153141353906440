<template>
  <div class="box">
    <div class="header">
      <el-button
        :class="$route.meta.pagename == '考生名单' ? 'active' : 'manage'"
        size="small"
        @click="changeList('考生名单')"
        >考生名单</el-button
      >
      <el-button
        :class="$route.meta.pagename == '监考老师名单' ? 'active' : 'manage'"
        size="small"
        @click="changeList('监考老师名单')"
        >监考老师名单</el-button
      >
      <el-button
        :class="$route.meta.pagename == '监考房间名单' ? 'active' : 'manage'"
        size="small"
        @click="changeList('监考房间名单')"
        >监考房间名单</el-button
      >
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
let that;
export default {
  data() {
    return {};
  },
  methods: {
    changeList(val) {
      if (val == "考生名单") {
        this.$router.push({ name: "StudentList",query: that.$route.query  });
      }
      if (val == "监考老师名单") {
        this.$router.push({ name: "TeacherList" ,query: that.$route.query  });
      }
      if (val == "监考房间名单") {
        this.$router.push({ name: "RoomList" ,query: that.$route.query  });
      }
      that.$route.meta.isAdd = that.$route.query['考试名称']
    },
  },
  mounted() {
    that.$route.meta.isAdd = that.$route.query['考试名称']
    that.$router.push({ path: "/studentList", query: that.$route.query });
  },
  created() {
    that = this;
  },
};
</script>

<style scoped>
.active {
  background-color: #fff;
  background: #5b95bd;
  border-radius: 4px;
  border-radius: 4px;
  border: none !important;
  height: 30px;
  color: #fff;
}
.box {
  padding: 22px 19px 22px 19px;
  background-color: #fff;
  overflow-y: auto;
}
/deep/.manage {
  background: #f6f6f6;
  border-radius: 4px;
  border: none !important;
  height: 30px;
}
</style>