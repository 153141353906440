import axios from 'axios'
import { Message, Loading } from 'element-ui'
import common from '../utils/index';
let loadingInstance = null //这里是loading
    // 环境的切换
if (process.env.NODE_ENV == 'development') {
    axios.defaults.baseURL = '/api/api';
} else if (process.env.NODE_ENV == 'debug') {
    axios.defaults.baseURL = '/api';
} else if (process.env.NODE_ENV == 'production') {
    axios.defaults.baseURL = '/api';
}

let that = this;

//使用create方法创建axios实例
const Service = axios.create({
        timeout: 7000, // 请求超时时间
        // baseURL: ConfigBaseURL,
        // method: 'post',
        // headers: {
        //     'Content-Type': 'application/json;charset=UTF-8'
        // }
    })
    // 添加请求拦截器
Service.interceptors.request.use(config => {
        loadingInstance = Loading.service({
            lock: true,
            text: 'loading...'
        })
        return config
    })
    // 添加响应拦截器
Service.interceptors.response.use(response => {
    if (response.data.result) {
        let url = response.config.url.substring(0, response.config.url.indexOf("?"))
        if (url == '/jiankao/login') {
            let usrNekot = response.data.data['用户令牌']
            common.localStg("set", "myToken", usrNekot);
        }
    } else {
        Message({
            message: response.data.error,
            type: 'error',
            duration: 3 * 1000
        })
    }
    loadingInstance.close()

    return response.data
}, error => {
    console.log('TCL: error', error)
    const msg = error.Message !== undefined ? error.Message : ''
    Message({
        message: error.response.data.error,
        type: 'error',
        duration: 3 * 1000
    })
    loadingInstance.close()
    return Promise.reject(error)
})

export default Service