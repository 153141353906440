<template>
  <div class="addExam">
    <div class="content">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="考试名称">
          <el-col :span="16">
            <el-input v-model="form['考试名称']"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="考试准备时间">
          开考前
          <el-input class="start" v-model="form['考前准备时间']"></el-input>
          分钟
        </el-form-item>
        <el-form-item label="开始考试时间">
          <el-col :span="16">
            <el-date-picker
              type="datetime"
              placeholder="选择日期"
              v-model="form['考试开始时间']"
              value-format="yyyy-MM-dd HH:mm"
              style="width: 100%"
            ></el-date-picker>
          </el-col>
        </el-form-item>
        <el-form-item label="考试结束时间">
          <el-col :span="16">
            <el-date-picker
              type="datetime"
              placeholder="选择日期"
              v-model="form['考试结束时间']"
              value-format="yyyy-MM-dd HH:mm"
              style="width: 100%"
            ></el-date-picker>
          </el-col>
        </el-form-item>
        <el-form-item label="考试设置">
          <el-row>
            <span class="setlabel">报名照片</span>
            <el-radio-group
              @change="photoChange"
              v-model="form['考试设置']['报名照片']"
            >
              <el-radio :label="true">有</el-radio>
              <el-radio :label="false">无</el-radio>
            </el-radio-group>
          </el-row>
          <el-row>
            <span class="setlabel">登录方式</span>
            <el-radio-group v-model="form['考试设置']['登录方式']">
              <el-radio :label="1" value="1">账号+密码</el-radio>
            </el-radio-group>
          </el-row>
          <el-row>
            <span class="setlabel"
              >人脸识别<el-popover
                placement="top-start"
                width="200"
                trigger="hover"
                content="考生与考生上传的照片进行人脸比对，检测是否为考生本人"
              >
                <i slot="reference" class="el-icon-question"></i> </el-popover
            ></span>
            <el-radio-group
              :disabled="form['考试设置']['报名照片'] ? false : true"
              @change="faceChange"
              v-model="form['考试设置']['人脸识别']"
            >
              <el-radio :label="true" value="true">开启</el-radio>
              <el-radio :label="false" value="false">不开启</el-radio>
            </el-radio-group>
          </el-row>
          <el-row>
            <span class="setlabel">是否允许人脸识别5次失败之后自动跳过</span>
            <el-radio-group
              :disabled="
                form['考试设置']['报名照片']
                  ? form['考试设置']['人脸识别'] == 1
                    ? false
                    : true
                  : true
              "
              v-model="form['考试设置']['自动跳过']"
            >
              <el-radio :label="true">允许</el-radio>
              <el-radio :label="false">不允许</el-radio>
            </el-radio-group>
          </el-row>
          <el-row>
            <span class="setlabel"
              >AI行为分析
              <el-popover
                placement="top-start"
                width="200"
                trigger="hover"
                content="开启后可以实时监考考生的异常行为（如：视频出现多人或没人、考生低头、不是考生本人等其他异常行为）"
              >
                <i slot="reference" class="el-icon-question"></i>
              </el-popover>
            </span>
            <el-radio-group v-model="form['考试设置']['AI行为分析']">
              <el-radio :label="true">开启</el-radio>
              <el-radio :label="false">不开启</el-radio>
            </el-radio-group>
          </el-row>
          <el-row>
            <span class="setlabel"
              >正面抓取考生照片

              <el-popover
                placement="top-start"
                width="200"
                trigger="hover"
                content="允许后可以定时抓取考生一机位的正面监控图片"
              >
                <i slot="reference" class="el-icon-question"></i>
              </el-popover>
            </span>
            <el-radio-group v-model="form['考试设置']['一机位抓拍']">
              <el-radio :label="true">允许</el-radio>
              <el-radio :label="false">不允许</el-radio>
            </el-radio-group>
            <el-select
              v-model="form['考试设置']['抓拍考生时间间隔']"
              size="small"
              placeholder="请选择"
              v-show="form['考试设置']['一机位抓拍'] ? true : false"
              :disabled="true"
              style="width: 90px; margin-left: 30px"
            >
              <el-option label="5分钟" value="5" size="small"> </el-option>
            </el-select>
          </el-row>
          <el-row>
            <span class="setlabel"
              >监控方式
              <el-popover
                placement="top-start"
                width="200"
                trigger="hover"
                content="正面监控：考生电脑一机位的监控；侧面监控：考生手机/平板二机位的监控；双机位监控：电脑和手机/平板同时监控"
              >
                <i slot="reference" class="el-icon-question"></i>
              </el-popover>
            </span>
            <el-radio-group v-model="form['考试设置']['监控方式']">
              <el-radio :label="1" name="type">启用双机位监控</el-radio>
              <el-radio :label="2" name="type">启用正面监控</el-radio>
              <el-radio :label="3" name="type">启用侧面监控</el-radio>
            </el-radio-group>
          </el-row>
          <el-row>
            <span class="setlabel"
              >监控视频录制
              <el-popover
                placement="top-start"
                width="200"
                trigger="hover"
                content="对考生的监控视频进行全程录制（根据监控方式的选择来确定录制的机位）"
              >
                <i slot="reference" class="el-icon-question"></i>
              </el-popover>
            </span>
            <el-radio-group v-model="form['考试设置']['录制监控视频']">
              <el-radio :label="true">允许</el-radio>
              <el-radio :label="false">不允许</el-radio>
            </el-radio-group>
          </el-row>
          <el-row>
            <span class="setlabel">考试屏幕录制</span>
            <el-radio-group v-model="form['考试设置']['考试屏幕录制']">
              <el-radio :label="true">允许</el-radio>
              <el-radio :label="false">不允许</el-radio>
            </el-radio-group>
          </el-row>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-button class="creatExam" @click="createExam">{{
        $route.query.isAdd == "false" ? "修改考试" : "创建考试"
      }}</el-button>
      <el-button
        class="creatExam"
        v-show="$route.query.isAdd == 'false'"
        @click="nextPage"
        >下一步</el-button
      >
    </div>
  </div>
</template>

<script>
let that;
let examUrl = "/jiankao/exam";
import { getFun, postFun, deleteFun, putFun } from "../../../request/request";
import { mapState, mapMutations } from "vuex";
export default {
  name: "AddExam",
  data() {
    return {
      radio: "",
      input2: "",
      form: {
        考试名称: "",
        考前准备时间: "",
        考试开始时间: "",
        考试结束时间: "",
        考试设置: {
          考试屏幕录制: true,
          录制监控视频: true,
          抓拍考生时间间隔: 5,
          监控方式: 1,
          人脸识别: true,
          一机位抓拍: true,
          AI行为分析: true,
          自动跳过: false,
          登录方式: 1,
          报名照片: true,
        },
      },
    };
  },
  computed: {
    ...mapState(["user", "routerName"]),
  },
  methods: {
    faceChange(val) {
      if (val) {
        that.form["考试设置"]["自动跳过"] = true;
      } else {
        that.form["考试设置"]["自动跳过"] = false;
      }
    },
    /**
     * 照片有无
     */
    photoChange(val) {
      if (val) {
        that.form["考试设置"]["自动跳过"] = true;
        that.form["考试设置"]["人脸识别"] = true;
      } else {
        that.form["考试设置"]["人脸识别"] = false;
        that.form["考试设置"]["自动跳过"] = false;
      }
    },
    /**
     * 下一步
     */
    nextPage() {
      that.$router.push({ path: "/studentImport", query: that.$route.query });
    },
    /**
     * 创建考试
     * 监控方式：1正面监控，2侧面监控，3双机位监控
     * 登录方式：1人脸+账号密码，2人脸识别，3账号密码
     */
    createExam() {
      if (!that.$route.query["批次ID"]) {
        this.$message({
          type: "error",
          message: "未选择批次!",
        });
        return;
      }
      if (!that.$route.query["机构ID"]) {
        this.$message({
          type: "error",
          message: "未选择机构!",
        });
        return;
      }
      if (that.$route.query.isAdd == "true") {
        let param = {};
        param = that.form;
        param["批次ID"] = that.$route.query["批次ID"];
        param["机构ID"] = that.$route.query["机构ID"];
        console.log(param);
        // return;
        param["考试设置"] = JSON.stringify(param["考试设置"]);
        putFun(examUrl, param).then((res) => {
          if (res.result && res.data) {
            this.$message({
              type: "success",
              message: "创建成功!",
            });
            that.$router.push({
              path: "/studentImport",
              query: {
                批次ID: that.$route.query["批次ID"],
                考试ID: res.data["考试ID"],
                机构ID: that.$route.query["机构ID"],
              },
            });
          }
        });
      }
      if (that.$route.query.isAdd == "false") {
        let param = {};
        param["批次ID"] = that.form["批次ID"] * 1;
        param["机构ID"] = that.form["机构ID"] * 1;
        param["考试ID"] = that.form["考试ID"] * 1;
        param["考试名称"] = that.form["考试名称"];
        param["考试开始时间"] = that.form["考试开始时间"];
        param["考试结束时间"] = that.form["考试结束时间"];
        param["考前准备时间"] = that.form["考前准备时间"];
        param["调试模式"] = "ZDFGsdxcvsdf";
        param["考试设置"] = JSON.stringify(that.form["考试设置"]);
        // param["考试设置"] = that.form["考试设置"];
        // return
        postFun(examUrl, param, "updateExam").then((res) => {
          if (res.result) {
            this.$message({
              type: "success",
              message: "修改成功!",
            });
            that.$router.push({
              path: "/studentImport",
              query: {
                批次ID: that.$route.query["批次ID"],
                考试ID: that.$route.query["考试ID"],
              },
            });
          }
        });
      }
    },
  },
  created() {
    that = this;
    if (that.$route.query.isAdd == "false") {
      that.form = that.$route.query;
      if (!that.$route.query["考试设置"]) {
        that.form["考试设置"] = {
          考试屏幕录制: true,
          录制监控视频: true,
          抓拍考生时间间隔: 5,
          监控方式: 1,
          一机位抓拍: true,
          AI行为分析: true,
          自动跳过: false,
          登录方式: 1,
          报名照片: true,
        };
      }
    }
  },
};
</script>

<style scoped>
.start {
  width: 50px;
}
.setlabel {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #1d394d;
  margin-left: 10px;
  margin-right: 20px;
}
.el-checkbox-group {
  display: inline-block;
  line-height: 30px;
}

.el-form-item {
  margin-bottom: 13px;
}
.creatExam {
  width: 300px;
  height: 44px;
  background: #5b95bd;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin-top: 30px;
}
.content {
  width: 1012px;
  height: 590px;
  background: #ffffff;
  border-radius: 4px;
  padding-top: 15px;
  display: flex;
  justify-content: center;
}
.addExam {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>