<template>
  <div class="login">
    <div>
      <img src="../assets/image/ic_denglu.png" style="width: 568px" alt="" />
    </div>
    <div class="userlogin">
      <div class="logo">LOGO</div>
      <div class="inputBox">
        <el-input
          placeholder="请输入账号"
          v-model="zhanghao"
          style="margin-bottom: 30px"
        >
          <img
            slot="prefix"
            src="../assets/image/ic_yonghu1  拷贝.png"
            alt=""
          />
        </el-input>
        <el-input placeholder="请输入密码" v-model="password" show-password>
          <img slot="prefix" src="../assets/image/ic_mima1 拷贝.png" alt="" />
        </el-input>
      </div>
      <el-button class="loginbtn" @click="login">登录</el-button>
    </div>
  </div>
</template>

<script>
let that;
import { getFun, postFun, deleteFun, putFun } from "../request/request";
import { mapState, mapMutations } from "vuex";

let loginUrl = "/jiankao/login";
let user_roleUrl = "/jiankao/user_role";
export default {
  name: "Login",
  data() {
    return {
      zhanghao: "",
      password: "",
    };
  },
  methods: {
    ...mapMutations(["updateUserInfo"]),
    login() {
      let param = {
        用户名: that.zhanghao,
        密码: that.password,
      };
      postFun(loginUrl, param).then((res) => {
        if (res.data && res.result) {
          let userRole = {
            UID: res.data["用户信息"].UID,
          };

          getFun(user_roleUrl, userRole).then((user) => {
            if (user.result && user.data) {
              let obj = {};
              obj = res.data["用户信息"];
              obj["用户令牌"] = res.data["用户令牌"];
              obj["用户权限"] = user.data;
              that.updateUserInfo(obj);
              that.$forceUpdate();
              if (obj["姓名"] == "admin") {
                that.$router.push({ path: "/adminManage" });
              } else {
                that.$router.push({ path: "/firstPage" });
              }
            }
            that.$router.push({ name: "Main" });
          });
        }
      });
    },
    // 回车键登录
    loginKeyDown(e) {
      let lcHash = location.hash;
      //如果是回车则执行登录方法
      if (e.keyCode === 13 || e.keyCode === 108) {
        // 解决其他页面点enter时的触发的跳转bug
        if(lcHash && lcHash == '#/login'){
          that.login("logInPar");
        }
      }
    },
  },
  created() {
    that = this;
    let lcHash = location.hash;
    // 解决其他页面点enter时的触发的跳转bug
    if(lcHash && lcHash == '#/login'){
      window.addEventListener("keydown", that.loginKeyDown);
    }
  },
};
</script>

<style scoped>
.loginbtn {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;
  width: 420px;
  background: #5b95bd;
  border-radius: 4px;
  border: none;
  margin-bottom: 60px;
}
.logo {
  font-size: 60px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #5b95bd;
  margin: 90px 0 78px 0;
}
/deep/.el-input__prefix {
  display: flex;
  align-items: center;
  padding-left: 5px;
}
/deep/.inputBox .el-input__inner {
  background: #f5f5f5;
  border-radius: 4px;
  border: none;
  padding-left: 40px;
}
.inputBox img {
  width: 17px;
}
.inputBox {
  width: 425px;
  margin-bottom: 100px;
}
.userlogin {
  width: 590px;
  background: #ffffff;
  box-shadow: 0px 3px 12px 1px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login {
  width: 100%;
  height: 100%;
  background: linear-gradient(3deg, #4ea4fa, #9eccff);
  display: flex;
  justify-content: space-around;
  align-items: center;
}
</style>