<template>
  <div class="pici">
    <div class="box">
      <div class="btn">
        <div class="btn_no1">
          <el-button size="small" @click="addpici('add')">新建批次</el-button>
        </div>
        <div class="seach">
          <el-input
            placeholder="请输入内容"
            suffix-icon="el-icon-search"
            style="width: 200px"
            size="small"
            v-model="input2"
          >
          </el-input>
        </div>
      </div>
      <div>
        <el-table
          ref="multipleTable"
          :data="tableData"
          :header-cell-style="{
            textAlign: 'center',
            background: '#5B95BD',
            fontSize: ' 16px',
            fontFamily: ' PingFang SC',
            fontWeight: 500,
            color: '#FFFFFF',
          }"
          :cell-style="{ textAlign: 'center' }"
          style="width: 100%"
        >
          <el-table-column prop="批次名称" label="批次名称" width="120">
          </el-table-column>
          <el-table-column prop="批次代码" label="批次代码"> </el-table-column>
          <el-table-column prop="考试数量" label="考试数量"> </el-table-column>
          <el-table-column prop="创建时间" label="创建时间">
            <template slot-scope="scope">
              {{ scope.row["创建时间"] | dateFormat }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <el-button
                @click="addpici('updata', scope.row)"
                type="text"
                size="small"
                >修改</el-button
              >
              <el-button type="text" size="small" @click="deletePici(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="footer">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[20, 50, 100]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog :title="piciName" :visible.sync="dialogVisible" width="25%">
      <div class="dialogBtn">
        <el-button size="small" class="cancel" @click="cancel('adduser')"
          >取消</el-button
        >
        <el-button
          size="small"
          :disabled="isshow"
          class="addbtn"
          @click="submit('user')"
          >{{ piciName == "新增批次" ? "新增" : "修改" }}</el-button
        >
      </div>
      <div class="dilogTop">
        <div class="dialbox">
          <div style="width: 80px">批次名称</div>
          <el-input
            placeholder="请输入内容"
            size="small"
            v-model="form['批次名称']"
          >
          </el-input>
        </div>
        <div class="dialbox">
          <div style="width: 80px">批次代码</div>
          <el-input
            placeholder="请输入内容"
            size="small"
            v-model="form['批次代码']"
          >
          </el-input>
          <span class="pcdm" v-show="isshow">该批次代码已存在</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
let that;
let batchUrl = "/jiankao/batch";
let examUrl = "/jiankao/exam";
import { mapState, mapMutations } from "vuex";
import { getFun, postFun, deleteFun, putFun } from "../../request/request";
export default {
  name: "PiciManage",
  data() {
    return {
      form: {
        批次名称: "",
        批次代码: "",
      },
      value: "",
      piciName: "",
      tableData: [],
      currentPage4: 1,
      pagesize: 20,
      total: 100,
      input2: "",
      dialogVisible: false,
      value: "",
      copyList: [],
      isshow: false,
    };
  },
  watch: {
    input2: {
      handler() {
        const realList = that.copyList.filter((order) => {
          return Object.keys(that.input2).reduce((flag, item) => {
            if (!flag) {
              return false;
            } else {
              return that.input2[item].trim()
                ? String(order["批次名称"]).indexOf(that.input2[item]) !== -1
                : true;
            }
          }, true);
        });
        that.tableData = realList;
      },
      deep: true,
    },
    form: {
      handler(newval) {
        let pcdm = that.tableData.some((item) => {
          if (newval["批次ID"] != item["批次ID"]) {
            return newval["批次代码"] == item["批次代码"];
          }
        });
        if (pcdm) {
          that.isshow = true;
        } else {
          that.isshow = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    /**
     * 删除批次
     */
    deletePici(row) {
      let prarm = {
        批次ID: row["批次ID"],
      };
      this.$confirm("此操作将删除该批次, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteFun(batchUrl, prarm).then((res) => {
            if (res.result) {
              that.getPici();
            }
          });
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    addpici(val, row) {
      that.dialogVisible = true;
      if (val == "add") {
        that.piciName = "新增批次";
      } else {
        that.piciName = "修改批次";
        that.form["批次ID"] = row["批次ID"];
        that.form["批次名称"] = row["批次名称"];
        that.form["批次代码"] = row["批次代码"];
      }
    },
    /**
     * 获取考试信息
     */
    getExamNum() {
      let arr = [];
      that.tableData.forEach((item) => {
        arr.push(item["批次ID"]);
      });
      let param = {
        批次ID: JSON.stringify(arr),
      };
      getFun(examUrl, param).then((res) => {
        if (res.data && res.result) {
          that.tableData.forEach((item) => {
            item["考试数量"] = 0;
            res.data.forEach((items) => {
              if (item["批次ID"] == items["批次ID"]) {
                item["考试数量"] += 1;
              }
            });
          });
          that.copyList = JSON.parse(JSON.stringify(that.tableData));

          that.$forceUpdate();
        }
      });
    },
    /**
     * 获取批次数据
     */
    getPici() {
      let param = {
        最大记录数: that.pagesize,
        跳过记录数: that.pagesize * (that.currentPage4 - 1),
        状态: 1,
      };
      getFun(batchUrl, param).then((res) => {
        if (res.data && res.result) {
          that.total = res.total_records;
          that.tableData = res.data;
          that.getExamNum(res.data);
        }
      });
    },
    /**
     * 数据提交
     */
    submit(val) {
      that.dialogVisible = false;
      let param = {};
      param = that.form;
      if (that.piciName == "新增批次") {
        putFun(batchUrl, param).then((res) => {
          if (res.data && res.result) {
            that.getPici();
            that.$message({
              message: "新建批次成功",
              type: "success",
            });
          }
        });
      } else {
        postFun(batchUrl, param).then((res) => {
          if (res.result) {
            that.getPici();
            that.$message({
              message: "修改批次成功",
              type: "success",
            });
          }
        });
      }
    },
    cancel(val) {
      that.dialogVisible = false;
      that.form = {
        批次名称: "",
        批次代码: "",
      };
    },
    handleSelectionChange() {},
    handleClick(row) {
      that.dialogVisible = true;
    },
    handleSizeChange(val) {
      that.pagesize = val;
    },
    handleCurrentChange(val) {
      that.currentPage4 = val;
    },
  },
  mounted() {
    that.getPici();
  },
  created() {
    that = this;
  },
};
</script>

<style scoped>
.pcdm {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #eb0303;
  position: absolute;
  bottom: -17px;
  left: 115px;
}
/deep/.el-dialog__body {
  width: 100%;
}
/deep/.dialbox .el-input__inner {
  width: 80%;
  background-color: #f6f6f6;
  border: none;
}
.dialbox > div {
  margin-right: 15px;
  margin-left: 20px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #1d394d;
}
.dialbox {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
  position: relative;
}
.cancel {
  width: 64px;
  height: 30px;
  background: #dd1c1c;
  border-radius: 4px;
  color: #fff;
  border: none;
}
.addbtn {
  width: 64px;
  height: 30px;
  background: #5b95bd;
  border-radius: 4px;
  color: #fff;
  border: none;
}
.dialogBtn {
  position: absolute;
  top: 5px;
  right: 5px;
}
.el-dialog {
  position: relative;
}
/deep/.el-dialog__headerbtn {
  top: 15px;
}
/deep/.el-dialog__title {
  color: #fff !important;
}
/deep/.el-dialog__header {
  background: #1d394d;
  height: 40px;
  padding: 0 20px;
  display: flex;
  align-items: center;
}
.dialogTop {
  margin-bottom: 15px;
  width: 80%;
}
.shuju {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #5b95bd;
  width: 90px;
  height: 30px;
  background: #eef8ff;
  border-radius: 4px;
}
.footer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
.num {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #5b95bd;
}
/deep/.seach .el-input__inner {
  background: #f6f6f6;
  border: none;
}
.btn_no1 {
  display: flex;
}
.btn {
  display: flex;
  margin: 27px 0 17px 0;
  justify-content: space-between;
}
.btn .el-button {
  height: 30px;
  background: #eef8ff;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #5b95bd;
  border: none !important;
}
.box {
  padding: 1px 22px 19px 22px;
  background-color: #fff;
}
.pici {
  width: 100%;
  height: 100%;
}
</style>