<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import Main from "./views/Main.vue";

export default {
  name: "App",
  components: {
    Main,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}
body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;
}
.el-main{
  position: relative;
  height: calc(100vh - 60px);
}
/* .el-icon-yonghu {
  content: url("../assets/image/ic_yonghu1  拷贝.png");
}
.el-icon-guanli1 {
  content: url("../assets/image/ic_guanli1.png");
}
.el-icon-guanli2 {
  content: url("../assets/image/ic_guanli1(1).png");
}
.el-icon-kaoshiliebiao1 {
  content: url("../assets/image/ic_kaoshiliebiao1.png");
}
.el-icon-kaoshiliebiao2 {
  content: url("../assets/image/ic_kaoshiliebiao2.png");
}
.el-icon-shouye1 {
  content: url("../assets/image/ic_shouye1.png");
}
.el-icon-shouye2 {
  content: url("../assets/image/ic_shouye2.png");
}
.el-icon-jiankaofangjian1 {
  content: url("../assets/image/ic_jiankaofangjian1.png");
}
.el-icon-jiankaofangjian2 {
  content: url("../assets/image/ic_jiankaofangjian2.png");
}
.el-icon-shujukanban1 {
  content: url("../assets/image/ic_shujukanban1.png");
}
.el-icon-shujukanban2 {
  content: url("../assets/image/ic_shujukanban2.png");
}
.el-icon-piciguanli1 {
  content: url("../assets/image/ic_piciguanli1.png");
}
.el-icon-piciguanli2 {
  content: url("../assets/image/ic_piciguanli2.png");
} */
</style>
