<template>
  <div>数据看板</div>
</template>

<script>
export default {
  name: "DataShow",
};
</script>

<style scoped>
</style>