module.exports = {
    lang: {
        'noSignal': 'no signal',
        'leave': 'leave',
        'joinIn': 'join in',
        'swtCamera': 'switch camera',
        'resumeCall': 'resume call',
        'reopenEqu': 'reopen the equipment',
        'joinSuc': 'join in the monitor room successfully',
        'leaveRoom': 'leave the monitor room',
        'clkUrc': 'click the upper right corner',
        'usrSafari': 'select Safari viewer to open',
        'switching': 'switching',
        'misUsrInfo': 'Missing personal information, please scan the QR code again!',
        'reClickBtn': 'Failed to turn on the microphone or camera, please click the "reopen the equipment" button.',
        'reOpenFail': 'Failed to reopen the device',
        'resumeCall': 'The call failed, please click the "resume call" button.',
        'resumeCallFail': 'Failed to resume call',
        'talking': 'talking...',
        'sideTalking': 'phone talking...',
        'login': 'Login',
        'logout': 'Logout',
        'inputPsw': 'Enter the password',
        'inputUname': 'Enter username',
        'videoJk': 'Video Proctor',
        'quitSystem': 'Quit system',
        'setRoom': 'Set room',
        'jkRoom': 'Rooms',
        'examList': 'Exam list',
        'errCode': 'Error code: ',
        'logouting': 'Logout',
        'sltPici': 'Select the test batch',
        'backToSlt': 'Return to the selection page',
        'roomStr': 'Room',
        'addRoom': 'Add room',
        'seeStu': 'See student',
        'seeTch': 'See teacher',
        'download': 'Download',
        'addFile': 'Add file',
        'file': 'File',
        'uploading': 'Uploading',
        'clickUpload': 'Click upload',
        'clearRoom': 'Clear faculty-room associations',
        'deleteRoom': 'Delete room',
        'backPiCi': 'Back batch list',
        'jkTch': 'Invigilator',
        'jkAdmin': 'Technical Support',
        'pcName': 'Batch name',
        'operate': 'Operate',
        'roomName': 'Room name',
        'rgstNumber': 'Registration number',
        'stuName': 'Candidate Name',
        'jkTchEmail': "Invigilator's mailbox",
        'jkTchName':  "Invigilator's name",
        'jkPower': 'Invigilation authority',
        'noPiciData': 'No batch data!',
        'noRoomData': 'No room data!',
        'noData': 'No data!', 
        'roomHasClear': 'The room has been cleared!', 
        'seeRoom': 'See room',
        'missUid': 'Miss UID!',
        'dataQuerying': 'Data querying',
        'msgChlLgFail': 'Login message channel failed!',
        'msgChlJoinFail': 'Failed to join the message channel!',
        'openMicFail': 'Accessing to the mic or camera failed，check the permissions and quit the browser, then scan the QRcode again.',
        'commError': 'Communication error，refresh the browser and than scan the QRcode again.',
        'appNotAllow': 'Your app not allowed the permissions for mic and cemera using！',
        'error': 'Error: ',
        'addSideMotSuc': 'Accessing to side monitor succeed！',
        'misZcid': 'The registration ID is missing!',
        'batchAddTch': 'Add teachers',
        'misKszid': 'Exam group ID is missing!',
        'closeRoom': 'Close room',
        'left': 'Left',
        'minute': 'minute',
        'unLogin': 'Not logged',
        'noSignal': 'No video signal',
        'front': 'front',
        'side': 'side',
        'sideMotConnt': 'Side camera in a call...',
        'closeVideo': 'Close video',
        'snapFrontImg': 'Capture the front image',
        'closeSideTalk': 'Close side camera call',
        'sideTalk': 'Side camera call',
        'openMic': 'Open mic',
        'closeMic': 'Close mic',
        'openHeadset': 'Open speaker',
        'closeHeadset': 'Close speaker',
        'sendMsg': 'Send message',
        'seePics': 'View photos',
        'passShenHe': 'Approved',
        'zoomVideo': 'Zoom video',
        'examRecord': 'Examination status record',        
        'restoreVideo': 'Restore video',
        'abnormal': 'Abnormal',
        'times': 'times',
        'addTime': 'Add time',
        'quitWebSystem': 'Quit exam',
        'closeFace': 'Close face recognition',
        'openFace': 'Open face recognition',
        'jumpScan': 'Skip scan code',
        'loginAgain': 'Login again',
        'answerNums': 'Progress',
        'motTch': 'Invigilator',
        'groupMsg': 'This is a group message',
        'studentID': 'Student ID',
        'name': 'Name',
        'plcMsgAlert': 'Please enter the information to be sent, it can be empty.',
        'shortLang': 'Shortcut language',
        'sendGrpMsg': 'Group message',
        'send': 'Send',
        'abnormalImg': 'Abnormal behavior image',
        'submit': 'Submit',
        'close': 'Close',
        'reStuState': 'Reset student status',
        'cfmReSttState': 'Are you sure you want to reset student status?',
        'addTimeToStu': 'Give students extra time',
        'allAddTime': 'Total overtime',
        'second': 'second',
        'freeAnswer': 'Free answer',
        'tingAddTime': 'Check the checkbox',
        'zuoAddTime': 'Please add extra time to the description and answer separately',
        'descPart': 'Description part',
        'answerPart': 'Answer part',
        'inputMins': 'Enter the number of minutes',
        'restoreTing': 'Reset listening questions',
        'hasTing': 'Finished listening',
        'unTing': 'Not listened',
        'openVideo': 'Open video',
        'unmute': 'Unmute',
        'mute': 'Mute',
        'refreshState': 'Refresh',
        'sendRoomMsg': 'Group message',
        'closeRommMic': 'Close all mic',
        'openRoomMic': 'Open all mic',
        'closeRoomHandset': 'Close all speaker',
        'openRoomHandset': 'Open add speaker',
        'noJoinRoom': "Haven't joined the room yet!",
        'jkMsgZt_1': 'Suspend the exam! Please wait for the invigilator.',
        'jkMsgZt_2': 'Suspend the exam! Please check the monitor of the mobile phone screen, check the test time, whether there is an image of yourself, if there is a problem, please scan the QR code again.',
        'jkMsgZt_3': 'Suspend the exam! Please adjust your monitoring phone to make sure you see your face, upper body, hands, test phone, desktop, scratch paper, etc.',
        'jkMsgZt_4': 'Suspend the exam! Please place your phone at 45 degrees on the upper left or upper right, at a height of more than 40 cm from the desktop, and 1 meter from yourself. Make sure to see your face, upper body, hands, test phone, desktop, scratch paper, etc.',
        'jkMsg_1': 'Please wait patiently after you have set up your double booths, and the invigilator will check them one by one.',
        'jkMsg_2': 'Please upload a clear photo of the front of your hand-held ID and the letter of commitment.',
        'jkMsg_3': 'Your debugging has been successful, please keep it and wait for the exam to start.',
        'jkMsg_4': 'Please make sure that your upper body, hands and answering mobile phone are in the monitoring, please adjust.',
        'jkMsg_5': 'Please ensure that the monitoring screen is vertical, please adjust.',
        'jkMsg_6': 'Please ensure that the desktop items meet the requirements of the "Examination Room Rules", please adjust.',
        'jkMsg_7': 'Please upload a photo of the answer immediately after answering a question, so as not to lose it.',
        'jkMsg_8': 'Please make sure that the photo of the answer is clear. If the photo is not clear, the assessment will be affected at your own risk.',
        'jkMsg_9': 'There are still 15 minutes before the end of the exam. Please arrange your time reasonably. If there are still questions that have not been uploaded, please upload them in time.',
        'itemXh': 'Question number',
        'currentState': 'Current state',
        'noStuData': 'No student data!',
        'syncServerErr': 'Failed to synchronize server time!',
        'missComd': 'Command missing!',
        'missParam': 'Missing parameters!',
        'noStuInRoom': 'This candidate has not yet joined the monitoring!',
        'misStuInfo': 'Missing student information!',
        'sltMsgType': 'Please select the message type!',
        'grpSendDone': 'Mass posting is complete!',
        'reConnect': 'The call failed, please click the ‘Resume Call’ button under the student’s profile picture.',
        'misTchUid': 'Teacher UID is missing',
        'sendShMsgFail': 'Failed to send the student a pass review message!',
        'misServerIp': 'Server address is missing!',
        'misKaoDian': 'Missing test site details!',
        'reStuState': 'Student status reset...',
        'reStuStateSuc': 'The student status has been reset successfully!',
        'addingTime': 'Overtime...',
        'addingTimeSuc': 'Succeeded in extra time!',
        'msgSending': 'Sending information...',
        'msgSendingSuc': 'The message was sent successfully!',
        'jumpScanMsgSend': 'Skip side camera scan code command has been sent',
        'eventPending': 'Event pending',
        'misMachCode': 'Machine code is missing!',
        'quitKsdMsgSend': 'The command to exit the exam has been sent successfully!',
        'misKsid': 'Exam ID is missing!',
        'notStarted': 'Not started',
        'started': 'Started',
        'stuList': 'Student list',
        'examName': 'Exam name',
        'beginDate': 'Start time',
        'endDate': 'End time',
        'applicantNum': 'Applicants',
        'jkkwNum': 'Invigilators',
        'jkjsNum': 'Teachers',
        'noKeMuInfo': 'No subject information was found!',
        'missXxId': 'School ID is missing!',
        'missKmId': 'Subject ID is missing!',
        'refreshStuData': 'Refresh student data',
        'refresh': 'later refreshable',
        'reset': 'Reset',
        'addTimeMinute': 'Overtime minutes',
        'stuImg': 'Student photos',
        'keXuHao': 'Teaching class',
        'schedule': 'Schedule',
        'sltKaoShi': 'Different test center servers have been set for different sessions of this exam, please select the specific session!',
        'enterTimeVal': 'Please enter the extra time!',
        'state': 'State',
        'allowExam': 'Allow exam',
        'pauseExam': 'Suspend exam',
        'forceEnd': 'Force end',
        'sendMessage': 'Send message',
        'disciplinary': 'Disciplinary',
        'cancel': 'Cancel',
        'importSuc': 'import success!',
        'seePic': 'See Image',
        'saveVlt': 'Save Violation',
        'dataSaveSuc': 'Save success!',
        'leftTime': 'Minutes remaining',
        'khsyMin': 'Upload answer left',
        'logouting': 'User login status in the process of logging out...',
        'logining': 'Login in ...',
        'ykNum': 'Number of candidates',
        'skNum': 'Number of actual exams',
        'wdlNum': 'Number of people not logged in',
        'ydlNum': 'Number of logged in',
        'yjjNum': 'Number of submitted papers',
        'ksImg': 'Candidate Photos',
        'zpImg': 'Capture photos',
        'freeRes': 'Free response',
        'enrolled': 'Enrolled',
        'answer': 'On the exam',
        'submitted': 'Submitted',
        'onUpload': 'Being uploaded',
        'uploaded': 'Been uploaded',
        'logged': 'Logged in',
        'ycCutImg': 'Abnormal behavior',
        'handCutImg': 'Manual Screenshot',
        'dsCutImg': 'Timed polling',
        'myPhoto': 'My photo',
        'phone': 'Phone num',
        'certNum': 'Certificate number',
        'rgstPwd': 'Registration password',
        'serAddr': 'Server Address',
        'total': 'Total',
        'sheet': 'Sheet',
        'sheet': 'Sheet',
        'postExamJiaShi': 'Post-exam uploading of responses plus time',
        'topic': 'Topics',
        'answered': 'Answered',
        'unanswered': 'Unanswered',
        'rtcInit': 'TatRTC initialization failure!',
        'leaveMonit': 'Leave monitoring',
        'notSelf': 'Not the candidate',
        'multPeople': 'Multi-person entry',
        'viewLr': 'Left and right view',
        'dataProc': 'In Data Processing',
        'statRlsbz': 'Face Recognition',
        'statRlyzsb': 'Verification fail',
        'statCjwsmz': 'Scanning',
        'statCjwsmcg': 'Scanning success',
        'statCjwsmsb': 'Scanning fail',
        'statKsxz': 'Information page',
        'statDdsh': 'Awaiting review',
        'statDdjrks': 'Waiting to enter',
        'statKsz': 'On the exam',
        'statJj': 'End exam',
        'statDkmkfcg': 'Open mic success',
        'statGbmkfsb': 'Open mic fail',
        'statDkysqcg': 'Open speaker success',
        'statDkysqsb': 'Open speaker fail',
        'statJxks': 'Continue exam',
        'statYdk': 'Opened',
        'statRm': 'Mic',
        'statYsq': 'Speaker',
        'statKhsczdz': 'Uploading answer',
        'noChkStu': 'Candidate not detected',
        'selectWjlx': 'Please select the type of violation!',
        'addInvlt': 'Add invigilator',
        'add': 'Add',
        'modRoomName': 'Modify room name',
        'addTo': 'Added to',
        'delete': 'delete',
        'from': 'From',
        'selectAll': 'Select all',
        'batchAdd': 'Batch add',
        'conflictData': 'Conflict data',
        'missJkRoom': 'Missing invigilator room',
        'misPiciId': 'Missing batch ID!',
        'modSuccess': 'Modified successfully!',
        'missJkRoomName': 'Missing room name',
        'roomNotMod': 'The same room name does not need to be changed',
        'roomSetSuc': 'Room teacher set up successfully!',
        'noImpData': 'There is no import data!',
        'callTeacher': 'Call teacher',
        'calling': 'Calling...',
        'cancelCall': 'Cancel call',
        'expStu': 'Exporting student',
        'focAndSave': 'Forced hand-in and preservation of discipline',
        'ksKsSj': 'Start time',
        'kmName': 'Course title',
    }
};