<template>
  <div class="pici">
    <div class="box">
      <div class="btn">
        <div class="btn_no1">
          <el-button size="small" @click="addJigou('add')">新增机构</el-button>
          <div class="selBox">
            <el-select v-model="value" size="small" placeholder="请选择">
              <el-option label="国级" value="10" size="small"> </el-option>
              <el-option label="省级" value="20" size="small"></el-option>
              <el-option label="考区" value="30" size="small"></el-option>
              <el-option label="学校" value="40" size="small"></el-option>
              <el-option label="校区" value="50" size="small"> </el-option>
            </el-select>
          </div>
        </div>
        <div class="seach">
          <el-input
            placeholder="请输入内容"
            suffix-icon="el-icon-search"
            style="width: 200px"
            size="small"
            v-model="input2"
          >
          </el-input>
        </div>
      </div>
      <div>
        <el-table
          ref="multipleTable"
          :data="jgTable"
          :header-cell-style="{
            textAlign: 'center',
            background: '#5B95BD',
            fontSize: ' 16px',
            fontFamily: ' PingFang SC',
            fontWeight: 500,
            color: '#FFFFFF',
          }"
          height="670"
          :cell-style="{ textAlign: 'center' }"
          style="width: 100%"
        >
          <el-table-column prop="机构名称" label="机构名称" width="120">
          </el-table-column>
          <el-table-column prop="级别ID" label="机构层级"
            ><template slot-scope="scope">
              {{ switchFun(scope.row["级别ID"]) }}
            </template></el-table-column
          >
          <el-table-column prop="address" label="机构管理员">
            <template slot-scope="scope" v-if="renderComponent">
              <span v-for="item in scope.row['管理员']" :key="item.UID">
                {{ item["姓名"] }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="机构用户" label="机构用户"> </el-table-column>
          <el-table-column label="操作" width="300">
            <template slot-scope="scope">
              <el-button
                @click="handleClick(scope.row)"
                type="text"
                size="small"
                >人员管理</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="addJigou('update', scope.row)"
                >修改机构</el-button
              >
              <el-button type="text" size="small" @click="deleteJg(scope.row)"
                >删除机构</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="footer">
          <el-pagination
            background
            @size-change="jghandleSizeChange"
            @current-change="jghandleCurrentChange"
            :current-page="jgcurrentPage"
            :page-sizes="[20, 50, 100]"
            :page-size="jgpagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="jgtotal"
          >
          </el-pagination>
        </div>
      </div>
      <!-- <div v-show="routerName">
        <div class="dilogTop">
          <div class="xiugaibox">
            <div>机构名称</div>
            <el-input
              placeholder="请输入内容"
              style="width: 200px"
              size="small"
              v-model="input2"
            >
            </el-input>
          </div>
          <div class="xiugaibox">
            <div>机构层级</div>
            <el-select v-model="value" size="small" placeholder="请选择">
              <el-option label="全部老师" value="0" size="small"> </el-option>
              <el-option label="未关联房间老师" value="0" size="small">
              </el-option>
            </el-select>
          </div>
          <div class="xiugaibox">
            <div>用户管理</div>
            <div>
              <el-button class="bluebtn" size="small" @click="exportBtn"
                >导入</el-button
              >
              <el-button class="bluebtn" @click="addUser" size="small"
                >新增</el-button
              >
              <el-button class="xiugaibtn" @click="handleClick" size="small"
                >查看/修改</el-button
              >
            </div>
          </div>
        </div>
        <div class="zhuyi">
          <div>注：</div>
          <div>
            机构管理人员：拥有所有页面权限，可以进行考试管理和监考房间管理
          </div>
          <div>考务人员：拥有看板、监考房间、考后数据查看权限</div>
          <div>
            巡考：在用户管理页面重新设置的角色，可以在添加监考房间时，区别对待
          </div>
          <div>监考：最基本监考角色，通过批量导入生成或者自动创建</div>
          <div>考生：考生类别和老师类别做出区分</div>
        </div>
      </div>-->
    </div>
    <el-dialog title="导入用户" :visible.sync="exportdialog" width="30%">
      <div class="dialogBtn">
        <el-button size="small" class="cancel" @click="cancel('upload')"
          >取消</el-button
        >
        <el-button size="small" class="addbtn" @click="submit('export')"
          >确定</el-button
        >
      </div>
      <div class="dilogTop">
        <div class="uploadBox">
          <div>用户角色</div>
          <el-select v-model="value" size="small" placeholder="请选择">
            <el-option label="机构管理人员" value="1" size="small"> </el-option>
            <el-option label="考务人员" value="2" size="small"> </el-option>
            <el-option label="巡考" value="4" size="small"> </el-option>
            <el-option label="监考" value="3" size="small"> </el-option>
          </el-select>
        </div>
        <div class="exportBox">
          <div>选择文件</div>
          <el-upload
            class="upload-demo"
            action="https://jsonplaceholder.typicode.com/posts/"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            multiple
            :limit="3"
            :on-exceed="handleExceed"
            :file-list="fileList"
          >
            <el-button class="shangchuan" size="small">导入</el-button>
          </el-upload>
        </div>
      </div>
    </el-dialog>
    <el-dialog :title="userTitle" :visible.sync="adduserdialog" width="25%">
      <div class="dialogBtn">
        <el-button size="small" class="cancel" @click="cancel('adduser')"
          >取消</el-button
        >
        <el-button size="small" class="addbtn" @click="submit('user')">{{
          userTitle == "新增用户" ? "新增" : "修改"
        }}</el-button>
      </div>
      <div class="dilogTop">
        <div class="dialbox">
          <div>机构名称</div>
          <el-input
            placeholder="请输入内容"
            style="width: 200px"
            size="small"
            disabled
            v-model="userFrom['机构名称']"
          >
          </el-input>
        </div>
        <div class="dialbox">
          <div>用户昵称</div>
          <el-input
            placeholder="请输入内容"
            style="width: 200px"
            size="small"
            v-model="userFrom['用户名']"
          >
          </el-input>
        </div>
        <div class="dialbox">
          <div>用户姓名</div>
          <el-input
            placeholder="请输入内容"
            style="width: 200px"
            size="small"
            v-model="userFrom['姓名']"
          >
          </el-input>
        </div>
        <div class="dialbox">
          <div>用户密码</div>
          <el-input
            placeholder="请输入内容"
            style="width: 200px"
            size="small"
            v-model="userFrom['密码']"
          >
          </el-input>
        </div>
        <div class="dialbox">
          <div>用户角色</div>
          <el-select
            v-model="userFrom['角色ID']"
            size="small"
            placeholder="请选择"
          >
            <el-option label="管理员" value="1" size="small">管理员 </el-option>
            <el-option label="操作员" value="2" size="small">操作员</el-option>
            <el-option label="监考老师" value="3" size="small"
              >监考老师</el-option
            >
            <el-option label="巡考老师" value="4" size="small"
              >巡考老师</el-option
            >
          </el-select>
        </div>
      </div>
    </el-dialog>
    <el-dialog :title="jigouTitle" :visible.sync="dialogVisible" width="25%">
      <div class="dialogBtn">
        <el-button size="small" class="cancel" @click="cancel('jigou')"
          >取消</el-button
        >
        <el-button size="small" class="addbtn" @click="submit('jigou')">{{
          jigouTitle == "新增机构" ? "新增机构" : "修改机构"
        }}</el-button>
      </div>
      <div class="dilogTop">
        <div class="dialbox">
          <div>机构名称</div>
          <el-input
            placeholder="请输入内容"
            style="width: 200px"
            size="small"
            v-model="jgForm['机构名称']"
          >
          </el-input>
        </div>
        <!-- <div class="dialbox">
          <div>机构代码</div>
          <el-input
            placeholder="请输入内容"
            style="width: 200px"
            size="small"
            v-model="jgForm['机构代码']"
          >
          </el-input>
        </div> -->
        <div class="dialbox">
          <div>机构层级</div>
          <el-select
            v-model="jgForm['级别ID']"
            size="small"
            placeholder="请选择"
            @change="selJgLevel"
          >
            <el-option label="国级" value="10" size="small"> </el-option>
            <el-option label="省级" value="20" size="small"></el-option>
            <el-option label="考区" value="30" size="small"></el-option>
            <el-option label="学校" value="40" size="small"></el-option>
            <el-option label="考点" value="50" size="small"> </el-option>
          </el-select>
        </div>
        <div class="dialbox">
          <div>上级机构</div>
          <el-select
            :disabled="!farTable.length"
            v-model="farID"
            value-key="机构ID"
            size="small"
            placeholder="请选择"
          >
            <el-option
              v-for="item in farTable"
              :key="item['机构ID']"
              :label="item['机构名称']"
              :value="item"
              size="small"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="人员管理" :visible.sync="userdialog" width="70%">
      <div class="dialogBtn">
        <el-button size="small" class="cancel" @click="cancel('usermanage')"
          >关闭</el-button
        >
      </div>
      <div class="dilogTop">
        <div class="btn_no1" style="margin-bottom: 10px">
          <el-button class="bluebtn" size="small" @click="exportBtn"
            >导入用户</el-button
          >
          <el-button class="bluebtn" @click="addUser('add')" size="small"
            >新增用户</el-button
          >
          <el-button class="bluebtn" @click="delAllUser" size="small"
            >清空用户</el-button
          >
          <div class="selBox">
            <el-select v-model="value" size="small" placeholder="请选择">
              <el-option label="国级" value="10" size="small"> </el-option>
              <el-option label="省级" value="20" size="small"></el-option>
              <el-option label="考区" value="30" size="small"></el-option>
              <el-option label="考点" value="40" size="small"> </el-option>
            </el-select>
          </div>
        </div>

        <el-table
          ref="multipleTable"
          :data="userTable"
          height="500px"
          :header-cell-style="{
            textAlign: 'center',
            background: '#5B95BD',
            fontSize: ' 16px',
            fontFamily: ' PingFang SC',
            fontWeight: 500,
            color: '#FFFFFF',
          }"
          :cell-style="{ textAlign: 'center' }"
          style="width: 100%"
        >
          <el-table-column prop="用户名" label="用户账号" width="120">
          </el-table-column>
          <el-table-column prop="姓名" label="用户昵称"> </el-table-column>
          <el-table-column prop="密码" label="用户密码"> </el-table-column>
          <el-table-column prop="address" label="用户角色">
            <template slot-scope="scope" v-if="renderComponent">
              <span v-for="item in scope.row['用户角色']" :key="item['角色ID']">
                {{ item["角色名称"] }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="300">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="addUser('updata', scope.row)"
                >修改</el-button
              >
              <el-button type="text" size="small" @click="delAllUser(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="footer">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[20, 50, 100]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
let that;
import { mapState, mapMutations } from "vuex";
import { getFun, postFun, deleteFun, putFun } from "../../request/request";
let organUrl = "/jiankao/organ";
let userUrl = "/jiankao/user";
let user_roleUrl = "/jiankao/user_role";
export default {
  name: "PiciManage",
  data() {
    return {
      farTable: [],
      userFrom: {
        姓名: "",
        用户名: "",
        密码: "",
        角色ID: "",
      },
      jigouTitle: "新增机构",
      jgForm: {
        机构名称: "",
        级别ID: "",
        机构代码: "",
      },
      jgTable: [],
      userTable: [],
      tableData: [],
      currentPage4: 1,
      pagesize: 20,
      total: 100,
      jgcurrentPage: 1,
      jgpagesize: 20,
      jgtotal: 100,
      input2: "",
      dialogVisible: false,
      value: "",
      userdialog: false,
      adduserdialog: false,
      exportdialog: false,
      fileList: [],
      userTitle: "新增用户",
      renderComponent: false,
      farID: "",
    };
  },
  computed: {
    ...mapState(["routerName", "user"]),
  },
  methods: {
    ...mapMutations(["changeRouterName"]),
    /**
     * 切换机构等级
     */
    selJgLevel(val) {
      let param = {};
      if (val == 10) {
        return;
      }
      if (val == 20) {
        param["级别ID"] = 10;
      }
      if (val == 30) {
        param["级别ID"] = 20;
      }
      if (val == 40) {
        param["级别ID"] = 30;
      }
      if (val == 50) {
        param["级别ID"] = 40;
      }
      that.farTable = [];
      getFun(organUrl, param).then((res) => {
        if (res.data && res.result) {
          that.farTable = res.data;
        }
      });
    },
    switchFun(val) {
      switch (val) {
        case 10:
          return "国级";
        case 20:
          return "省级";
        case 30:
          return "考区";
        case 40:
          return "学校";
        case 50:
          return "考点";

        default:
          break;
      }
    },
    forceRerender: function () {
      that.renderComponent = false; // 从 DOM 中删除 my-component 组件
      that.$nextTick(function () {
        that.renderComponent = true; // 在 DOM 中添加 my-component 组件
      });
    },
    /**
     * 删除机构
     */
    deleteJg(row) {
      let param = {
        机构ID: row["机构ID"],
      };
      this.$confirm("此操作将删除该机构, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteFun(organUrl, param).then((res) => {
            if (res.result) {
              that.getorgan();
            }
          });
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    /**
     *  清空用户
     */
    delAllUser(row) {
      let param = {
        UID: row.UID,
      };
      this.$confirm("此操作将删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteFun(userUrl, param).then((res) => {
            if (res.result) {
              that.getUser();
            }
          });
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    /**
     * 获取用户信息
     */
    getUser() {
      let arr = [];
      that.jgTable.forEach((item) => {
        arr.push(item["机构ID"]);
      });
      let param = {
        机构ID: JSON.stringify(arr),
        用户类型: 1,
        最大记录数: that.pagesize,
        跳过记录数: that.pagesize * (that.currentPage4 - 1),
      };
      getFun(userUrl, param).then((res) => {
        if (res.data && res.result) {
          that.userTable = res.data;
          that.total = res.total_records;
          that.jgTable.forEach((item) => {
            let isSet = that.userTable.find((items) => {
              return item["机构ID"] == items["机构ID"];
            });
            if (isSet) {
              item["机构用户"] = "已设置";
            }
          });
          let userid = [];
          that.userTable.forEach((items) => {
            userid.push(items.UID);
          });
          let userobj = {
            UID: JSON.stringify(userid),
          };
          getFun(user_roleUrl, userobj).then((juese) => {
            if (juese.data && juese.result) {
              that.userTable.forEach((item) => {
                item["用户角色"] = [];
                juese.data.forEach((items) => {
                  if (item.UID == items.UID) {
                    item["用户角色"].push(items);
                  }
                });
              });
              that.jgTable.forEach((item) => {
                item["管理员"] = [];
                that.userTable.forEach((items) => {
                  if (item["机构ID"] == items["机构ID"]) {
                    items["用户角色"].forEach((userjs) => {
                      if (userjs["角色ID"] == 1) {
                        item["管理员"].push(items);
                      }
                    });
                  }
                });
              });
              that.forceRerender();
            }
          });
        } else {
          that.jgTable.forEach((item) => {
            item["机构用户"] = "未设置";
          });
        }
        that.$forceUpdate();
      });
    },

    /**
     * 获取机构信息
     */
    getorgan() {
      let param = {
        最大记录数: that.jgpagesize,
        跳过记录数: that.jgpagesize * (that.jgcurrentPage - 1),
      };
      getFun(organUrl, param).then((res) => {
        if (res.data && res.result) {
          that.jgtotal = res.total_records;
          that.jgTable = res.data;

          that.getUser();
        }
      });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      that.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return that.$confirm(`确定移除 ${file.name}？`);
    },
    exportBtn() {
      that.exportdialog = true;
    },
    addUser(val, row) {
      that.adduserdialog = true;
      if (val == "add") {
        that.userTitle = "新增用户";
      }
      if (val == "updata") {
        that.userTitle = "修改用户";
        that.userFrom.UID = row.UID;
        that.userFrom["姓名"] = row["姓名"];
        that.userFrom["用户名"] = row["用户名"];
        that.userFrom["机构ID"] = row["机构ID"];
        that.userFrom["角色ID"] = row["用户角色"][0]["角色ID"];
        that.userFrom["密码"] = row["密码"];
      }
    },
    /**
     * 数据提交
     */
    submit(val) {
      if (val == "jigou") {
        that.dialogVisible = false;
        let param;
        if (that.jigouTitle == "新增机构") {
          param = {
            机构名称: that.jgForm["机构名称"],
            级别ID: that.jgForm["级别ID"],
          };
          if (that.jgForm["级别ID"] == 10) {
            let arr = that.jgTable.filter((item) => {
              return item["级别ID"] == 10;
            });
            param["机构代码"] = arr.length + 1;
          }
          if (
            that.jgForm["级别ID"] == 20 ||
            that.jgForm["级别ID"] == 30 ||
            that.jgForm["级别ID"] == 40
          ) {
            let arr = that.jgTable.filter((item) => {
              return item["父机构ID"] == that.farID["机构ID"];
            });
            param["机构代码"] =
              that.farID["机构代码"] + "00" + (arr.length + 1);
          }
          if (that.jgForm["级别ID"] == 50) {
            let arr = that.jgTable.filter((item) => {
              return item["父机构ID"] == that.farID["机构ID"];
            });
            param["机构代码"] =
              that.farID["机构代码"] + "000" + (arr.length + 1);
          }

          // return;
          if (that.jgForm["级别ID"] != 10) {
            if (that.farID) {
              param["父机构ID"] = that.farID["机构ID"];
            } else {
              that.$message({
                message: "请选择上级机构",
                type: "error",
              });
              return;
            }
          } else {
            param["父机构ID"] = 0;
          }

          // return;
          putFun(organUrl, param).then((res) => {
            if (res.data && res.result) {
              that.getorgan();
              that.$message({
                message: "新建机构成功",
                type: "success",
              });
              that.jgForm = {
                机构名称: "",
                级别ID: "",
                机构代码: "",
              };
              that.farID = "";
            }
          });
        } else {
          param = {
            机构ID: that.jgForm["机构ID"],
            机构名称: that.jgForm["机构名称"],
            级别ID: that.jgForm["级别ID"],
            机构代码: 2,
          };
          if (that.jgForm["级别ID"] != 10) {
            if (that.farID) {
              param["父机构ID"] = that.farID["父机构ID"];
            } else {
              that.$message({
                message: "请选择上级机构",
                type: "error",
              });
              return;
            }
          } else {
            param["父机构ID"] = 0;
          }
          // return;
          postFun(organUrl, param).then((res) => {
            if (res.result) {
              that.getorgan();
              that.$message({
                message: "修改机构成功",
                type: "success",
              });
              that.jgForm = {
                机构名称: "",
                级别ID: "",
                机构代码: "",
              };
              that.farID = "";
            }
          });
        }
        that.getorgan();
      }
      if (val == "user") {
        that.adduserdialog = false;
        let param;
        if (that.userTitle == "新增用户") {
          param = {
            用户名: that.userFrom["用户名"],
            姓名: that.userFrom["姓名"],
            机构ID: that.userFrom["机构ID"],
            密码: that.userFrom["密码"],
            用户类型: 1,
          };
          // return;
          putFun(userUrl, param).then((res) => {
            if (res.data && res.result) {
              let obj = {};
              let userobj = {
                机构ID: that.userFrom["机构ID"],
                角色ID: that.userFrom["角色ID"],
              };
              obj.UID = res.data.UID;
              obj["用户角色"] = [];
              obj["用户角色"].push(userobj);
              obj["用户角色"] = JSON.stringify(obj["用户角色"]);
              postFun(user_roleUrl, obj).then((res) => {
                if (res.result) {
                  that.$message({
                    message: "新建用户成功",
                    type: "success",
                  });
                  that.getUser();
                  return;
                }
              });
            }
          });
        }
        if (that.userTitle == "修改用户") {
          param = {
            UID: that.userFrom.UID,
            用户名: that.userFrom["用户名"],
            姓名: that.userFrom["姓名"],
            机构ID: that.userFrom["机构ID"],
            密码: that.userFrom["密码"],
            用户类型: 1,
          };
          // return;
          postFun(userUrl, param).then((res) => {
            if (res.result) {
              let obj = {};
              let userobj = {
                机构ID: that.userFrom["机构ID"],
                角色ID: that.userFrom["角色ID"],
              };
              obj.UID = that.userFrom.UID;
              obj["用户角色"] = [];
              obj["用户角色"].push(userobj);
              obj["用户角色"] = JSON.stringify(obj["用户角色"]);
              // return
              postFun(user_roleUrl, obj).then((res) => {
                if (res.result) {
                  that.$message({
                    message: "修改用户成功",
                    type: "success",
                  });
                  that.getUser();
                  return;
                }
              });
            }
          });
        }
      }
      if (val == "export") {
        that.exportdialog = false;
      }
    },
    cancel(val) {
      if (val == "usermanage") {
        that.userdialog = false;
      }
      if (val == "upload") {
        that.exportdialog = false;
      }
      if (val == "adduser") {
        that.adduserdialog = false;
      }
      if (val == "jigou") {
        that.dialogVisible = false;
        that.jgForm = {
          机构名称: "",
          级别ID: "",
          机构代码: "",
        };
      }
      // that.jgName = "";
      // that.jglevel = "";
    },
    addJigou(val, scope) {
      that.dialogVisible = true;
      if (val == "add") {
      }
      if (val == "update") {
        that.jigouTitle = "修改机构";
        that.selJgLevel(scope["级别ID"]);
        that.jgForm = JSON.parse(JSON.stringify(scope));
        that.jgForm["级别ID"] = String(that.jgForm["级别ID"]);
        that.farID = {};
        that.farID["机构ID"] = scope["父机构ID"];
      }
    },
    handleSelectionChange() {},
    handleClick(row) {
      that.userdialog = true;
      that.userFrom["机构ID"] = row["机构ID"];
      that.userFrom["机构名称"] = row["机构名称"];
    },
    handleSizeChange(val) {
      that.pagesize = val;
      that.getUser();
    },
    handleCurrentChange(val) {
      that.currentPage4 = val;
      that.getUser();
    },
    jghandleSizeChange(val) {
      that.jgpagesize = val;
      that.getorgan();
    },
    jghandleCurrentChange(val) {
      that.jgcurrentPage = val;
      that.getorgan();
    },
  },
  created() {
    that = this;
    that.getorgan();
  },
};
</script>

<style scoped>
.exportBox span {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ebae39;
}
/deep/.shangchuan {
  width: 200px;
  background: #f6f6f6;
}
.exportBox {
  display: flex;
  height: 80px;
}
.exportBox > div {
  margin-right: 30px;
  margin-left: 50px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #1d394d;
}
.zhuyi {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #1d394d;
  margin-top: 430px;
  margin-bottom: 40px;
}
/deep/.xiugaibtn {
  background: #f6f6f6;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #5b95bd;
  border: none;
}
/deep/.bluebtn {
  background: #eef8ff;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #5b95bd;
  border: none;
}
.dialogBtn {
  position: absolute;
  top: 5px;
  right: 5px;
}
.el-dialog {
  position: relative;
}
.cancel {
  width: 64px;
  height: 30px;
  background: #dd1c1c;
  border-radius: 4px;
  color: #fff;
  border: none;
}
.addbtn {
  height: 30px;
  background: #5b95bd;
  border-radius: 4px;
  color: #fff;
  border: none;
}
.xiugaibox {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
/deep/.xiugaibox .el-input__inner {
  width: 300px;
  background-color: #f6f6f6;
  border: none;
}
.xiugaibox > div {
  margin-right: 60px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #1d394d;
}
.dialbox {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.uploadBox {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

/deep/.uploadBox .el-input__inner {
  /* width: 300px; */
  background-color: #f6f6f6;
  border: none;
}
/deep/.dialbox .el-input__inner {
  width: 300px;
  background-color: #f6f6f6;
  border: none;
}
.dialbox > div {
  margin-right: 15px;
  margin-left: 20px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #1d394d;
}
.uploadBox > div {
  margin-right: 30px;
  margin-left: 50px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #1d394d;
}
/deep/.el-dialog__headerbtn {
  top: 15px;
}
/deep/.el-dialog__title {
  color: #fff !important;
}
/deep/.el-dialog__header {
  background: #1d394d;
  height: 40px;
  padding: 0 20px;
  display: flex;
  align-items: center;
}
/deep/.el-table .el-button {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #5b95bd;
}
.selBox {
  margin-left: 30px;
}
/deep/.selBox .el-input__inner {
  border: none !important;
  background: #eef8ff;
}
/deep/.selBox .el-select {
  width: 100px;
  height: 30px;

  border: none !important;
}
.dialogTop {
  margin-bottom: 15px;
}
.shuju {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #5b95bd;
  width: 90px;
  height: 30px;
  background: #eef8ff;
  border-radius: 4px;
}
.footer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
.num {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #5b95bd;
}
/deep/.seach .el-input__inner {
  background: #f6f6f6;
  border: none;
}
.btn_no1 {
  display: flex;
}
.btn {
  display: flex;
  margin: 27px 0 17px 0;
  justify-content: space-between;
}
.btn .el-button {
  height: 30px;
  background: #eef8ff;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #5b95bd;
  border: none !important;
}
.box {
  padding: 1px 22px 19px 22px;
  background-color: #fff;
}
.pici {
  width: 100%;
  height: 100%;
}
</style>