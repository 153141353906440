<template>
  <div>
    <div class="btn">
      <div class="btn_no1">
        <el-button size="small" @click="exportFun">导出Excel数据</el-button>
        <el-button size="small" @click="addRoomFun">新增监考房间</el-button>
        <el-button size="small" @click="deleteRoom">删除房间</el-button>
      </div>
      <div class="seach">
        <el-input
          placeholder="请输入内容"
          suffix-icon="el-icon-search"
          style="width: 200px"
          size="small"
          v-model="input2"
        >
        </el-input>
      </div>
    </div>
    <div>
      <el-table
        id="exportTab"
        ref="multipleTable"
        :data="tableData"
        :header-cell-style="{
          textAlign: 'center',
          background: '#5B95BD',
          fontSize: ' 16px',
          fontFamily: ' PingFang SC',
          fontWeight: 500,
          color: '#FFFFFF',
        }"
        height="600px"
        :cell-style="{ textAlign: 'center' }"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="房间名称" label="监考房间名称" width="120">
        </el-table-column>
        <el-table-column prop="考生人数" label="房间考生人数">
          <template slot-scope="scope">
            <span v-if="scope.row['考生人数']">{{
              scope.row["考生人数"]
            }}</span>
            <span class="notSet" v-if="!scope.row['考生人数']">未设置</span>
          </template>
        </el-table-column>
        <el-table-column prop="监考老师人数" label="房间监考人数">
          <template slot-scope="scope">
            <span v-if="scope.row['监考老师人数']">{{
              scope.row["监考老师人数"]
            }}</span>
            <span class="notSet" v-if="!scope.row['监考老师人数']"
              >未添加</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="巡考老师人数" label="房间巡考人数">
          <template slot-scope="scope">
            <span v-if="scope.row['巡考老师人数']">{{
              scope.row["巡考老师人数"]
            }}</span>
            <span class="notSet" v-if="!scope.row['巡考老师人数']"
              >未添加</span
            >
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small"
              >监考房间管理</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[20, 50, 100]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <el-dialog title="监考房间管理" :visible.sync="dialogVisible" width="70%">
        <div class="dialogBox">
          <div>
            <div class="dialogTop">
              <el-button
                size="small"
                class="shuju"
                @click="addStudent('student')"
                >新增考生</el-button
              >
              <el-button
                size="small"
                class="shuju"
                @click="stuJiechu('student')"
                >解除关联</el-button
              >
            </div>
            <el-table
              ref="multipleTable"
              :data="studentArr"
              :header-cell-style="{
                textAlign: 'center',
                background: '#5B95BD',
                fontSize: ' 16px',
                height: '46px',
                fontFamily: ' PingFang SC',
                fontWeight: 500,
                color: '#FFFFFF',
              }"
              @selection-change="stuSelectionChange"
              height="450px"
              :cell-style="{ textAlign: 'center' }"
              style="width: 95%"
            >
              <!-- @selection-change="handleSelectionChange" -->
              <el-table-column type="selection" width="55"> </el-table-column>
              <el-table-column prop="姓名" label="考生姓名"> </el-table-column>
              <el-table-column prop="报名号" label="考生准考证号">
              </el-table-column>
            </el-table>
            <div class="num" style="margin-top: 20px">
              考生人数：{{ studentArr.length }}人
            </div>
          </div>
          <div>
            <div class="dialogTop">
              <el-button
                size="small"
                class="shuju"
                @click="addStudent('teacher')"
                >新增老师</el-button
              >
              <el-button
                size="small"
                class="shuju"
                @click="stuJiechu('teacher')"
                >解除关联</el-button
              >
            </div>
            <el-table
              ref="multipleTable"
              :data="teacherArr"
              :header-cell-style="{
                textAlign: 'center',
                background: '#5B95BD',
                fontSize: ' 16px',
                height: '46px',
                fontFamily: ' PingFang SC',
                fontWeight: 500,
                color: '#FFFFFF',
              }"
              height="450px"
              :cell-style="{ textAlign: 'center' }"
              style="width: 95%"
              deleteChange
              @selection-change="deleteChange"
            >
              <!-- @selection-change="handleSelectionChange" -->
              <el-table-column type="selection" width="55"> </el-table-column>
              <el-table-column prop="姓名" label="监考名称"> </el-table-column>
              <el-table-column prop="用户名" label="监考账号">
              </el-table-column>
              <el-table-column prop="address" label="账号角色">
                <template slot-scope="scope">
                  <div class="quanxian">
                    {{ switchFun(scope.row["用户权限"][0]["角色ID"]) }}
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        :title="roomTitle + '监考房间管理新增考生'"
        :visible.sync="studentDialog"
        width="70%"
      >
        <div class="dilogTop">
          <div class="selBox">
            <el-button
              size="small"
              class="shuju"
              @click="addKsToRoom('student')"
              >添加到{{ roomTitle }}</el-button
            >
            <!-- <el-select v-model="value" size="small" placeholder="请选择">
              <el-option label="全部考生" value="0" size="small"> </el-option>
              <el-option label="未关联房间考生" value="0" size="small">
              </el-option>
            </el-select>
            <el-select v-model="value" size="small" placeholder="请选择">
              <el-option label="全部考生" value="0" size="small"> </el-option>
              <el-option label="未关联房间考生" value="0" size="small">
              </el-option>
            </el-select> -->
          </div>
          <!-- <div class="search">
            <el-input
              placeholder="请输入内容"
              suffix-icon="el-icon-search"
              style="width: 200px"
              size="small"
              v-model="input2"
            >
            </el-input>
          </div> -->
        </div>
        <div class="table">
          <el-table
            ref="multipleTable"
            :data="allStudent"
            :header-cell-style="{
              textAlign: 'center',
              background: '#5B95BD',
              fontSize: ' 16px',
              fontFamily: ' PingFang SC',
              fontWeight: 500,
              color: '#FFFFFF',
            }"
            height="500px"
            :cell-style="{ textAlign: 'center' }"
            style="width: 100%"
            @selection-change="addStuSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="姓名" label="考生姓名"> </el-table-column>
            <el-table-column prop="报名号" label="考生准考证号">
            </el-table-column>
            <el-table-column prop="监考房间" label="监考房间">
              <template slot-scope="scope">
                {{ scope.row["房间名称"] ? scope.row["房间名称"] : "未设置" }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-dialog>
      <el-dialog
        :title="roomTitle + '监考房间管理新增监考'"
        :visible.sync="teacherDialog"
        width="70%"
      >
        <div class="dilogTop">
          <div class="selBox">
            <el-button
              size="small"
              class="shuju"
              @click="addKsToRoom('teacher')"
              >添加到{{ roomTitle }}</el-button
            >
            <!-- <el-select v-model="value" size="small" placeholder="请选择">
              <el-option label="全部老师" value="0" size="small"> </el-option>
              <el-option label="未关联房间老师" value="0" size="small">
              </el-option>
            </el-select> -->
          </div>
          <!-- <div class="search">
            <el-input
              placeholder="请输入内容"
              suffix-icon="el-icon-search"
              style="width: 200px"
              size="small"
              v-model="input2"
            >
            </el-input>
          </div> -->
        </div>
        <div class="table">
          <el-table
            ref="multipleTable"
            :data="allTeacher"
            :header-cell-style="{
              textAlign: 'center',
              background: '#5B95BD',
              fontSize: ' 16px',
              fontFamily: ' PingFang SC',
              fontWeight: 500,
              color: '#FFFFFF',
            }"
            height="500px"
            :cell-style="{ textAlign: 'center' }"
            style="width: 100%"
            @selection-change="addTeaSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="姓名" label="监考名称" width="120">
            </el-table-column>
            <el-table-column prop="用户名" label="监考账号"> </el-table-column>
            <el-table-column prop="address" label="账号角色">
              <template slot-scope="scope">
                <div class="quanxian">
                  {{ switchFun(scope.row["用户权限"][0]["角色ID"]) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="房间名称" label="监考房间">
              <template slot-scope="scope" v-if="renderComponent">
                <el-button
                  @click="watchClick(scope.row)"
                  type="text"
                  size="small"
                  v-show="scope.row['房间'].length"
                  >查看</el-button
                >
                <span v-show="!scope.row['房间'].length">未设置</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-dialog>
    </div>
    <el-dialog title="查看房间" :visible.sync="watchDialog" width="40%">
      <el-table
        ref="multipleTable"
        :data="watchTable"
        :header-cell-style="{
          textAlign: 'center',
          background: '#5B95BD',
          fontSize: ' 16px',
          fontFamily: ' PingFang SC',
          fontWeight: 500,
          color: '#FFFFFF',
        }"
        max-height="300px"
        :cell-style="{ textAlign: 'center' }"
        style="width: 100%"
      >
        <el-table-column prop="房间名称" label="监考房间名称">
        </el-table-column>
        <!-- <el-table-column prop="用户名" label="监考账号"> </el-table-column>
        <el-table-column prop="密码" label="初始密码"> </el-table-column> -->
      </el-table>
    </el-dialog>
    <el-dialog
      title="新增监考房间"
      :visible.sync="addRoomDialog"
      width="30%"
    >
      <div style="text-align: center">
        <div class="chuangjian_1">
          <span style="margin: 0 10px 0 0">每房间考生数量</span>
          <el-col :span="4">
            <el-input size="small" v-model="form['每房间考生数']"></el-input
          ></el-col>
          <span style="margin-left: 10px">人</span>
          <span style="margin-left: 10px">（每房间最多可分配XX人）</span>
        </div>

        <div class="chuangjian_1">
          <span style="margin-right: 20px">监考房间名称前缀</span>
          <el-col :span="13">
            <el-input size="small" v-model="form['房间前缀']"></el-input
          ></el-col>
        </div>
        <el-button class="shengcheng" @click="createJiankao"
          >一键生成</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
let that;
let roomUrl = "/jiankao/room";
let watchUrl = "/jiankao/watch";
let examineeUrl = "/jiankao/examinee";
let room_watchUrl = "/jiankao/room_watch";
let room_examineeUrl = "/jiankao/room_examinee";
let auto_make_room_watchUrl = "/jiankao/auto_make_room_watch";

import {
  getFun,
  postFun,
  deleteFun,
  putFun,
} from "../../../../request/request";
export default {
  data() {
    return {
      form: {
        每房间考生数: "",
        房间前缀: "",
      },
      addRoomDialog: false,
      roomTitle: "",
      renderComponent: true,
      watchDialog: false,
      value: "",
      tableData: [],
      studentArr: [],
      teacherArr: [],
      currentPage4: 1,
      pagesize: 20,
      total: 0,
      input2: "",
      dialogVisible: false,
      studentDialog: false,
      teacherDialog: false,
      value: "",
      stuSelArr: [],
      delTeacher: [],
      addstuSelArr: [],
      addteaSelArr: [],
      roomID: "",
      watchTable: [],
      allTeacher: [],
      allStudent: [],
      copyList: [],
      examListArr: [],
    };
  },
  watch: {
    input2: {
      handler() {
        const realList = that.copyList.filter((order) => {
          return Object.keys(that.input2).reduce((flag, item) => {
            if (!flag) {
              return false;
            } else {
              return that.input2[item].trim()
                ? String(order["房间名称"]).indexOf(that.input2[item]) !== -1
                : true;
            }
          }, true);
        });
        that.tableData = realList;
      },
      deep: true,
    },
  },
  methods: {
    /**
     * 导出数据
     */
    exportFun() {
      if (!that.tableData.length) {
        that.$message({
          message: "当前没有可以导出的数据！",
          type: "warning",
        });
        return;
      }
      that.$common.getExcel("房间列表");
    },
    forceRerender: function () {
      that.renderComponent = false; // 从 DOM 中删除 my-component 组件
      that.$nextTick(function () {
        that.renderComponent = true; // 在 DOM 中添加 my-component 组件
      });
    },
    addRoomFun() {
      that.addRoomDialog = true;
    },
    /**
     * 一键生成监考数据
     */
    createJiankao() {
      let param = {
        批次ID: that.$route.query["批次ID"],
        机构ID: that.$route.query["机构ID"],
        考试ID: that.$route.query["考试ID"],
        每房间考生数: that.form["每房间考生数"],
        房间前缀: that.form["房间前缀"],
      };

      postFun(auto_make_room_watchUrl, param).then((res) => {
        if (res.result) {
          that.form = {
            每房间考生数: "",
            房间前缀: "",
          };
          that.getRoomList();
          that.addRoomDialog = false;
          this.$message({
            type: "success",
            message: "创建成功!",
          });
        }
      });
    },
    /**
     * 删除监控房间
     */
    deleteRoom(val) {
      if (!that.examListArr.length) {
        this.$message({
          type: "error",
          message: "请选择要删除的房间!",
        });
        return;
      }
      let arr = [];
      that.examListArr.forEach((item) => {
        arr.push(item["房间ID"]);
      });
      let param = {
        房间ID: JSON.stringify(arr),
      };
      this.$confirm("此操作将删除房间, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteFun(roomUrl, param).then((res) => {
            if (res.result) {
              that.getRoomList();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    /**
     * 查看
     */
    watchClick(val) {
      that.watchDialog = true;
      that.watchTable = val["房间"];
    },
    switchFun(val) {
      switch (val) {
        case 3:
          return "监考";
        case 4:
          return "巡考";

        default:
          break;
      }
    },
    /**
     * 监考房间添加考生
     */
    addKsToRoom(val) {
      if (val == "student") {
        if (!that.addstuSelArr.length) {
          this.$message({
            type: "error",
            message: "请选择考生!",
          });
          return;
        }
        let arr = [];
        that.addstuSelArr.forEach((item) => {
          arr.push(item.UID);
        });
        let param = {
          考试ID: that.$route.query["考试ID"],
          房间ID: that.roomID,
          考生: JSON.stringify(arr),
          全新设置: false,
        };
        postFun(room_examineeUrl, param).then((res) => {
          if (res.result) {
            that.getStuList();
            that.studentDialog = false;
            this.$message({
              type: "success",
              message: "添加成功!",
            });
          }
        });
      }
      if (val == "teacher") {
        if (!that.addteaSelArr.length) {
          this.$message({
            type: "error",
            message: "请选择老师!",
          });
          return;
        }
        let arr = [];
        that.addteaSelArr.forEach((item) => {
          arr.push(item.UID);
        });
        let param = {
          考试ID: that.$route.query["考试ID"],
          房间ID: that.roomID,
          老师: arr,
          全新设置: false,
        };
        postFun(room_watchUrl, param).then((res) => {
          if (res.result) {
            that.getTeaList();
            that.teacherDialog = false;
            this.$message({
              type: "success",
              message: "添加成功!",
            });
          }
        });
      }
    },
    /**
     * 学生新增多选框
     */
    addStuSelectionChange(val) {
      that.addstuSelArr = val;
    },
    /**
     * 老师新增多选框
     */
    addTeaSelectionChange(val) {
      that.addteaSelArr = val;
    },
    /**
     * 学生多选框
     */
    stuSelectionChange(val) {
      that.stuSelArr = val;
    },
    /**
     * 老师解除多选框
     */
    deleteChange(val) {
      that.delTeacher = val;
    },
    /**
     * 解除考生关联
     */
    stuJiechu(val) {
      if (val == "student") {
        if (!that.stuSelArr.length) {
          this.$message({
            type: "error",
            message: "请先选择考生!",
          });
          return;
        }
        let arr = [];
        that.stuSelArr.forEach((item) => {
          arr.push(item.UID);
        });
        let param = {
          考试ID: that.$route.query["考试ID"],
          房间ID: that.roomID,
          考生: JSON.stringify(arr),
        };
        this.$confirm(
          `此操作将解除选中考生与${that.$route.query["考试名称"]}, 是否继续?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            deleteFun(room_examineeUrl, param).then((res) => {
              if (res.result) {
                that.getStuList();
                this.$message({
                  type: "success",
                  message: "解除关联成功!",
                });
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消解除关联",
            });
          });
      }
      if (val == "teacher") {
        if (!that.delTeacher.length) {
          this.$message({
            type: "error",
            message: "请先选择老师!",
          });
          return;
        }
        let arr = [];
        that.delTeacher.forEach((item) => {
          arr.push(item.UID);
        });
        let param = {
          // 考试ID: that.$route.query["考试ID"],
          房间ID: that.roomID,
          老师: JSON.stringify(arr),
        };
        this.$confirm(
          `此操作将解除选中老师与${that.$route.query["考试名称"]}, 是否继续?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            deleteFun(room_watchUrl, param).then((res) => {
              if (res.result) {
                that.getTeaList();
                this.$message({
                  type: "success",
                  message: "解除关联成功!",
                });
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消解除关联",
            });
          });
      }
    },
    switchFun(val) {
      switch (val) {
        case 3:
          return "监考";
        case 4:
          return "巡考";

        default:
          break;
      }
    },
    getRoomList() {
      let param = {
        批次ID: that.$route.query["批次ID"],
        机构ID: that.$route.query["机构ID"],
        考试ID: that.$route.query["考试ID"],
        状态: 1,
        最大记录数: that.pagesize,
        跳过记录数: that.pagesize * (that.currentPage4 - 1),
      };
      getFun(roomUrl, param).then((res) => {
        console.log(res)
        if (res.result && res.data) {
          that.total = res.total_records;
          that.tableData = res.data;
          that.copyList = JSON.parse(JSON.stringify(res.data));
        }
      });
    },
    addStudent(val) {
      if (val == "student") {
        this.studentDialog = true;

        let stuParam = {
          考试ID: that.$route.query["考试ID"],
        };
        getFun(examineeUrl, stuParam).then((res) => {
          if (res.result && res.data) {
            this.allStudent = res.data.filter((item) => {
              return !item["房间ID"];
            });
          }
        });
      }
      if (val == "teacher") {
        this.teacherDialog = true;
        let teaParam = {
          批次ID: that.$route.query["批次ID"],
          机构ID: that.$route.query["机构ID"],
          考试ID: that.$route.query["考试ID"],
        };
        getFun(watchUrl, teaParam).then((res) => {
          if (res.result && res.data) {
            console.log(res)
            let newArr = []
            res.data.forEach((item) => {
              let indexValue = newArr.findIndex(
                (v) => v.UID == item.UID
              );
              if (indexValue == -1) {
                item["房间"] = [];
                item["房间"].push(item);
                newArr.push(item);
              } else {
                newArr[indexValue]["房间"].push(item);
              }
            });
            that.allTeacher = newArr.filter(item=>{
              return item['房间ID']!=that.roomID
            })
            that.forceRerender();
            that.$forceUpdate();
          }
        });
      }
    },
    getStuList() {
      let stuParam = {
        房间ID: that.roomID,
        批次ID: that.$route.query["批次ID"],
        机构ID: that.$route.query["机构ID"],
        考试ID: that.$route.query["考试ID"],
      };
      this.studentArr = [];
      getFun(examineeUrl, stuParam).then((res) => {
        if (res.result && res.data) {
          this.studentArr = res.data;
        }
      });
    },
    getTeaList() {
      let teaParam = {
        房间ID: that.roomID,
        批次ID: that.$route.query["批次ID"],
        机构ID: that.$route.query["机构ID"],
        考试ID: that.$route.query["考试ID"],
      };
      that.teacherArr = [];
      getFun(watchUrl, teaParam).then((res) => {
        if (res.result && res.data) {
          that.teacherArr = res.data;
          that.forceRerender();
          that.$forceUpdate();
        }
      });
    },
    handleSelectionChange(rows) {
      // this.selectedRowIndex = rows;
      that.examListArr = rows;
    },
    handleClick(row) {
      this.dialogVisible = true;
      that.roomID = row["房间ID"];
      that.roomTitle = row["房间名称"];
      that.getStuList();
      that.getTeaList();
    },
    handleSizeChange(val) {
      that.pagesize = val;
      that.getRoomList();
    },
    handleCurrentChange(val) {
      that.currentPage4 = val;
      that.getRoomList();
    },
  },
  created() {
    that = this;
    that.getRoomList();
  },
};
</script>

<style scoped>
.notSet {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #dd1c1c;
}
.shengcheng {
  width: 300px;
  height: 44px;
  background: #5b95bd;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.chuangjian_1 {
  display: flex;
  align-items: center;
  padding-left: 80px;
  margin-bottom: 20px;
}
/deep/.el-dialog__title {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin-left: 20px;
}
/deep/.el-dialog__headerbtn {
  top: 15px;
}
/deep/.el-dialog__header {
  background: #1d394d;
  height: 40px;
  padding: 0 20px;
  display: flex;
  align-items: center;
}
.quanxian {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  width: 66px;
  height: 26px;
  background: #8c97cb;
  margin: 0 auto;
}
/deep/.el-dialog__title {
  color: #fff;
}
/deep/.el-dialog .el-input__inner {
  background: #f6f6f6;
  border: none;
}
/deep/.el-dialog .el-select {
  margin-left: 30px;
}
.dilogTop {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.dialogBox > div {
  width: 50%;
}
.dialogBox {
  display: flex;
}
/deep/.dialogBox .el-table tr {
  background-color: #f6f6f6;
}
.dialogTop {
  margin-bottom: 15px;
}
.shuju {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #5b95bd;
  /* width: 90px; */
  height: 30px;
  background: #eef8ff;
  border-radius: 4px;
}
.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.num {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #5b95bd;
}
/deep/.seach .el-input__inner {
  background: #f6f6f6;
  border: none;
}
.btn_no1 {
  display: flex;
}
.btn {
  display: flex;
  margin: 27px 0 17px 0;
  justify-content: space-between;
}
.btn .el-button {
  height: 30px;
  background: #eef8ff;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #5b95bd;
  border: none !important;
}
.selBox {
  /* margin-left: 30px; */
  display: flex;
}
/deep/.selBox .el-input__inner {
  border: none !important;
  background: #eef8ff;
}
/deep/.selBox .el-select {
  width: 100px;
  height: 30px;
  border: none !important;
}
.header {
  width: 100%;
}
/deep/.el-dialog__headerbtn {
  top: 15px;
}
/deep/.el-dialog__header {
  background: #1d394d;
  height: 40px;
  padding: 0 20px;
  display: flex;
  align-items: center;
}
</style>