import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: null,
        sltPici: null,
        userCzqx: null, //点击侧边栏跳转  页面操作权限
        routerName: null,
        schoolID: null
    },
    mutations: {
        //更新用户信息
        updateUserInfo(state, newUser) {
            state.user = newUser;
        },
        // 切换批次（考次）
        updatePici(state, value) {
            state.sltPici = value
        },
        // 切换批次（考次）
        getSchoolID(state, value) {
            state.schoolID = value
        },
        // 切换批次（考次）
        getuserCzqx(state, value) {
            state.userCzqx = value
        },
        // 切换批次（考次）
        changeRouterName(state, value) {
            state.routerName = value
        },
    },
    actions: {

    },
    modules: {},
    plugins: [createPersistedState({ storage: window.sessionStorage })]
})