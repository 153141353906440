<template>
  <div>
    <div class="examlist">
      <div class="box1">
        <div class="header">
          <div class="header_box1">
            <div>
              机构选择
              <el-select
                v-model="searchForm.mineStatus"
                clearable
                @clear="handleClear"
                ref="selectUpResId"
                size="small"
                placeholder="请选择"
              >
                <el-option
                  :value="searchForm.mineStatus"
                  key="id"
                  :label="label"
                  style="height: auto"
                >
                  <el-tree
                    :data="jgTable"
                    :props="defaultProps"
                    ref="tree"
                    node-key="机构ID"
                    :expand-on-click-node="false"
                    :check-on-click-node="true"
                    @node-click="handleNodeClick"
                  ></el-tree>
                </el-option>
              </el-select>
            </div>
            <!--  -->
            <div style="margin-left: 30px">
              批次选择
              <el-select
                v-model="piciVal"
                @change="changePici"
                size="small"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in picioptions"
                  :key="item['批次ID']"
                  :label="item['批次名称']"
                  :value="item['批次ID']"
                  size="small"
                >
                </el-option>
              </el-select>
            </div>
            <!-- <div>
              <el-input
                placeholder="请输入内容"
                suffix-icon="el-icon-search"
                style="width: 200px"
                size="small"
                v-model="input2"
              >
              </el-input>
            </div> -->
          </div>
          <div class="btn">
            <el-button size="small" @click="addExam">新建考试</el-button>
            <el-button size="small" @click="createJkData"
              >自动创建监考数据</el-button
            >
            <!-- <el-button size="small" @click="exportExam">导入考试</el-button>
            <span style="margin-left:15px"><a href="考试导入模板.xlsx" download="考试导入模板.xlsx"> 下载导入模板 </a></span> -->
            <input
              ref="examRef"
              v-show="false"
              @change="examClick"
              type="file"
            />

            <div class="selBox">
              考试状态
              <el-select
                v-model="examStu"
                @change="filterExam('考试')"
                size="small"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in status"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  size="small"
                >
                </el-option>
              </el-select>
            </div>
            <div class="selBox">
              监考房间
              <el-select
                v-model="jiankaoStu"
                @change="filterExam('房间')"
                size="small"
                placeholder="请选择"
              >
                <el-option label="全部" value="0" size="small"> </el-option>
                <el-option label="未设置" value="1" size="small"> </el-option>
              </el-select>
            </div>
            <div class="selBox">
              监考老师
              <el-select
                v-model="teacherStu"
                @change="filterExam('监考')"
                size="small"
                placeholder="请选择"
              >
                <el-option label="全部" value="0" size="small"> </el-option>
                <el-option label="未添加" value="1" size="small"> </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="table">
          <el-table
            ref="multipleTable"
            :data="tableData"
            height="600px"
            :header-cell-style="{
              textAlign: 'center',
              background: '#5B95BD',
              fontSize: ' 16px',
              fontFamily: ' PingFang SC',
              fontWeight: 500,
              color: '#FFFFFF',
            }"
            :cell-style="{ textAlign: 'center' }"
            :row-class-name="tableRowClassName"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column fixed type="selection" width="55">
            </el-table-column>
            <el-table-column fixed prop="机构名称" label="机构名称" width="180">
            </el-table-column>
            <el-table-column prop="级别ID" label="机构级别" width="180">
              <template slot-scope="scope">
                <div
                  :class="
                    scope.row['级别ID'] == 10
                      ? 'level'
                      : scope.row['级别ID'] == 20
                      ? 'sheng'
                      : scope.row['级别ID'] == 30
                      ? 'kaoqu'
                      : scope.row['级别ID'] == 50
                      ? 'kaodian'
                      : ''
                  "
                >
                  {{ switchFun(scope.row["级别ID"]) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="批次名称" label="批次名称" width="180">
            </el-table-column>
            <el-table-column prop="考试名称" label="考试名称" width="180">
            </el-table-column>
            <el-table-column
              prop="考前准备时间"
              label="考前准备时间"
              width="120"
            >
            </el-table-column>
            <el-table-column prop="address" label="考试时间" width="180">
              <template slot-scope="scope">
                {{ scope.row["考试开始时间"] | dateFormat }}--{{
                  scope.row["考试结束时间"] | dateEndFormat
                }}
              </template>
            </el-table-column>
            <el-table-column prop="状态" label="考试状态" width="180">
              <template slot-scope="scope">
                <span class="student" v-show="!scope.row['考生人数']">
                  等待添加考生</span
                >
                <span
                  class="student"
                  v-show="!scope.row['监考房间数量'] && scope.row['考生人数']"
                >
                  等待创建监考房间</span
                >
                <span
                  v-show="scope.row['监考房间数量'] && scope.row['考生人数']"
                  :class="
                    scope.row['状态'] == 1
                      ? 'unstart'
                      : scope.row['状态'] == 2
                      ? 'starting'
                      : scope.row['状态'] == 3
                      ? 'end'
                      : ''
                  "
                >
                  {{ statusSwitch(scope.row["状态"]) }}</span
                >
              </template>
            </el-table-column>
            <el-table-column prop="监考房间数量" label="监考房间数" width="180">
              <template slot-scope="scope">
                <span v-show="scope.row['监考房间数量']">{{
                  scope.row["监考房间数量"]
                }}</span>
                <span class="notSet" v-show="!scope.row['监考房间数量']"
                  >未添加</span
                >
              </template>
            </el-table-column>
            <el-table-column prop="考生人数" label="考生总数量" width="180">
              <template slot-scope="scope">
                <span v-show="scope.row['考生人数']">{{
                  scope.row["考生人数"]
                }}</span>
                <span class="notSet" v-show="!scope.row['考生人数']"
                  >未设置</span
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="监考老师人数"
              label="监考老师数量"
              width="180"
            >
              <template slot-scope="scope">
                <span v-show="scope.row['监考老师人数']">{{
                  scope.row["监考老师人数"]
                }}</span>
                <span class="notSet" v-show="!scope.row['监考老师人数']"
                  >未添加</span
                >
              </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" width="200">
              <template slot-scope="scope">
                <el-button
                  @click="handleClick(scope.row)"
                  type="text"
                  size="small"
                  >查看/修改</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click="examManage(scope.row)"
                  >考试管理</el-button
                >
                <el-button
                  type="text"
                  @click="deleteExam(scope.row)"
                  size="small"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[20, 50, 100]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <el-dialog
        title="自动创建监考数据"
        :visible.sync="dialogVisible"
        width="30%"
      >
        <div style="text-align: center">
          <div class="chuangjian_1">
            <span style="margin: 0 10px 0 0">每房间考生数量</span>
            <el-col :span="4">
              <el-input size="small" v-model="form['每房间考生数']"></el-input
            ></el-col>
            <span style="margin-left: 10px">人</span>
            <span style="margin-left: 10px">（每房间最多可分配XX人）</span>
          </div>
          <div class="chuangjian_1">
            <el-col :span="4">
              <el-input size="small" v-model="form['监考房间数']"></el-input
            ></el-col>
            <span style="margin: 0 10px">房间搭配</span>
            <el-col :span="4">
              <el-input size="small" v-model="form['监考老师数']"></el-input
            ></el-col>
            <span style="margin-left: 10px">监考老师数量</span>
          </div>
          <div class="chuangjian_1">
            <el-col :span="4">
              <el-input size="small" v-model="form['巡考房间数']"></el-input
            ></el-col>
            <span style="margin: 0 10px">房间搭配</span>
            <el-col :span="4">
              <el-input size="small" v-model="form['巡考老师数']"></el-input
            ></el-col>
            <span style="margin-left: 10px">巡考老师数量</span>
          </div>
          <div class="chuangjian_1">
            <span style="margin-right: 20px">监考房间名称前缀</span>
            <el-col :span="13">
              <el-input size="small" v-model="form['房间前缀']"></el-input
            ></el-col>
          </div>
          <div class="chuangjian_1">
            <span style="margin-right: 20px">监考老师账号前缀</span>
            <el-col :span="13">
              <el-input size="small" v-model="form['监考老师前缀']"></el-input
            ></el-col>
          </div>
          <div class="chuangjian_1">
            <span style="margin-right: 20px">巡考老师账号前缀</span>
            <el-col :span="13">
              <el-input size="small" v-model="form['巡考老师前缀']"></el-input
            ></el-col>
          </div>
          <el-button class="shengcheng" @click="createJiankao"
            >一键生成</el-button
          >
        </div>
      </el-dialog>
    </div>

    <!-- <router-view></router-view> -->
  </div>
</template>

<script>
let that;

import AddExam from "./ExamListPage/AddExam.vue";
import { mapState, mapMutations } from "vuex";
let batchUrl = "/jiankao/batch";
let organUrl = "/jiankao/organ";
let examUrl = "/jiankao/exam";
let import_examUrl = "/jiankao/import_exam";
let auto_make_room_watchUrl = "/jiankao/auto_make_room_watch";
import { getFun, postFun, deleteFun, putFun } from "../../request/request";
export default {
  name: "ExamList",
  data() {
    return {
      examStu: "",
      jiankaoStu: "",
      teacherStu: "",
      radio: "",
      input2: "",

      defaultProps: {
        children: "children",
        label: "机构名称",
      },
      status: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "未开始",
        },
        {
          value: 2,
          label: "考试中",
        },
        {
          value: 3,
          label: "已结束",
        },
      ],
      piciVal: "",
      picioptions: [],
      tableData: [],
      selectedRowIndex: [], //table多选框索引数组
      examListArr: [],
      value: "",
      currentPage4: 1,
      pagesize: 20,
      total: 0,
      isActive: false,
      form: {
        每房间考生数: "",
        房间前缀: "",
        监考房间数: "",
        巡考房间数: "",
        监考老师数: "",
        巡考老师数: "",
        监考老师前缀: "",
        巡考老师前缀: "",
      },
      dialogVisible: false,
      searchForm: {
        mineStatus: "",
      },
      label: "",
      jgTable: [],
    };
  },
  computed: {
    ...mapState(["schoolID", "user", "sltPici"]),
  },
  methods: {
    ...mapMutations(["changeRouterName", "getSchoolID", "updatePici"]),
    switchFun(val) {
      switch (val) {
        case 10:
          return "国级";
        case 20:
          return "省级";
        case 30:
          return "考区";
        case 40:
          return "学校";
        case 50:
          return "考点";

        default:
          break;
      }
    },
    handleNodeClick(data, node, el) {
      // 这里主要配置树形组件点击节点后，设置选择器的值；自己配置的数据
      that.getSchoolID(data);
      this.label = data["机构名称"];
      // console.log(that.schoolID);

      // 选择器执行完成后，使其失去焦点隐藏下拉框的效果
      this.$refs.selectUpResId.blur();
      that.$forceUpdate();
    },
    // 选择器配置可以清空选项，用户点击清空按钮时触发
    handleClear() {
      // 将选择器的值置空
      this.label = "";
      this.searchForm.mineStatus = "";
    },
    exportExam() {
      that.$refs.examRef.dispatchEvent(new MouseEvent("click"));
    },
    getChild(list, pid = 0) {
      let newArr = [];
      list.forEach((item) => {
        if (item["父机构ID"] == pid) {
          let a = item;
          a.children = that.getChild(list, item["机构ID"]);
          newArr.push(a);
        }
      });
      return newArr;
    },
    /**
     * 获取学校信息
     */
    getSchoolFun() {
      let param = {};
      getFun(organUrl, param).then((res) => {
        if (res.data && res.result) {
          that.schoolArr = res.data;
          that.jgTable = that.getChild(res.data);
          that.getExam();
        }
      });
    },
    examClick() {
      const inputFile = this.$refs.examRef.files[0];
      const formData = new FormData();
      formData.append("批次ID", that.piciVal);
      formData.append("机构ID", that.user["机构ID"]);
      formData.append("考试数据文件", inputFile);
      formData.append("调试模式", "ZDFGsdxcvsdf");
      postFun(import_examUrl, formData).then((res) => {
        if (res.result) {
          this.fullscreenLoading = false;
          this.$message({
            message: "考试导入成功",
            type: "success",
          });
          that.getExam();
        }
      });
    },
    filterExam(val) {
      that.tableData = that.newTableData.filter((item) => {
        let aaa = true;
        let bbb = 0;
        if (
          (this.examStu == 0 || this.examStu == item["状态"]) &&
          (this.jiankaoStu == 0 || !item["监考房间数量"]) &&
          (this.teacherStu == 0 || !item["监考老师人数"])
        ) {
          return item; // 把符合条件的数据返回,模板中通过that.tableData可直接动态读取数据
        }
      });
    },
    /**
     * 一键生成监考数据
     */
    createJiankao() {
      let arr = [];
      that.examListArr.forEach((item) => {
        arr.push(item["考试ID"]);
      });
      let param = {
        批次ID: that.piciVal,
        机构ID: that.user["机构ID"],
        考试ID: JSON.stringify(arr),
        每房间考生数: that.form["每房间考生数"],
        房间前缀: that.form["房间前缀"],
      };
      param["监考老师"] = {};
      param["巡考老师"] = {};
      param["监考老师"]["前缀"] = that.form["监考老师前缀"];
      param["巡考老师"]["前缀"] = that.form["巡考老师前缀"];
      param["监考老师"]["密码"] = "123456";
      param["巡考老师"]["密码"] = "123456";
      param["监考老师"]["分配规则"] = {};
      param["巡考老师"]["分配规则"] = {};
      param["监考老师"]["分配规则"]["房间数"] = that.form["监考房间数"];
      param["巡考老师"]["分配规则"]["房间数"] = that.form["巡考房间数"];
      param["监考老师"]["分配规则"]["老师数"] = that.form["监考老师数"];
      param["巡考老师"]["分配规则"]["老师数"] = that.form["巡考老师数"];
      param["监考老师"] = JSON.stringify(param["监考老师"]);
      param["巡考老师"] = JSON.stringify(param["巡考老师"]);
      postFun(auto_make_room_watchUrl, param).then((res) => {
        if (res.result) {
          that.form = {
            每房间考生数: "",
            房间前缀: "",
            监考房间数: "",
            巡考房间数: "",
            监考老师数: "",
            巡考老师数: "",
            监考老师前缀: "",
            巡考老师前缀: "",
          };
          this.$message({
            type: "success",
            message: "创建成功!",
          });
          that.dialogVisible = false;
          that.getExam();
        }
      });
    },
    /**
     * 考试管理
     */
    examManage(val) {
      if (!that.piciVal) {
        this.$message({
          type: "error",
          message: "请选择批次!",
        });
        return;
      }
      // return
      val.isAdd = false;
      if (val["考试设置"]) {
        val["考试设置"] = JSON.parse(val["考试设置"]);
      }
      that.$router.push({ path: "/addExam", query: val });
    },
    statusSwitch(val) {
      switch (val) {
        case 1:
          return "未开始";
        case 2:
          return "考试中";
        case 3:
          return "已结束";

        default:
          break;
      }
    },
    /**
     * 删除考试
     */
    deleteExam(val) {
      let param = {
        考试ID: val["考试ID"],
      };
      this.$confirm("此操作将删除该考试, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteFun(examUrl, param).then((res) => {
            if (res.result) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              that.getExam();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    /**
     * 选择批次
     */
    changePici(val) {
      that.updatePici(val);
      that.getExam();
    },

    /**
     * 获取批次数据
     */
    getPici() {
      getFun(batchUrl, { 状态: 1 }).then((res) => {
        if (res.data && res.result) {
          that.picioptions = res.data;
          // that.piciVal = res.data[0]["批次ID"];
        }
      });
    },
    /**
     * 获取考试列表
     */
    getExam() {
      let param = {
        批次ID: that.piciVal,
        机构ID: that.searchForm.mineStatus,
        最大记录数: that.pagesize,
        跳过记录数: that.pagesize * (that.currentPage4 - 1),
      };
      getFun(examUrl, param).then((res) => {
        if (res.data && res.result) {
          that.tableData = res.data;
          that.tableData.forEach((item) => {
            that.schoolArr.forEach((items) => {
              if (item["机构ID"] == items["机构ID"]) {
                item["级别ID"] = items["级别ID"];
              }
            });
          });
          that.$forceUpdate();
          that.total = res.total_records;
          that.newTableData = JSON.parse(JSON.stringify(res.data));
        } else {
          that.tableData = [];
        }
      });
    },
    createJkData() {
      if (!that.examListArr.length) {
        this.$message({
          type: "error",
          message: "请选择考试!",
        });
        return;
      }
      if (!that.piciVal) {
        this.$message({
          type: "error",
          message: "请选择批次!",
        });
        return;
      }
      let arr = [];
      that.examListArr.forEach((item) => {
        if (!item["考生人数"]) {
          arr.push(item["考试名称"]);
        }
      });
      if (arr.length) {
        let str = arr.join(",");
        this.$message({
          type: "error",
          message: `当前考试 ${str} 没有考生，请先导入考生!`,
        });
        return;
      }
      that.dialogVisible = true;
    },
    addExam() {
      if (!that.piciVal) {
        this.$message({
          type: "error",
          message: "请选择批次!",
        });
        return;
      }
      if (!that.schoolID) {
        this.$message({
          type: "error",
          message: "请选择机构!",
        });
        return;
      }
      that.$router.push({
        path: "/addExam",
        query: {
          批次ID: that.piciVal,
          机构ID: that.searchForm.mineStatus,
          isAdd: true,
        },
      });
    },
    handleSizeChange(val) {
      that.pagesize = val;
    },
    handleCurrentChange(val) {
      that.currentPage4 = val;
    },
    // getData() {
    //   getFun(xuexiaoUrl, { 学校ID: 1 }).then((res) => {
    //     console.log(res);
    //   });
    // },
    handleClick(row) {
      that.$router.push({ path: "/createJiankaoData", query: row });
      that.$route.meta.isAdd = row["考试名称"];

      // that.$forceUpdate()
    },
    handleSelectionChange(rows) {
      // this.selectedRowIndex = rows;
      that.examListArr = rows;
      // 选中变色
      let arr = [];
      rows.forEach((e) => {
        arr.push(e.index);
      });
      /* selectedRowIndex：记录（取消）选中行事件前 所有已选中行的索引；
       * arr：记录（取消）选中行事件后 所有已选中行的索引；
       * arr数组长度大于selectedRowIndex数组，表明选中了新的一行 */
      if (arr.length > this.selectedRowIndex.length) {
        this.selectedRowIndex = Object.values(JSON.parse(JSON.stringify(arr))); //将arr的数据复制给selectedRowIndex
        let rowIndex = rows[rows.length - 1].index; //最后被选中行的索引
        this.tableData[rowIndex].isHighLight = true; //高亮显示最后被选中的行
      } else {
        /* arr数组长度小于selectedRowIndex数组，表明取消选中了某一行
         * 通过indexOf方法找出被取消选中的那行 */
        for (let i = 0; i < this.selectedRowIndex.length; i++) {
          if (arr.indexOf(this.selectedRowIndex[i]) === -1) {
            this.tableData[this.selectedRowIndex[i]].isHighLight = false;
          }
        }
        this.selectedRowIndex = Object.values(JSON.parse(JSON.stringify(arr)));
      }
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex; //为每行数据对象添加“索引”属性，在handleSelectionChange方法中会用到
      row = this.tableData;
      if (row[rowIndex].isHighLight === true) {
        return "rowHighLight"; //若isHighLight为true，则使用“rowHighLight”样式
      } else {
        return "";
      }
    },
  },
  mounted() {
    that.getPici();
    that.getSchoolFun();
    if (that.schoolID) {
      that.handleNodeClick(that.schoolID);
      that.searchForm.mineStatus = String(that.schoolID["机构ID"]);
    }
  },
  created() {
    that = this;
    that.piciVal = that.sltPici;
    that.$forceUpdate();
  },
  watch: {
    schoolID: {
      handler(newval) {
        that.searchForm.mineStatus = String(that.schoolID["机构ID"]);
        that.getExam();
      },
      deep: true,
    },
  },
  components: {
    AddExam,
  },
};
</script>

<style scoped>
/deep/.el-icon-upload-success {
  display: none !important;
}
/deep/.el-icon-close {
  display: none !important;
}
.end {
  width: 47px;
  height: 16px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
}
.starting {
  width: 46px;
  height: 16px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #5bbd7e;
}
.student {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #dd1c1c;
}
.level {
  background: #e5903c;
  border-radius: 2px;
  color: #fff;
  width: 66px;
  height: 24px;
  margin: 0 auto;
}
.sheng {
  background: #38bbc3;
  border-radius: 2px;
  color: #fff;
  width: 66px;
  height: 24px;
  margin: 0 auto;
}
.kaoqu {
  background: #3291d3;
  border-radius: 2px;
  color: #fff;
  width: 66px;
  height: 24px;
  margin: 0 auto;
}
.kaodian {
  background: #9b69e6;
  border-radius: 2px;
  color: #fff;
  width: 66px;
  height: 24px;
  margin: 0 auto;
}
.notSet {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #dd1c1c;
}
.shengcheng {
  width: 300px;
  height: 44px;
  background: #5b95bd;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.chuangjian_1 {
  display: flex;
  align-items: center;
  padding-left: 80px;
  margin-bottom: 20px;
}
/deep/.el-dialog__title {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin-left: 20px;
}
/deep/.el-dialog__headerbtn {
  top: 15px;
}
/deep/.el-dialog__header {
  background: #1d394d;
  height: 40px;
  padding: 0 20px;
  display: flex;
  align-items: center;
}
/deep/.header_box1 .el-input__inner {
  border: none !important;
  background: #eef8ff;
}

/deep/.selBox .el-input__inner {
  border: none !important;
  background: #eef8ff;
}
/deep/.selBox .el-select {
  width: 150px;
  border: none !important;
}
.selBox {
  margin-left: 30px;
}
/deep/ .rowHighLight {
  background: #c3dcee;
}
.el-pagination {
  margin-top: 20px;
  float: right;
}
.box1 {
  padding: 20px 30px;
}
.btn {
  padding: 15px 0;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #1d394d;
  display: flex;
  align-items: center;
}
.btn .el-button {
  background: #eef8ff;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #5b95bd;
}
.header_box1 {
  display: flex;
  padding: 15px 0;
}
.examlist {
  width: 100%;
  height: 100%;
  background-color: #fff;
  /* padding: 18px 20px; */
  overflow: hidden;
  overflow-y: auto;
}
</style>