var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"import"},[_c('div',{staticClass:"text"},[_vm._v(" 导入考生 "),_c('span',{staticStyle:{"color":"#5b95bd"}},[_vm._v(_vm._s(_vm.studentNum)+"人")]),_vm._v(" ，考生照片"),_c('span',{staticStyle:{"color":"#fa0d0d"}},[_vm._v(_vm._s(_vm.photoNum)+"张")])]),_c('div',{staticStyle:{"margin-top":"90px"}},[_c('div',{staticClass:"uploadBtn"},[_c('span',{staticStyle:{"margin-right":"20px"}},[_vm._v("考生名单导入")]),_c('el-upload',{staticClass:"upload-demo",attrs:{"action":"#","before-upload":_vm.handlePreview,"on-remove":_vm.handleRemove,"before-remove":_vm.beforeRemove,"multiple":"","limit":1,"on-exceed":_vm.handleExceed,"file-list":_vm.fileList}},[_c('el-button',{staticClass:"shangchuan",attrs:{"size":"small"}},[_vm._v("导入")])],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.tableData.length),expression:"tableData.length"}],staticClass:"watchStudent",on:{"click":_vm.watchStudent}},[_vm._v("查看考生详情")])],1)]),_vm._m(0),_vm._m(1)]),_c('div',{staticClass:"foot"},[_c('el-button',{staticClass:"creatExam",on:{"click":function($event){return _vm.createExam('top')}}},[_vm._v("上一步")]),_c('el-button',{staticClass:"creatExam",on:{"click":function($event){return _vm.createExam('bottom')}}},[_vm._v("下一步")])],1),_c('el-dialog',{attrs:{"title":"考生名单导入详情","visible":_vm.dialogVisible,"width":"80%"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticClass:"table"},[_c('el-table',{ref:"multipleTable",staticStyle:{"width":"100%"},attrs:{"id":"exportTab","data":_vm.tableData,"header-cell-style":{
          textAlign: 'center',
          background: '#5B95BD',
          fontSize: ' 16px',
          fontFamily: ' PingFang SC',
          fontWeight: 500,
          color: '#FFFFFF',
        },"height":"500px","cell-style":{ textAlign: 'center' },"row-class-name":_vm.tableRowClassName}},[_c('el-table-column',{attrs:{"prop":"姓名","label":"考生姓名","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row["姓名"]))])]}}])}),_c('el-table-column',{attrs:{"prop":"报名号","label":"考生准考证号"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row["报名号"]))])]}}])}),_c('el-table-column',{attrs:{"prop":"证件号","label":"考生身份证号"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row["证件号"]))])]}}])}),_c('el-table-column',{attrs:{"prop":"address","label":"考生照片"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(scope.row['照片']),expression:"scope.row['照片']"}],attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.watchFun(scope.row)}}},[_vm._v("查看")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!scope.row['照片']),expression:"!scope.row['照片']"}],staticStyle:{"color":"red"}},[_vm._v("未上传")])]}}])}),_c('el-table-column',{attrs:{"prop":"密码","label":"考生初始密码"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row["密码"]))])]}}])})],1),_c('div',{staticClass:"footer"},[_c('el-pagination',{attrs:{"background":"","current-page":_vm.currentPage4,"page-sizes":[20, 50, 100],"page-size":_vm.pagesize,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)],1)]),_c('el-dialog',{attrs:{"title":"考生照片","visible":_vm.photoDialog,"width":"25%"},on:{"update:visible":function($event){_vm.photoDialog=$event}}},[_c('img',{attrs:{"src":_vm.imgUrl,"alt":""}})])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zhuyi"},[_c('div',[_vm._v("注：")]),_c('div',{staticClass:"point"},[_c('div',[_vm._v("1、可以将考生、监考房间、监考老师编排一起导入；")]),_c('div',[_vm._v(" 2、导入时如果只有一个名单文件，则可以只导入Excel文件，需要导入多个文件时则需放到一个ZIP包中导入； ")]),_c('div',[_vm._v(" 3、导入时如果包含考生照片，需要将考生名单和照片放到一个ZIP包中导入。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"upload"},[_c('a',{attrs:{"href":"考生导入.zip","download":"考生导入.zip"}},[_vm._v(" 下载导入模板 ")])])
}]

export { render, staticRenderFns }