export default {
    //监控方式
    JK_GUANBI: 0, //关闭监控
    JK_ZHENGMIAN: 1, //正面
    JK_CEMIAN: 2, //侧面
    JK_SHUANGJIWEI: 3, //双机位
    JK_ZHENGAPP: 4, //正面+APP

    //RTM和TRC
    MSG_ZFZS: 'zfzs_', //学生账号前缀
    MSG_ZFZT: 'zfzt_', //教师账号前缀
    MSG_CHANNEL: 'ZHIFUZI_MESSAGE_', //消息通道前缀
    MSG_LOCAL_STREAM: 'local_stream_',
    MSG_ZHIFUZI_JIANKONG: 'ZHIFUZI_JIANKONG_', //监控房间前缀

    //用户信息
    USER_DFTPSW: '12345678'
};

// 考生状态：
// 1 已报名 
// 2 考试中 
// 3 已交卷 
// 4 正在上传作答 
// 5 已上传作答 
// 10 已登录，未作答

//code: 1-继续考试, 2-不继续考试, 3-强制交卷, 4-纯消息, 5-审核消息, 6-打开麦克风, 7-关闭麦克风, 8-打开扬声器, 9-关闭扬声器, 20-跳过人脸识别