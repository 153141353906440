<template>
  <div class="roomPage">
    <div class="rpNav">
      <span class="csrPt" v-if="stepInfo=='stuvdo'" @click="backToExamList()" title="返回考试列表">
        <i class="el-icon-arrow-left backIcon"></i>
        <!-- <i class="el-icon-s-home cl5B95BD"></i> -->
        <img src="@/assets/image/ic_fangjian1.png" alt="">
      </span>
      <span class="ml5" v-html="navTitle"></span>
      <!-- <el-button @click="testFun(3)" size="mini">测试按钮3</el-button> -->
    </div>
    <div class="rpWrap" :class="{rpUnfold: !msgWrapFold}">
      <!-- 房间列表容器 -->
      <div class="rmListCont" v-if="stepInfo=='kslist'">
        <!-- <div class="piciCont">
          <div class="demo-input-suffix">
            批次选择
            <el-select v-model="sltPiCiVal" placeholder="请选择" size="small" @change="changePiCi">
              <el-option
                v-for="pici in piCiData"
                :key="pici['批次ID']"
                :label="pici['批次名称']"
                :value="pici['批次ID']">
              </el-option>
            </el-select>
          </div>
        </div> -->
        <div class="stateBtns">
          <el-row>
            <el-col :span="16">
              <div class="piciCont">
                <div class="demo-input-suffix">
                  批次选择
                  <el-select v-model="sltPiCiVal" placeholder="请选择" size="small" @change="changePiCi">
                    <el-option
                      v-for="pici in piCiData"
                      :key="pici['批次ID']"
                      :label="pici['批次名称']"
                      :value="pici['批次ID']">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="dpIb ml20">
                <el-button type="grayblue" size="medium" @click="changeExamState(0)" 
                  :class="{grayblueOn:sltExamState===0}">
                  全部
                </el-button>
                <el-button type="grayblue" size="medium" @click="changeExamState(1)" 
                  :class="{grayblueOn:sltExamState===1}">
                  未开始
                </el-button>
                <el-button type="grayblue" style="margin-left:15px;" size="medium" @click="changeExamState(2)" 
                  :class="{grayblueOn:sltExamState===2}">
                  考试中
                </el-button>
                <el-button type="grayblue" style="margin-left:15px;" size="medium" @click="changeExamState(3)" 
                  :class="{grayblueOn:sltExamState===3}">
                  已结束
                </el-button>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="refreshWarp">
                <el-button type="lightblue" size="medium" @click="refreshData()">
                  刷新数据
                </el-button>
              </div>
              <!-- <div class="searchBox">
                <el-input
                  class="schbInput"
                  size="medium"
                  placeholder="请输入房间名称"
                  v-model="searchVal">
                </el-input>
                <span class="schBtn" @click="searchRoom()">
                  <i class="el-icon-search"></i>
                </span>
              </div> -->
            </el-col>
          </el-row>
        </div>
        <div class="examCont">
          <!-- 考试列表table -->
          <el-table
            :data="examData"
            style="width: 100%">
            <el-table-column
              prop="考试名称"
              label="考试名称"
              align="center"
              header-align="center">
            </el-table-column>
            <el-table-column
              prop="房间名称"
              label="房间名称"
              align="center"
              header-align="center">
            </el-table-column>
            <el-table-column
              prop="正式考试时间"
              label="正式考试时间"
              align="center"
              width="200"
              header-align="center">
            </el-table-column>
            <el-table-column
              prop="监考老师在线人数"
              label="监考老师在线数量"
              align="center"
              width="140"
              header-align="center">
            </el-table-column>
            <el-table-column
              prop="考生人数"
              label="应到考生"
              align="center"
              width="100"
              header-align="center">
            </el-table-column>
            <el-table-column
              prop="考生在线人数"
              label="已在线考生"
              align="center"
              width="100"
              header-align="center">
            </el-table-column>
            <el-table-column label="操作" 
              width='200' 
              align="center">
              <template slot-scope="scope">
                <el-button class="seeMsgBtn" size="mini" type="text" @click="seeMessage(scope.row)">查看消息</el-button>
                <a href="javascript:;" class="ml20 goToRoomBtn" @click="goToRoomPage(scope.row)">进入房间>></a>
              </template>
            </el-table-column>
          </el-table>
          <div class="pageCont">
            <el-pagination
              background
              layout="prev, pager, next"
              prev-text="上一页" 
              next-text="下一页" 
              @current-change="pageTurn"
              :current-page.sync="page.current" 
              :page-size="page.size"
              :total="page.total">
            </el-pagination>
            <div class="jumpCont">
              <el-input class="jumpInput" v-model="jumpPage"></el-input>
              <el-button class="jumpBtn" type="text" @click="jumpPageFun()">跳转</el-button>
            </div>
          </div>
        </div>
      </div>
      <!-- 学生影像列表 -->
      <div class="stuVideoWrap" v-if="stepInfo=='stuvdo'">
        <div class="roomNav">
          <el-row :gutter="20">
            <el-col :span="19">
              <ul class="rnRoomList">
                <li v-for="room in roomData" :key="room['房间ID']" 
                  :class="{rnRoomOn:selectRoom['房间ID']==room['房间ID']}" @click="changeRoom(room)">
                  <i class="el-icon-s-home"></i>
                  <span class="rnrlName">{{room['房间名称']}}</span>
                </li>
              </ul>
            </el-col>
            <el-col :span="5" class="rnSchWrap">
              <div class="searchBox">
                <el-input
                  class="schbInput"
                  size="medium"
                  placeholder="请输入房间名称"
                  v-model="searchVal">
                </el-input>
                <span class="schBtn" @click="searchRoom()">
                  <i class="el-icon-search"></i>
                </span>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="videoWrap">
          <div class="vwRoomOutline" v-if="onlineInfo">
            <span class="vwroInfo">房间应到人数：{{onlineInfo['应到人数']}}人</span>
            <span class="vwroInfo">一机位在线人数：{{onlineInfo['一机位在线人数']}}人</span>
            <span class="vwroInfo">二机位在线人数：{{onlineInfo['二机位在线人数']}}人</span>
            <span class="vwroInfo">考试时间：{{onlineInfo['考试时间']}}</span>
          </div>
          <!-- 学生视频 -->
          <div class="videos">
            <div v-show="renderComponent"></div>
            <div class="noStudent" v-if="!(studentData && studentData.length)">
              此房间没有考生数据！
            </div>
            <el-card class="studentCard" v-for="stu in studentData" :key="stu['UID']" :id="gernerateId(stu['UID'], 'student')">
              <div class="restoreBox">
                <span class="ml20">{{stu['报名号'] || stu['学号']}}</span>
                <span class="ml15">{{stu['姓名']}}</span>
                <el-button type="danger" size="mini" class="restoreBtn" @click="restoreVideo(stu)" :title="$t('lang.closeVideo')">
                  关闭
                </el-button>
              </div>
              <!-- 正面监控 -->
              <div :id="gernerateAvtId(stu['UID'], 'talkTch')" class="stuVideo_1" v-show="jkType!=3">
                <div class="stuOnline">
                  <div :id="gernerateId(stu['UID'], 'stu', 1)" class="stuOnline">
                    <div class="stuOffline">
                      {{$t('lang.noSignal')}}({{$t('lang.front')}})
                    </div>
                  </div>
                </div>
                <el-row :id="gernerateId(stu['UID'], 'tlkb', 1)" class="talkBox">
                  <el-col :span="10">
                    <!-- 呼叫提醒 -->
                    <a href="javascript:;" :id="gernerateAvtId(stu['UID'], 'onejt')" class="jkIcon huJiao" 
                      @click="conncetCall(stu, '正面')" title="接通呼叫">
                      <i class="el-icon-hujiao smallIcon"></i>
                      <img class="bigIcon" src="@/assets/image/ic_laidianshi@2x.png" alt="">
                    </a>
                    <!-- 考生截图 -->
                    <a href="javascript:;" class="jieTuBtn" @click="handShotImg('正面', stu)">
                      <img src="@/assets/image/ic_kaohengjietu_new.png" alt="">
                    </a>
                  </el-col>
                  <el-col :span="14" class="talnRt">
                    <a href="javascript:;" :id="gernerateAvtId(stu['UID'], 'talk')" class="jkIcon talk mr7" 
                      @click="talkWithStu(stu)" :title="$t('lang.openMic')">
                      <!-- 通话 -->
                      <i class="el-icon-closemic smallIcon"></i>
                      <img class="bigIcon" src="@/assets/image/ic_maikefeng2.png" alt="">
                    </a>
                    <a href="javascript:;" :id="gernerateAvtId(stu['UID'], 'endTalk')" class="jkIcon endTalk mr7" 
                      @click="endTalkWithStu(stu)" :title="$t('lang.closeMic')">
                      <!-- 结束通话 -->
                      <i class="el-icon-openmic smallIcon"></i>
                      <img class="bigIcon" src="@/assets/image/ic_maikefeng1.png" alt="">
                    </a>
                    <a href="javascript:;" :id="gernerateAvtId(stu['UID'], 'headset')" class="jkIcon headset" 
                      @click="openStuHeadset(stu)" :title="$t('lang.openHeadset')">
                      <!-- 打开扬声器 -->
                      <i class="el-icon-closespeaker smallIcon"></i>
                      <img class="bigIcon" src="@/assets/image/ic_tingtong da1.png" alt="">
                    </a>
                    <a href="javascript:;" :id="gernerateAvtId(stu['UID'], 'endHeadset')" class="jkIcon endHeadset" 
                      @click="closetuHeadset(stu)" :title="$t('lang.closeHeadset')">
                      <!-- 关闭扬声器 -->
                      <i class="el-icon-openspeaker smallIcon"></i>
                      <img class="bigIcon" src="@/assets/image/ic_tingtong da2.png" alt="">
                    </a>
                  </el-col>
                </el-row>
                <div class="voiceBar voiceBar1" :id="gernerateAvtId(stu['UID'], 'voiceBar')">
                  <p class='vcbrInner' :style="{height: stu['音量'] + 'px'}"></p>
                  <!-- <p class='vcbrInner' :style="{height:percentage + 'px'}"></p> -->
                </div>
              </div>
              <!-- 侧面监控 -->
              <div class="stuVideo_2" v-show="jkType!=2">
                <div class="stuOnline">
                  <div :id="gernerateId(stu['UID'], 'stu', 2)" class="stuOnlineInner">
                    <div class="stuOffline">
                      {{$t('lang.noSignal')}}({{$t('lang.side')}})
                    </div>
                  </div>
                </div>
                <!-- 侧机位通话 -->
                <el-row :id="gernerateId(stu['UID'], 'tlkb', 2)" class="talkBox">
                  <el-col :span="10">
                    <!-- 呼叫提醒 -->
                    <a href="javascript:;" :id="gernerateAvtId(stu['UID'], 'twojt')" class="huJiao" 
                      @click="conncetCall(stu, '侧面')" title="接通呼叫">
                      <i class="el-icon-erhujiao smallIcon"></i>
                      <img class="bigIcon" src="@/assets/image/ic_laidianshi@2x.png" alt="">
                    </a>
                    <!-- 考生截图 -->
                    <a href="javascript:;" class="jieTuBtn" @click="handShotImg('侧面', stu)">
                      <img src="@/assets/image/ic_kaohengjietu_new.png" alt="">
                    </a>
                  </el-col>
                  <el-col :span="14" class="talnRt">
                    <a href="javascript:;" class="jkIcon" @click="endBackCameraTalk(stu)" v-if="stu['侧方位通话中']">
                      <i class="el-icon-eropen smallIcon"></i>
                      <img class="bigIcon" src="@/assets/image/ic_maikefengyangshengqi2@2x.png" alt="">
                    </a>
                    <a href="javascript:;" class="jkIcon" @click="backCameraTalk(stu)" v-else>
                      <i class="el-icon-erclose smallIcon"></i>
                      <img class="bigIcon" src="@/assets/image/ic_maikefengyangshengqi@2x.png" alt="">
                    </a>
                  </el-col>
                </el-row>
                <div class="voiceBar" v-if="stu['侧方位通话中']">
                  <p class='vcbrInner' :style="{height: stu['音量'] + 'px'}"></p>
                </div>
              </div>
              <!-- 学生信息 -->
              <div class="stuName">
                <p class="snTxt">{{stu['报名号'] || stu['学号']}}</p>
                <p class="snTxt">{{stu['姓名']}}</p>
              </div>
              <!-- 考试剩余时间 -->
              <!-- <h4 class="ksLftTime">
                <span v-if="stu['剩余分钟']>=0">
                  <span class="clRed" v-if="stu['考后剩余时间']>0">
                    <span class="cl1D394D">{{$t('lang.khsyMin')}}：</span>
                    <span class="cl5B95BD">{{stu['考后剩余时间']}} {{$t('lang.minute')}}</span> 
                  </span>
                  <span v-else>
                    <span class="cl1D394D">{{$t('lang.left')}}：</span>
                    <span class="cl5B95BD">{{stu['剩余分钟'] || 0}} {{$t('lang.minute')}}</span> 
                  </span>
                </span>
                <span v-else>{{$t('lang.unLogin')}}</span>
              </h4> -->
              <!-- 异常次数 -->
              <div class="anomalyNums" :id="gernerateId(stu['UID'], 'anNum')">
                <span>{{$t('lang.abnormal')}}：</span>
                <span class="clDD1C1C ftwt600">
                  {{stu['异常次数'] || 0}} {{$t('lang.times')}}
                </span>
              </div>
              <!-- 功能按钮 -->
              <div class="jkBtnBox">
                <!-- 发消息 -->
                  <a href="javascript:;" class="jkIcon mr30" @click="sendMsgPop(stu)" title="文字沟通">
                    <img src="@/assets/image/ic_wenzixiaoxi.png" alt="">
                    <el-badge :value="stu['未读消息数量']" :max="99" class="rmBadge" v-if="stu['未读消息数量']">
                    </el-badge>
                  </a>
                <!-- 查看照片 -->
                <a href="javascript:;" class="jkIcon mr30" @click="seeStuImagePop(stu)" title="考生现场照片">
                  <img src="@/assets/image/ic_tupian.png" alt="">
                </a>
                <!-- 查看现场环境视频 -->
                <!-- <a href="javascript:;" class="jkIcon mr30" @click="seeEnvtVideo(stu)" title="环境拍摄视频"> -->
                <a href="javascript:;" class="jkIcon mr30" @click="seeStuImagePop(stu, 'vdo')" title="环境拍摄视频">
                  <img src="@/assets/image/ic_huanjingshipin.png" alt="">
                </a>
                <!-- 放大视频 -->
                <a href="javascript:;" class="jkIcon" @click="zoomVideo(stu)" title="放大视频">
                  <img src="@/assets/image/ic_fangda.png" alt="">
                </a>
              </div>
              <!-- 放大后的功能按钮 -->
              <div class="jkBtnBoxBig">
                <!-- 文字沟通 -->
                <a href="javascript:;" class="jkIcon" @click="sendMsgPop(stu)">
                  <img src="@/assets/image/ic_wenzixiaoxi(1).png" alt="">
                  <span class="btnName">文字沟通</span>
                  <el-badge :value="10" :max="99" class="rmBadge">
                  </el-badge>
                </a>
                <!-- 查看考生照片 -->
                <a href="javascript:;" class="jkIcon" @click="seeStuImagePop(stu)">
                  <img src="@/assets/image/ic_zhaopain.png" alt="">
                  <span class="btnName">考生照片</span>
                </a>
                <!-- 考生截图 -->
                <!-- <a href="javascript:;" class="jkIcon" @click="handShotImg('正面', stu)">
                  <img src="@/assets/image/ic_kaohengjietu.png" alt="">
                  <span class="btnName">考生截图</span>
                </a> -->
                <!-- 环境拍摄视频 -->
                <!-- <a href="javascript:;" class="jkIcon" @click="seeEnvtVideo(stu)"> -->
                <a href="javascript:;" class="jkIcon" @click="seeStuImagePop(stu, 'vdo')">
                  <img src="@/assets/image/ic_huanjingshipin.png" alt="">
                  <span class="btnName">环境拍摄视频</span>
                </a>
                <!-- 考试情况记录 -->
                <a href="javascript:;" class="jkIcon" @click="kaoShiRecord(stu)">
                  <img src="@/assets/image/ic_kaoshengqingkuangjilu.png" alt="">
                  <span class="btnName">考试情况记录</span>
                </a>
              </div>
            </el-card>
          </div>
          <!-- 监控教师 -->
          <div class="tchVideos" v-show="studentData && studentData.length">
            <div class="tchFunBtns">
              <!-- 开启麦克风 -->
              <a href="javascript:;" class="jkIcon ml30" @click="muteAndUnmuteLocalAudio('解除静音')" v-if="tcherMute">
                <img src="@/assets/image/ic_mkf1.png" alt="">
                <span class="btnName">麦克风已关闭</span>
              </a>
              <a href="javascript:;" class="jkIcon ml30" @click="muteAndUnmuteLocalAudio('静音')" v-else>
                <img src="@/assets/image/ic_mkf2.png" alt="">
                <span class="btnName">麦克风已开启</span>
              </a>
              <!-- 群体监听 -->
              <a href="javascript:;" class="jkIcon ml30" @click="openAllMic()" v-if="tcherHear" >
                <img src="@/assets/image/ic_quntijianting2.png" alt="">
                <span class="btnName">群体监听已开启</span>
              </a>
              <a href="javascript:;" class="jkIcon ml30" @click="openAllMic()" v-else>
                <img src="@/assets/image/ic_quntijianting1.png" alt="">
                <span class="btnName">群体监听已关闭</span>
              </a>
              <!-- 群体发言 -->
              <a href="javascript:;" class="jkIcon ml30" @click="openAllHeadset()" v-if="tcherTalk">
                <img src="@/assets/image/ic_quntifayan2.png" alt="">
                <span class="btnName">群体发言已开启</span>
              </a>
              <a href="javascript:;" class="jkIcon ml30" @click="openAllHeadset()" v-else>
                <img src="@/assets/image/ic_quntifayan1.png" alt="">
                <span class="btnName">群体发言已关闭</span>
              </a>
              <!-- 群发消息 -->
              <a href="javascript:;" class="jkIcon ml30" @click="sendMsgPop('all')">
                <img src="@/assets/image/ic_qunfaxiaoxi.png" alt="">
                <span class="btnName">群发消息</span>
              </a>
            </div>
            <div class="videos">
              <el-card class="studentCard" v-for="tch in teacherData" :key="tch['UID']">
                <div class="stuVideo">
                  <div class="stuOnline">
                    <div :id="gernerateId(tch['UID'])" class="stuOnline">
                      <div class="stuOffline">
                        {{$t('lang.noSignal')}} 
                      </div>
                    </div>
                  </div>
                </div>
                <div class="teacherVolume">
                  <span class="fz14 fll">{{tch['姓名']}}</span>
                  <span v-if="isTchJoin">
                    <span class="clRed" v-if="tch['教师静音']">
                      <!-- <Icon type="md-mic-off" /> -->
                    </span>
                    <span class="darkBlue" v-else>
                      <!-- <Icon type="md-mic" /> -->
                    </span>
                  </span>
                </div>
              </el-card>
              <el-card class="studentCard" v-for="tch in remoteTeacher" :key="tch['UID']">
                <!-- 正面监控 -->
                <div class="stuVideo" v-show="jkType!=3">
                  <div class="stuOnline">
                    <div :id="gernerateId(tch['UID'], 'tch', 1)" class="stuOnline">
                      <div class="stuOffline">
                        {{$t('lang.noSignal')}}({{$t('lang.front')}})
                      </div>
                    </div>
                  </div>
                </div>
                <div class="teacherVolume">
                  <span class="fz14 fll">{{tch['姓名']}}</span>
                  <span class="clRed" v-if="tch['教师静音']">
                    <!-- <Icon type="md-mic-off" /> -->
                  </span>
                  <span class="darkBlue" v-else>
                    <!-- <Icon type="md-mic" /> -->
                  </span>
                </div>
                <!-- 后面监控 -->
                <div class="stuVideo" style="display:none">
                  <div class="stuOnline">
                    <div :id="gernerateId(tch['UID'], 'tch', 2)" class="stuOnlineInner">
                      <div class="stuOffline">
                        {{$t('lang.noSignal')}}({{$t('lang.side')}})
                      </div>
                    </div>
                  </div>
                </div>
              </el-card>
            </div>
          </div>
        </div>
      </div>
      <!-- 展开和收起的按钮 -->
      <div class="zmFold" v-if="msgWrapFold" @click="foldBtn()">
        <p style="margin-top:15px;">
          <img src="@/assets/image/ic_kaoshengxiaoxi.png" alt="">
        </p>
        <p>考生</p>
        <p style="margin-top:5px;">消息</p>
        <ul class="userListSmry">
          <li v-for="stu in msgUserData" :key="stu['UID']" @click="getUserMsg(stu)">
            <img class="img-xxicon" src="@/assets/image/ic_xiaoxi.png" alt="">
            <img class="img-avatar" src="@/assets/image/ic_xuesheng.png"/>
            <span class="usrName">{{stu['姓名']}}</span>
          </li>
        </ul>
        <div class="zoomBtnWrap">
          <span class="zoomBtn">
            <i class="el-icon-arrow-left"></i>
          </span>
        </div>
      </div>
      <div class="zmUnfold" v-else @click="foldBtn()">
        <span class="zoomBtn">
          <i class="el-icon-arrow-right"></i>
        </span>
      </div>
      <!-- 消息容器 -->
      <div class="msgCont" v-show="!msgWrapFold">
        <div class="msgBtns">
          <span class="clDD1C1C">可以通过选择房间与考生文字聊天</span>
        </div>
        <div class="userMgsCont">
          <div class="umcLeft">
            <div class="userSchBox plr15">
              <el-input
                class="schUser"
                placeholder="搜索"
                suffix-icon="el-icon-search"
                v-model="inputUsrVal">
              </el-input>
            </div>
            <div class="roomTitle" v-if="msgSelectRoom">
              <i class="el-icon-s-unfold rtIcon"></i>
              <span class="rtName">{{msgSelectRoom['房间名称']}}</span>
            </div>
            <div class="userCont">
              <ul class="userList">
                <li v-for="stu in msgUserData" :key="stu['UID']" :class="{active: stu['选中']}"
                  @click="getUserMsg(stu)">
                  <!-- <el-avatar :src="stu['头像']"></el-avatar> -->
                  <img class="img-avatar" src="@/assets/image/ic_xuesheng.png"/>
                  <span class="usrName">{{stu['姓名']}}</span>
                  <span class="msgNum" v-if="stu['未读消息数量']">{{stu['未读消息数量']}}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="umcRight">
            <div class="umcrTitle">
              <span class="umcrtName" v-if="msgSltUser">
                {{msgSltUser['姓名']}} - {{msgSltUser['房间名称']}}
              </span>
              <el-button type="text" class="flr mt10 mr15" @click="goToStuRoom()" v-if="msgSltUser">
                切换至该考生房间
              </el-button>
            </div>
            <div class="umcrMsgList">
              <div class="usrMsgBox" v-for="msg in messageData" :key="msg['id']">
                <div class="msgTime">
                  <span>{{msg['发送时间']}}</span>
                </div>
                <!-- 学生消息 -->
                <div class="msgBox" v-if="msg['用户类型']==2">
                  <div class="userAvatar">
                    <img src="@/assets/image/ic_xuesheng.png"/>
                    <!-- <el-avatar :src="msg['用户头像']"></el-avatar> -->
                  </div>
                  <div class="trglGray">
                    <!-- 灰色三角 -->
                  </div>
                  <div class="msgBg" v-html="msg['消息详情']">
                  </div>
                </div>
                <!-- 老师消息 -->
                <div class="msgBox myMsg" v-else>
                  <div class="msgBg" v-html="msg['消息详情']">
                  </div>
                  <div class="trglGray">
                    <!-- 灰色三角 -->
                  </div>
                  <div class="userAvatar">
                    <img src="@/assets/image/ic_laoshi.png"/>
                    <!-- <el-avatar :src="msg['用户头像']"></el-avatar> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="umcrInput">
              <el-input
                class="inputBox"
                type="textarea"
                v-model="txtInputMsg">
              </el-input>
              <div class="sendBox">
                <el-button type="sendbutton" size="medium" class="sendBtn" @click="sendMsgFun('msg')">发送</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 学生照片容器 -->
      <el-dialog class="imageWrap"
        :show-close="false" 
        :close-on-click-modal="false" 
        :close-on-press-escape="false" 
        :visible.sync="showImageBox">
        <div class="dlgHeader">
          <span class="ml30" v-if="sltStu">{{sltStu['姓名']}}</span>
          <span class="ml20" v-if="sltStu">{{sltStu['报名号']}}</span>
          <el-button type="danger" size="mini" class="restoreBtn" @click="seeStuImagePop(sltStu, 'close')">
            关闭
          </el-button>
        </div>
        <div class="dlgBody">
          <div class="imageType">
            <el-button type="grayblue" style="margin-left:20px;" size="small" @click="changeImageState(1)" 
              :class="{grayblueOn:imgSltType===1}">
              考生报名照片
            </el-button>
            <el-button type="grayblue" style="margin-left:15px;" size="small" @click="changeImageState(2)" 
              :class="{grayblueOn:imgSltType===2}">
              AI报警照片
            </el-button>
            <el-button type="grayblue" style="margin-left:15px;" size="small" @click="changeImageState(3)" 
              :class="{grayblueOn:imgSltType===3}">
              定时轮巡照片
            </el-button>
            <el-button type="grayblue" style="margin-left:15px;" size="small" @click="changeImageState(4)" 
              :class="{grayblueOn:imgSltType===4}">
              手动截图照片
            </el-button>
            <!-- <el-button type="grayblue" style="margin-left:15px;" size="small" @click="changeImageState(5)" 
              :class="{grayblueOn:imgSltType===5}">
              手动截图照片二机位，预留
            </el-button> -->
            <el-button type="grayblue" style="margin-left:15px;" size="small" @click="changeImageState(6)" 
              :class="{grayblueOn:imgSltType===6}">
              异常声音留存
            </el-button>
            <el-button type="grayblue" style="margin-left:15px;" size="small" @click="changeImageState(7)" 
              :class="{grayblueOn:imgSltType===7}">
              环境拍摄视频
            </el-button>
          </div>
          <div class="imageCont">
            <div v-if="imgSltType==1">
              <div class="usrImgBox" v-if="imageData && imageData.length > 0">
                <div class="usrBox" v-for="pic in imageData" :key="pic['照片序号']">
                  <img :src="'https://jiankao.zhifz.com/file/show/' + pic['照片']" alt="">
                  <!-- <img :src="'/file/show/' + pic['照片']" alt=""> -->
                </div>
              </div>
              <div class="usrImgBox" v-else>
                <div class="noUsrImg">
                  暂无照片
                </div>
              </div>
              <table class="infoTable">
                <tr>
                  <td class="tdLabel">{{$t('lang.name')}}:</td>
                  <td>{{stuInfo['姓名']}}</td>
                </tr>
                <tr>
                  <td class="tdLabel">{{$t('lang.certNum')}}:</td>
                  <td>{{stuInfo['证件号']}}</td>
                </tr>
                <!-- <tr>
                  <td class="tdLabel">{{$t('lang.phone')}}:</td>
                  <td>{{stuInfo['手机']}}</td>
                </tr>
                <tr>
                  <td class="tdLabel">{{$t('lang.studentID')}}:</td>
                  <td>{{stuInfo['学号']}}</td>
                </tr> -->
                <tr>
                  <td class="tdLabel">{{$t('lang.rgstNumber')}}:</td>
                  <td>{{stuInfo['报名号']}}</td>
                </tr>
                <tr>
                  <td class="tdLabel">{{$t('lang.rgstPwd')}}:</td>
                  <td>{{stuInfo['报名密码']}}</td>
                </tr>
                <!-- <tr>
                  <td class="tdLabel">{{$t('lang.serAddr')}}:</td>
                  <td>{{stuInfo['服务器地址']}}</td>
                </tr>
                <tr>
                  <td class="tdLabel">{{$t('lang.kmName')}}:</td>
                  <td>{{stuInfo['科目名称']}}</td>
                </tr>
                <tr>
                  <td class="tdLabel">{{$t('lang.ksKsSj')}}:</td>
                  <td>{{stuInfo['开始时间']}}</td>
                </tr> -->
              </table>
            </div>
            <div v-if="imgSltType>=2 && imgSltType<=5">
              <div v-if="imageData && imageData.length > 0">
                <div class="imgBox" v-for="pic in imageData" :key="pic['照片序号']">
                  <img :src="'https://jiankao.zhifz.com/file/show/' + pic['照片']" alt="">
                  <!-- <img :src="'/file/show/' + pic['照片']" alt=""> -->
                </div>
              </div>
            </div>
            <div v-if="imgSltType==6">

            </div>
            <div v-if="imgSltType==7">
              <!-- <video width="300" height="480" controls>
                <source src="https://jiankao-m.oss-cn-beijing.aliyuncs.com/environment/5/406.mp4" type="video/mp4">
                <object data="https://jiankao-m.oss-cn-beijing.aliyuncs.com/environment/5/406.mp4" width="300" height="480">
                </object> 
              </video> -->
              <video width="300" height="480" controls v-if="evntVideo">
                <source :src="evntVideo" type="video/mp4">
                <object :data="evntVideo" width="300" height="480">
                </object> 
              </video>
            </div>
          </div>
        </div>
      </el-dialog>
      <!-- 控制页面发送消息弹出 -->
      <el-dialog class="imageWrap" 
        width="760px"
        :show-close="false" 
        :close-on-click-modal="false" 
        :close-on-press-escape="false" 
        :visible.sync="showMsgBox">
        <div class="dlgHeader">
          <span class="ml30" v-if="sltStu=='all'">群发消息</span>
          <span class="ml30" v-else>
            发消息给
            <span v-if="sltStu">{{sltStu['姓名']}}</span>
            <!-- <span class="ml20" v-if="sltStu">{{sltStu['报名号']}}</span> -->
          </span>
          <el-button type="danger" size="mini" class="restoreBtn" @click="sendMsgPop('', 'close')">
            关闭
          </el-button>
        </div>
        <div class="dlgBody">
          <div class="stuMsgList">
            <div class="usrMsgBox" v-for="msg in stuMsgData" :key="msg['id']">
              <div class="msgTime">
                <span>{{msg['发送时间']}}</span>
              </div>
              <!-- 学生消息 -->
              <div class="msgBox" v-if="msg['用户类型']==2">
                <div class="userAvatar">
                  <img src="@/assets/image/ic_xuesheng.png"/>
                  <!-- <el-avatar :src="msg['用户头像']"></el-avatar> -->
                </div>
                <div class="trglGray">
                  <!-- 灰色三角 -->
                </div>
                <div class="msgBg" v-html="msg['消息详情']">
                </div>
              </div>
              <!-- 老师消息 -->
              <div class="msgBox myMsg" v-else>
                <div class="msgBg" v-html="msg['消息详情']">
                </div>
                <div class="trglGray">
                  <!-- 灰色三角 -->
                </div>
                <div class="userAvatar">
                  <img src="@/assets/image/ic_laoshi.png"/>
                  <!-- <el-avatar :src="msg['用户头像']"></el-avatar> -->
                </div>
              </div>
            </div>
          </div>
          <div class="umcrInput">
            <el-input
              class="inputBox"
              type="textarea"
              v-model="msgVal">
            </el-input>
            <div class="sendBox">
              <el-button type="sendbutton" size="medium" class="sendBtn" @click="sendMsgFun('ctrl')">发送</el-button>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { getFun, postFun, deleteFun, putFun } from '@/request/request';
import Lazy from 'lazy.js';
import CONST from '@/utils/const';

let that = null;
let loginUid = null;
let msgTchUid = null; //消息通道的教师UID
let rtcObj = {};
let jgId = null; //登陆用户的机构ID
let MICROPHONE_MUTE = false; // 麦克风静音开关
let REMOTE_AUDIO_MUTE = false; // 远端音频静音开关
let trackObj = {};
let allJkUsrs = {}; //所有用户的obj
let roomStuIds = []; //监控房间里的学生UID，用户判断教师和学生
let allExams = []; //查出的所有考试数据，用于分页
let highRole = null; //最高角色
const batchUrl = 'jiankao/batch'; //批次url
const examUrl = 'jiankao/exam'; //考试url
const roomUrl = 'jiankao/room'; //房间url
const messageDetailUrl = 'jiankao/message_detail'; //消息详情
const messageSummaryUrl = 'jiankao/message_summary'; //消息概要
const liveRecordUrl = 'jiankao/live_record'; //现场记录
const examineeUrl = 'jiankao/examinee'; //查询考生名单
const userUrl = 'jiankao/user'; //查询用户
const touchMessageUrl = 'jiankao/touch_message'; //标记消息为已读
export default {
  name: "InvigilateRoom",
  data() {
    return {
      navTitle: '监控房间/考试中',
      msgWrapFold: true,
      stepInfo: 'kslist',
      piCiData: [], //批次列表
      sltPiCiVal: '', //批次选择
      sltExamState: 2, //选中的考试状态
      searchVal: '', //房间搜索输入的值
      examData: [], //考试房间列表
      page: {
        total: 0,
        size: 10,
        current: 1,
        max: 1
      },
      msgUserData: [],
      messageData: [],
      jumpPage: '',
      inputUsrVal: '', //消息用户列表搜索
      txtInputMsg: '', //给学生发送消息
      roomData: [], //监控房间数据
      msgSelectRoom: null, //消息选中的房间
      selectRoom: null, //视频选中的房间
      onlineInfo: {
        '应到人数': 0,
        '一机位在线人数': 0,
        '二机位在线人数': 0,
        '考试时间': ''
      }, //房间在线统计
      msgSltUser: null, //消息页面，选中用户
      studentData: [], //房间学生列表
      roomId: null, //视频房间ID
      msgRoomId: null, //查看消息的房间ID
      teacherData: [], //监考教师列表
      showMsgBox: false, //学生控制群发消息
      sltStu: '',
      msgVal: '', //控制发送消息的内容
      isTchJoin: false, //教师加入视频通道
      isMsgTchJoin: false, //教师加入消息通道
      onTalkArr: [],
      onHeadsetArr: [],
      jkType: null, //监控方式
      jkRoomIdArr: [], //房间ID
      showImageBox: false, //显示学生照片变量
      imageData: [], //学生照片，包含异常照片
      // talkDev: 1, //通话设备
      renderComponent: true,
      channelName: '', //视频房间的通道
      msgChannelName: null, //msg房间信息
      remoteTeacher: [], //远端教师加入
      tcherMute: false, //教师静音
      tcherHear: false, //教师群体监听
      tcherTalk: false, //教师群体发言
      sideTalkArr: [], //侧机位通话
      imgSltType: 1, //查询学生照片类型
      stuMsgData: [], //学生视频页面的消息
      msgSumryData: [], //消息摘要
      stuInfo: {
        '姓名': '',
        '证件号': '',
        // '手机': '',
        // '国籍': '',
        // '学号': '',
        '报名号': '',
        '报名密码': '',
        // '服务器地址': ''
      },
      evntVideo: null, //现场环境检测视频
      percentage: 10,
      examGroup: [], //房间分组

   }
  },
  computed: {
    ...mapState(['routerName', 'user']),
  },
  methods: {
    ...mapMutations([]),
    // 查询批次
    queryPiCiData() {
      let param = {
        '状态': 1
      };
      getFun(batchUrl, param).then((res) => {
        if (res.result && res.data) {
          that.piCiData = res.data;
          if(that.piCiData && that.piCiData.length == 1){
            that.sltPiCiVal = that.piCiData[0]['批次ID'];
            that.changeExamState(2);
          }
        }
        else{
          that.piCiData = [];
          console('没有批次数据！');
        }
      });
    },
    // 查询房间
    queryExamData(stat) {
      let examIds = null;
      let exams = null;
      return new Promise(function(resolve, reject){
        let param = {
          '机构ID': jgId,
        };
        if(that.sltPiCiVal){
          param['批次ID'] = that.sltPiCiVal;
        }
        else{
          that.$message({
            showClose: true,
            message: '请选择批次！',
            type: 'warning'
          });
          return ;
        }
        if(stat){
          param['状态'] = stat; //暂时注销
        }
        return getFun(examUrl, param).then((res) => {
          if (res.result && res.data) {
            exams = res.data;
            examIds = Lazy(exams).map(function(ks){
              if(ks['考试开始时间'] && ks['考试结束时间']){
                let ksDate = that.$common.ksDate(ks['考试开始时间'], ks['考试结束时间'], +ks['考前准备时间']);
                ks['考前准备时间'] = ksDate['准备时间'];
                ks['正式考试时间'] = ksDate['考试时间'];
              }
              ks['考试设置'] = that.$common.toObj(ks['考试设置']);
              return ks['考试ID'];
            }).toArray();
            resolve();
          }
          else{
            reject('没有考试数据！');
            return ;
          }
        });
      })
      .then(function(){
        return new Promise(function(resolve, reject){
          let param = {
            '机构ID': jgId,
            '考试ID': '',
          };
          if(exams){
            param['考试ID'] = JSON.stringify(examIds);
          }
          else{
            reject('没有考试数据！');
            return ;
          }
          if(highRole >= 3){ 
            //监考老师、巡考老师只查分配给自己的考试
            param['UID'] = loginUid;
          }
          return getFun(roomUrl, param).then((res) => {
            if (res.result && res.data) {
              let newArr = [];
              let rooms = res.data;
              Lazy(rooms).each(function(rm){
                let fndEx = Lazy(exams).find(function(ex){
                  return ex['考试ID'] == rm['考试ID'];
                });
                if(fndEx){
                  let fndExCopy = that.$common.cloneObj(fndEx);
                  let rmTmp = Lazy(fndExCopy).merge(rm).toObject();
                  newArr.push(rmTmp);
                }
              });
              let totalNum = newArr.length;
              that.page.total = totalNum;
              that.page.max = Math.ceil(totalNum/that.page.size);
              that.page.current = 1;
              allExams = that.$common.cloneObj(newArr);
              // that.examData = newArr;
              console.log('allExams:', allExams);
              that.pageTurn(1);
              resolve();
            }
            else{
              reject('没有监考房间数据！');
            }
          });
        });
      })
      // .then(function(){
      //   return new Promise(function(resolve, reject){
      //     let param = {
      //       '考试ID': JSON.stringify(examIds)
      //     };
      //     return that.querMsgSmy(param, resolve);
      //   });
      // })
      .then(function(){
        let emDpTmp = Lazy(allExams).chunk(5).toArray();
        console.log("emDpTmp:", emDpTmp);
      })
      .catch(function(err){  
        that.page.total = 0
        that.page.max = 0
        that.page.current = 0
        allExams = [];
        that.examData = [];
        if(err){
          that.$message({
            showClose: true,
            message: err,
            type: 'error'
          });
        }
      });
    },
    // 消息页面的折叠
    foldBtn() {
      that.msgWrapFold = !that.msgWrapFold;
    },
    // 切换批次
    changePiCi() {
      that.changeExamState(that.sltExamState);
    },
    //切换考试状态查询考试
    changeExamState(stat) {
      switch(stat){
        case 0:
          that.navTitle = '监控房间/全部';
          break;
        case 1:
          that.navTitle = '监控房间/未开始';
          break;
        case 2:
          that.navTitle = '监控房间/考试中';
          break;
        case 3:
          that.navTitle = '监控房间/已结束';
          break;
      }
      that.sltExamState = stat;
      that.queryExamData(stat);
    },
    //查询房间
    searchRoom() {
      if(that.searchVal){
        let newArr = Lazy(allExams).filter(function(em){
          let idxOf = Lazy(em['房间名称']).indexOf(that.searchVal);
          return idxOf > -1;
        }).toArray();
        let totalNum = newArr.length;
        that.page.total = totalNum;
        that.page.max = Math.ceil(totalNum/that.page.size);
        that.page.current = 1;
        allExams = that.$common.cloneObj(newArr);
        that.pageTurn(1);
      }
    },
    //翻页
    pageTurn(pg) {
      // console.log('pg: ', pg);
      // let currentPg = that.page.current;
      let bgIdx = (pg - 1) * that.page.size;
      let edIdx = pg * that.page.size;
      that.examData = Lazy(allExams).slice(bgIdx, edIdx).toArray();
    },
    //跳转页面
    jumpPageFun() {
      let jpPg = +that.jumpPage;
      if(jpPg >= 1 && jpPg <= that.page.max){
        that.pageTurn(jpPg);
        that.page.current = jpPg;
      }
      else{
        that.$message({
          showClose: true,
          message: '输入的页码不正确！',
          type: 'error'
        });
      }
    },
    // 查看消息的函数
    async seeMessage(row) {
      // that.roomId = null;
      that.msgRoomId = null;
      that.msgSelectRoom = null;
      if(row){
        if(row['考试设置']){
          row['考试设置'] = that.$common.toObj(row['考试设置']);
          //此处考试设置里面可能没有这个字段，先默认为1，双机位，需要
          that.jkType = row['考试设置']['监控方式'] ? row['考试设置']['监控方式'] : 1; 
        }
        that.msgRoomId = row['房间ID'];
        that.msgWrapFold = false;
        that.msgSelectRoom = row;
        let param = {
          '房间ID': that.msgRoomId
        };
        let msgChannelTmp = 'room_';
        if(row['考试ID'] && that.msgRoomId){
          msgChannelTmp += row['考试ID'] + '_' + that.msgRoomId;
          that.msgChannelName = msgChannelTmp;
        }
        //查询次房间学生的所有信息， 此处传房间ID
        let paramCopy = that.$common.cloneObj(param);
        let stuMsgArr = await that.queryMsg(paramCopy);
        //查询房间学生
        getFun(examineeUrl, param).then((res) => {
          if(res.result && res.data) {
            let stuArr = res.data;
            Lazy(stuArr).each(function(stu){
              let stuUid = stu['UID'];
              stu['选中'] = false;
              stu['消息数量'] = 0;
              // stu['头像'] = 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png';
              stu['消息数组'] = [];
              stu['未读消息数量'] = 0;
              stu['未读消息'] = [];
              if(stuMsgArr && stuMsgArr.length > 0){
                let stuMsg = Lazy(stuMsgArr).filter(function(msg){
                  let isSuit = msg['接收人UID'] == stuUid || msg['发送人UID'] == stuUid;
                  if(isSuit){
                      if(isSuit && msg['消息状态'] === 0 && msg['发送人UID'] == stuUid && 
                        (msg['接收人UID'] === 0 || msg['接收人UID'] === loginUid)){
                        stu['未读消息'].push(msg);
                        stu['未读消息数量'] ++;
                      }
                    }
                  return isSuit;
                }).toArray();
                if(stuMsg && stuMsg.length > 0){
                  stu['消息数组'] = stuMsg;
                  stu['消息数量'] = stuMsg.length;
                }
              }
            });
            that.msgUserData = stuArr;
            console.log('msgUserData：', stuArr);
            that.joinChannel(that.msgChannelName);//教师加入通道
          }
          else {
            that.msgUserData = [];
          }
        })
        .catch(function(err) {
          console.log('消息学生信息错误:', err);
        });
      }
    },
    //查询消息概要
    querMsgSmy(param, rsl) {
      param = param || {}
      return new Promise(function(resolve){
        getFun(messageSummaryUrl, param).then((res) => {
          if (res.result && res.data) {
            let msgArr = res.data;
            console.log('msgArr:', msgArr);
            that.msgSumryData = msgArr;
            Lazy(allExams).each(function(em){
              let ksId = em['考试ID'];
              let rmId = em['房间ID'];
              let fltArr = [];
              let msgNumTmp = 0;
              Lazy(that.msgSumryData).each(function(msg){
                if(msg['考试ID'] == ksId && msg['房间ID'] == rmId){
                  fltArr.push(msg);
                  msgNumTmp += msg['未读消息数'];
                }
              });
              em['消息概要'] = fltArr;
              em['未读消息人数'] = msgNumTmp;
            });
            resolve(msgArr);
            if(rsl){
              rsl(msgArr);
            }
          }
          else{
            resolve('未查询到消息概要！');
            if(rsl){
              rsl('未查询到消息概要！');
            }
          }
        });
      });
    },
    //查询消息
    queryMsg(param, rsl) {
      param = param || {}
      return new Promise(function(resolve){
        getFun(messageDetailUrl, param).then((res) => {
          if (res.result && res.data) {
            let msgArr = res.data;
            let newMsgArr = [];
            console.log("msgArr000:", msgArr);
            Lazy(msgArr).each(function(msg){
              if(msg['消息内容']){
                msg['消息详情'] = '消息格式不正确！';
                let msgTmp = that.$common.toObj(msg['消息内容']);
                if(msgTmp && msgTmp.Type == 1 && msgTmp.Message){
                  if(msgTmp['Uniqid']){
                    msg['Uniqid'] = msgTmp['Uniqid'];
                  }
                  else{
                    msgTmp['Uniqid'] = msg['消息ID'];
                    msg['Uniqid'] = msg['消息ID'];
                  }
                  msg['消息详情'] = msgTmp.Message;
                  if(msg['更新时间']){
                    msg['发送时间'] = that.$common.dateMake(msg['更新时间']);
                  }
                  msg['用户类型'] = 2; //默认是学生
                  // msg['用户头像'] = 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png';
                  msg['用户头像'] = null;
                  if(msg['发送人UID'] == loginUid){
                    msg['用户类型'] = 1;
                  }
                  newMsgArr.push(msg);
                }
              }
            });
            newMsgArr = Lazy(newMsgArr).uniq('Uniqid').toArray();
            resolve(newMsgArr);
            if(rsl){
              rsl(newMsgArr);
            }
          }
          else{
            resolve(null);
            if(rsl){
              rsl(null);
            }
          }
        });
      });
    },
    //点击学生，查询学生消息
    async getUserMsg(stu) {
      if(stu){
        let param = {
          // '房间ID': that.roomId
          '房间ID': that.msgRoomId
        };
        let stuUid = stu['UID'];
        let stuMsgArr = await that.queryMsg(param);
        stu['选中'] = true;
        that.msgSltUser = stu;
        if(stuMsgArr){
          let stuMsg = Lazy(stuMsgArr).filter(function(msg){
            //接收人UID 为 0 表示通道消息
            return msg['接收人UID'] == stuUid || msg['发送人UID'] == stuUid || 
              (msg['用户类型'] === 1 && msg['接收人UID'] === 0);
          }).toArray();
          let msgCopy = that.$common.cloneObj(stuMsg);
          Lazy(that.msgUserData).each(function(ostu){
            ostu['选中'] = false;
            if(ostu['UID'] == stu['UID']){
              ostu['选中'] = true;
              if(msgCopy && msgCopy.length > 0){
                ostu['消息数组'] = msgCopy;
                that.markMsgHasRead(ostu, 'frmMsg');
              }
            }
          });
          that.messageData = msgCopy;
          setTimeout(function(){
            that.scrollToBottom('.umcrMsgList');
          }, 100);
        }
        else{
          that.messageData = [];
        }
      }
      else{
        that.msgSltUser = null;
        that.$message({
          showClose: true,
          message: '请选择学生！',
          type: 'warning'
        });
      }
    },
    //发送消息，聊天窗口
    sendMsgFun(tp) {
      if(tp && tp == 'ctrl'){
        //学生视频页面发送消息，弹出消息
        let stu = that.sltStu;
        let uid1 = ''; //1机位
        let uid2 = ''; //2机位
        let msgObj = {
          'Type': 1, 
          'Message': that.msgVal,
          'Uniqid': new Date().getTime()
        };
        let chnlStr = that.channelName;
        if(!chnlStr){
          that.$message({
            showClose: true,
            message: '缺少频道信息！',
            type: 'warning'
          });
          return ;
        }
        if(stu){
          if(stu == 'all'){
            that.sendChannelMsg(chnlStr, msgObj);
          }
          else{
            if(stu['UID']){
              // 1 双机位，2 正面，3 侧面 
              switch(that.jkType){
                case 1:
                  uid1 = 'stua_' + stu['UID'];
                  uid2 = 'stub_' + stu['UID'];
                  break;
                case 2:
                  uid1 = 'stua_' + stu['UID'];
                  break;
                case 3:
                  uid2 = 'stub_' + stu['UID'];
                  break;
              }
            }
            else{
              that.$message({
                showClose: true,
                message: '缺少学生UID！',
                type: 'warning'
              });
            }
            if(that.msgVal){
              if(uid1){
                that.sendPotMsg(uid1, chnlStr, msgObj);
              }
              if(uid2){
                that.sendPotMsg(uid2, chnlStr, msgObj);
              }
              that.msgVal = '';
            }
            else{
              that.$message({
                showClose: true,
                message: '请输入消息内容！',
                type: 'warning'
              });
            }
          }
        }
        else{
          that.$message({
            showClose: true,
            message: '缺少学生信息！',
            type: 'warning'
          });
        }
      }
      if(tp && tp == 'msg'){
        let msgObj = {
          'Type': 1, 
          'Message': '',
          'Uniqid': new Date().getTime()
        };
        if(that.txtInputMsg){
          msgObj.Message = that.txtInputMsg;
          let uid1 = ''; //1机位
          let uid2 = ''; //2机位
          let stu = that.msgSltUser;
          let chnlStr = that.msgChannelName;
          if(chnlStr && stu && stu['UID']){
            // 1 双机位，2 正面，3 侧面 
            switch(that.jkType){
              case 1:
                uid1 = 'stua_' + stu['UID'];
                uid2 = 'stub_' + stu['UID'];
                break;
              case 2:
                uid1 = 'stua_' + stu['UID'];
                break;
              case 3:
                uid2 = 'stub_' + stu['UID'];
                break;
            }
            if(uid1){
              that.sendPotMsg(uid1, chnlStr, msgObj);
            }
            if(uid2){
              that.sendPotMsg(uid2, chnlStr, msgObj);
            }
            that.txtInputMsg = '';
          }
          else{
            that.$message({
              showClose: true,
              message: '缺少学生信息！',
              type: 'warning'
            });
          }
        }
        else{
          that.$message({
            showClose: true,
            message: '请输入消息内容！',
            type: 'warning'
          });
        }
      }
    },
    //学生聊天页面进入房间
    goToStuRoom(){
      if(that.msgSelectRoom){
        that.goToRoomPage(that.msgSelectRoom);
      }
      else{
        that.$message({
          showClose: true,
          message: '缺少房间信息！',
          type: 'warning'
        });
      }
    },
    //进入监考房间
    goToRoomPage(row) {
      if(row){
        that.stepInfo = 'stuvdo';
        //考试的房间列表可以从考试列表过滤
        that.roomData = Lazy(that.examData).filter(function(rm){
          return rm['考试ID'] == row['考试ID'];
        }).toArray();
        that.changeRoom(row);
      }
      else{
        that.roomData = [];
        that.$message({
          showClose: true,
          message: '输入的页码不正确！',
          type: 'warning'
        });
      }
    },
    //返回考试列表页
    backToExamList() {
      switch(that.sltExamState){
        case 1:
          that.navTitle = '监控房间/未开始';
          break;
        case 2:
          that.navTitle = '监控房间/考试中';
          break;
        case 3:
          that.navTitle = '监控房间/已结束';
          break;
      }
      that.stepInfo = 'kslist';
      that.leaveRoomFun();
      that.selectRoom = null;
    },
    //视频页切换房间
    async changeRoom(room) {
      that.isMsgTchJoin = false;
      that.channelName = null;
      if(room){
        that.onlineInfo['考试时间'] = room['正式考试时间'];
        that.selectRoom = room;
        that.jkType = ''; //监控方式不存，启动双机位
        that.roomId = room['房间ID'];
        that.navTitle = room['房间名称'];
        if(room['考试设置']){
          room['考试设置'] = that.$common.toObj(room['考试设置']);
          //此处考试设置里面可能没有这个字段，先默认为1，双机位，需要
          that.jkType = room['考试设置']['监控方式'] ? room['考试设置']['监控方式'] : 1;
        }
        //生成消息通道所需要参数
        let msgChannelTmp = 'room_';
        if(room['考试ID'] && that.roomId){
          msgChannelTmp += room['考试ID'] + '_' + that.roomId;
          that.channelName = msgChannelTmp;
          that.joinChannel(msgChannelTmp);
        }
        //生成监考房间ID
        let tmpRoomArr = [];
        let newRoomIdArr = [];
        tmpRoomArr.push(that.roomId);
        Lazy(tmpRoomArr).each(function(rmid){
          let ftRmId = rmid + '_1';
          let bkRmId = rmid + '_2';
          if(that.jkType == 1){ //双面
            newRoomIdArr.push(ftRmId);
            newRoomIdArr.push(bkRmId);
          }
          else if(that.jkType == 2){ //正面
            newRoomIdArr.push(ftRmId);
          }
          else if(that.jkType == 3){ //侧面
            newRoomIdArr.push(bkRmId);
          }
        });
        that.jkRoomIdArr = newRoomIdArr;
        //如果老师加入了，先离开房间
        await new Promise(function(resolve, reject){
          that.leaveRoomFun(resolve, reject);
        });
        let param = {
          '房间ID': that.roomId
        };
        //查询次房间学生的所有信息， 此处传房间ID
        let paramCopy = that.$common.cloneObj(param);
        let stuMsgArr = await that.queryMsg(paramCopy);
        //查询房间学生
        await new Promise(function(resolve) {
          getFun(examineeUrl, param).then((res) => {
            if(res.result && res.data) {
              let stuArr = res.data;
              roomStuIds = Lazy(stuArr).map(function(stu){
                let stuUid = stu['UID'];
                stu['剩余分钟'] = 0;
                stu['异常次数'] = 0;
                stu['侧方位通话中'] = false;
                stu['消息数组'] = [];
                stu['未读消息数量'] = 0;
                stu['未读消息'] = [];
                stu['音量'] = 0;
                if(stuMsgArr && stuMsgArr.length > 0){
                  let stuMsg = Lazy(stuMsgArr).filter(function(msg){
                    let isSuit = msg['接收人UID'] == stuUid || msg['发送人UID'] == stuUid;
                    if(isSuit){
                      if(isSuit && msg['消息状态'] === 0 && msg['发送人UID'] == stuUid && 
                        (msg['接收人UID'] === 0 || msg['接收人UID'] === loginUid)){
                        stu['未读消息'].push(msg);
                        stu['未读消息数量'] ++;
                      }
                    }
                    return isSuit;
                  }).toArray();
                  if(stuMsg && stuMsg.length > 0){
                    stu['消息数组'] = stuMsg;
                  }
                }
                return stuUid;
              }).toArray();
              that.onlineInfo['应到人数'] = stuArr.length;
              that.studentData = stuArr;
              console.log('学生信息：', stuArr);
              resolve();
            }
            else {
              that.studentData = [];
              resolve();
            }
          })
          .catch(function(err) {
            console.log('查询房间学生: ', err);
            resolve();
          });
        });
        that.remoteTeacher=[
          // {
          //   'UID': 1001,
          //   '姓名': '远端一',
          //   '教师静音': false
          // }
        ];
        //加入监考房间
        await that.joinRoomFun(); //暂时注销，测试使用
      }
      else{
        that.selectRoom = null;
        that.$message({
          showClose: true,
          message: '请选择房间！',
          type: 'warning'
        });
      }
    },
    //构造stream ID
    gernerateId(uid, tp, thp) {
      if(tp && tp == 'stu'){
        let rmtVdId = 'remote_video_';
        switch (thp) {
          case 1:
            uid = that.roomId + '_1_' + uid;
            break;
          case 2:
            uid = that.roomId + '_2_' + uid;
            break;
        }
        rmtVdId += uid;
        return rmtVdId;
      }
      if(tp && tp == 'tlkb'){
        let rmtVdId = 'talkBox_';
        switch (thp) {
          case 1:
            uid = that.roomId + '_1_' + uid;
            break;
          case 2:
            uid = that.roomId + '_2_' + uid;
            break;
        }
        rmtVdId += uid;
        return rmtVdId;
      }
      else if(tp && tp == 'tch'){
        let rmtVdId = 'remote_video_';
        rmtVdId += uid;
        return rmtVdId;
      }
      else if(tp && tp == 'student'){
        return 'student_' + uid;
      }
      else if(tp && tp == 'anNum'){
        return 'anNum_' + uid;
      }
      else if(tp && tp == 'anNumJd'){
        return 'anNumJd_' + uid;
      }
      else if(tp && tp == 'anCont'){
        return 'anCont_' + uid;
      }
      else{
        return 'local_stream_' + uid;
      }
    },
    //构造头像ID
    gernerateAvtId(uid, tp) {
      if(tp){
        if(tp == 'inner'){
          return 'avatar_' + uid + '_' + tp;
        }
        else{
          return tp + '_' + uid;
        }
      }
      else{
        return 'avatar_' + uid;
      }
    },
    //addview
    addView: function(id){
      $('#remote_video_' + id).find('.stuOffline').hide();
      $('#talkBox_' + id).show();
      setTimeout(function(){
        that.countOnlineNum();
      }, 1000);
    },
    //删除视频
    removeView: function(id, newId){
      let rmSltDiv = null;
      let playerSlt ="#player_" + id;
      if(newId){
        rmSltDiv = $('#remote_video_' + newId);
      }
      else{
        rmSltDiv = $('#remote_video_' + id);
      }
      rmSltDiv.find('.stuOffline').show();
      $('#talkBox_' + id).hide();
      let agPly = rmSltDiv.find(playerSlt);
      let wjPly = rmSltDiv.find('.meta_video_player');
      let wjPlyPrt = wjPly.parent();
      if(agPly.length){
        agPly.remove();
      }
      if(wjPly && wjPly.length && wjPlyPrt && wjPlyPrt.length){
        wjPlyPrt.remove();
      }
      setTimeout(function(){
        that.countOnlineNum();
      }, 1000);
    },
    //agora初始化
    initAgoraRtc: function(rsl, rjt) {
      if(TatRTC){
        TatRTC.createNew().then((rtcObj) => {
          if(rsl){
            rsl(rtcObj);
          }
        });
      }
      else{
        that.$message({type: 'error', message: that.$t('lang.rtcInit'), duration: 10000, showClose: true});
        if(rjt){
          rjt(that.$t('lang.rtcInit'));
        }
      }
    },
    //加入房间
    joinRoomFun() {
      let lsJoin = function(rmIdArr){
        let rmId = rmIdArr.shift();
        let cnlName = CONST.MSG_ZHIFUZI_JIANKONG + rmId;
        let rtcName = 'rtc_' + rmId;
        let roomSpt = rmId.split('_'); 
        let roomTp = roomSpt[1]; //房间的正面和侧面
        new Promise(function(resolve, reject) {
          return that.initAgoraRtc(resolve, reject);
        })
        .then(function(rtc){
          let vCfg = {
            'cameraId': '',
            'encoderConfig': '480p_1',
          };
          rtcObj[rtcName] = rtc;
          trackObj[rtcName] = {
            videoTrack: null,
            audioTrack: null
          };
          async function handleOneJoin(channel, ljUid, rtc, localTracks) {
            let streamDiv = 'local_stream_' + ljUid;
            // 创建本地视频轨道
            localTracks.videoTrack = await rtc.createVideoTrack(vCfg);
            // 创建本地音频轨道
            if (!MICROPHONE_MUTE) {
              localTracks.audioTrack = await rtc.createAudioTrack();
            }
            // 播放本地视频轨道，增加条件是为了防止点击返回房间过快导致的错误bug
            if(that.stepInfo=='stuvdo'){
              localTracks.videoTrack.play(streamDiv);
            }
            // 远端用户发布音视频轨道的回调
            async function handleRemoteUserPublished(user, mediaType) {
              console.log('远端用户:', user.uid, ', 发布轨道:', mediaType);
              const uid = user.uid;
              let newUid = null;
              let usrLx = null;
              let remoteVideoDiv = 'remote_video_';
              let uidHasIn = Lazy(roomStuIds).contains(uid);
              if(uidHasIn){
                usrLx = roomTp;
                remoteVideoDiv += rmId + '_' + uid;
                newUid = rmId + '_' + uid;
              }
              else{
                usrLx = 'ls';
                remoteVideoDiv += uid;
                newUid = uid;
              }
              let usrTmp = allJkUsrs[newUid];
              if(!usrTmp){
                usrTmp = {};
              }
              usrTmp[mediaType] = user;
              allJkUsrs[newUid] = usrTmp;
              new Promise(function(resolve, reject){
                if(usrLx == 'ls'){
                  let sltDivId = '#' + remoteVideoDiv;
                  let getDivTar = document.querySelector(sltDivId);
                  if(getDivTar) {
                    resolve();
                  }
                  else{
                    let jkTchTmp = {
                      'UID': uid,
                      '姓名': '监考老师',
                      '教师静音': false
                    };
                    that.addJianKao(jkTchTmp, resolve, reject);
                  }
                }
                else{
                  resolve();
                }
              })
              .then(function(){
                that.addView(newUid);
              });
              // 订阅远端的轨道
              if(usrLx == 'ls' || usrLx == 1 || (usrLx == 2 && mediaType == 'video')){ 
                await rtc.client.setRemoteVideoStreamType(uid, 1); //uid必须是个num，之前传了个对象，所以报错
                await rtc.subscribe(user, mediaType);
                if(mediaType === 'video') {
                  user.videoTrack.play(remoteVideoDiv);
                }
                if(mediaType === 'audio') {
                  // 播放音频轨道
                  // 如果不想听到远端声音，就不要播放
                  if(usrLx == 'ls'){
                    if (!REMOTE_AUDIO_MUTE) {
                      user.audioTrack.play();
                    }
                  }
                }
              }
            }
            // 远端用户取消音视频轨道的回调
            // 当远端用户离线也会触发
            async function handleRemoteUserUnpublished(user, mediaType) {
              console.log('用户离线user：', user);
              console.log('用户离线mediaType：', mediaType);
              console.log('远端用户:', user.uid, ',取消轨道:', mediaType);
              if(mediaType == 'video'){
                const uid = user.uid;
                let newUid = null;
                let uidHasIn = Lazy(roomStuIds).contains(uid);
                if(uidHasIn){
                  newUid = rmId + '_' + uid;
                  //二机位离线，通知考生
                  if(rmId){
                    let jiWei = +rmId.slice(-1);
                    if(jiWei == 2){
                    }
                  }
                }
                else{
                  newUid = uid;
                }
                // 删除远端视频的播放容器
                that.removeView(newUid);
              }
            } 
            // 远端用户发布了新的音频轨道或者视频轨道
            rtc.onRemoteUserPublished(handleRemoteUserPublished);
            // 远端用户取消发布了音频或视频轨道
            rtc.onRemoteUserUnpublished(handleRemoteUserUnpublished);
            // 加入频道
            await rtc.join(channel, ljUid).then((result) => {
              console.log('加入频道成功, 返回的UID:', result);
              let lcStreamDiv = 'local_stream_' + result;
              $('#' + lcStreamDiv).find('.stuOffline').hide();
              that.isTchJoin = true;
              if(rmIdArr && rmIdArr.length > 0){
                lsJoin(rmIdArr);
              }
              else{
                // that.sendChannelMsg('报告状态', '');
              }
            });
            if(!MICROPHONE_MUTE) {
              // 发布本地音视频轨道
              await rtc.publish([localTracks.videoTrack, localTracks.audioTrack]);
            } 
            else{
              // 只发布视频轨道
              await rtc.publish([localTracks.videoTrack]);
            }
            rtc.client.enableAudioVolumeIndicator();
            rtc.client.on("volume-indicator", volumes => {
              volumes.forEach((volume, index) => {
                let talkerUid = volume.uid;
                let volumeNum = +volume.level.toFixed(0);
                // if(volume.level){
                //   volumeNum = +volume.level.toFixed(0);
                // }
                Lazy(that.studentData).each(function(stu){
                  if(stu['UID'] == talkerUid){
                    stu['音量'] = volumeNum; 
                  }
                });
                // console.log(`${index} UID ${volume.uid} volume ${JSON.stringify(volume)}`);
              });
            })
            that.muteAndUnmuteLocalAudio('静音');
          };
          handleOneJoin(cnlName, loginUid, rtcObj[rtcName], trackObj[rtcName]);
        })
        .catch(function(err) {
          console.log('RTC：', err);
          if(rmIdArr && rmIdArr.length > 0){
            lsJoin(rmIdArr);
          }
        });
      }
      if(that.jkRoomIdArr && that.jkRoomIdArr.length > 0){
        let jkRoomCopy = that.$common.cloneObj(that.jkRoomIdArr);
        lsJoin(jkRoomCopy);
      }
    },
    //声网4.0，离开房间
    leaveRoomFun(rsl) {
      Lazy(trackObj).each(function(v){
        if(v){
          (function(localTracks){
            // 关闭视频轨道
            if(localTracks.videoTrack) {
              localTracks.videoTrack.stop();
              localTracks.videoTrack.close();
              localTracks.videoTrack = null;
            }
            // 关闭音频轨道
            if(localTracks.audioTrack) {
              localTracks.audioTrack.stop();
              localTracks.audioTrack.close();
              localTracks.audioTrack = null;
            }
          })(v);
        }
      });
      Lazy(rtcObj).each(function(v){
        if(v){
          (function(rtc){
            // 退出频道
            rtc.leave();
          })(v);
        }
      });
      // that.$ws.stopWebSocket(); //停止websocket
      that.isTchJoin = false;
      $('.stuOffline').show();
      if(rsl){
        rsl();
      }
    },
    //监考老师动态加入
    addJianKao: function(js, rsl){
      if(js){
        let fndJsIn = Lazy(that.remoteTeacher).find(function(ojs){
          return ojs['UID'] == js['UID'];
        });
        if(fndJsIn){
          if(rsl){
            rsl();
          }
        }
        else{
          that.remoteTeacher.push(js);
          that.$nextTick(function(){
            if(rsl){
              rsl();
            }
          });
        }
      }
      else{
        if(rsl){
          rsl();
        }
      }
    },
    //切换麦克和扬声器的图标
    switchMicAndHeadsetIcon(tp, bl, pssStu) {
      Lazy(that.studentData).each(function(stu){
        if(pssStu && (stu['UID'] == pssStu['UID'])){
          (function(stuDtl){
            if(tp && tp == 'mic'){
              let talkId = '#talk_' + stuDtl['UID'];
              let endTalkId = '#endTalk_' + stuDtl['UID'];
              let talkTchId = '#talkTch_' + stuDtl['UID'];
              if(bl){
                document.querySelector(talkId).style.display = 'none';
                document.querySelector(endTalkId).style.display = 'inline-block';
                document.querySelector(talkId).classList.remove('vibrate-1');
                document.querySelector(talkTchId).classList.remove('heartbeat');
              }
              else{
                document.querySelector(talkId).style.display = 'inline-block';
                document.querySelector(endTalkId).style.display = 'none';
                document.querySelector(talkId).classList.remove('vibrate-1'); //新增加
              }
            }
            else if(tp && tp == 'headset'){
              let talkId = '#headset_' + stuDtl['UID'];
              let endTalkId = '#endHeadset_' + stuDtl['UID'];
              let talkTchId = '#talkTch_' + stuDtl['UID'];
              if(bl){
                document.querySelector(talkId).style.display = 'none';
                document.querySelector(endTalkId).style.display = 'inline-block';
                document.querySelector(talkId).classList.remove('vibrate-1');
                document.querySelector(talkTchId).classList.remove('heartbeat');
              }
              else{
                document.querySelector(talkId).style.display = 'inline-block';
                document.querySelector(endTalkId).style.display = 'none';
                document.querySelector(talkId).classList.remove('vibrate-1'); //新增加
              }
            }
          })(stu);
        }
      });
    },
    //还原监考视频
    restoreVideo(stu) {
      let stuId = '#student_' + stu['UID'];
      let stuDiv = document.querySelector(stuId);
      stuDiv.classList.remove('zoomWrap');
      //转化为低清视频流
      let uid = +stu['UID'];
      if(!(stu && stu['UID'])){
        return ;
      }
      //一机位视频
      let rtcId = 'rtc_';
      if(stu['房间ID']){
        rtcId += stu['房间ID'] + '_1';
      }
      let stuRtc = rtcObj[rtcId];
      if(stuRtc){
        stuRtc.client.setRemoteVideoStreamType(uid, 1);
      }
      //二机位视频
      let rtcId2 = 'rtc_';
      if(stu['房间ID']){
        rtcId2 += stu['房间ID'] + '_2';
      }
      let stuRtc2 = rtcObj[rtcId2];
      if(stuRtc2){
        stuRtc2.client.setRemoteVideoStreamType(uid, 1);
      }
    },
    //
    //语音通话，打开麦克风
    talkWithStu(stu) {
      if(!(stu && stu['UID'])){
        return ;
      }
      let chnlStr = that.channelName;
      let rtcId = 'rtc_';
      if(stu['房间ID']){
        rtcId += stu['房间ID'] + '_1';
      }
      let stuRtc = rtcObj[rtcId];
      let mediaType = 'audio';
      let sltStuUid = stu['房间ID'] + '_1_' + stu['UID'];
      let sltUsr = allJkUsrs[sltStuUid];
      if(stuRtc){
        if(sltUsr && sltUsr[mediaType]){
          let usrAudio = sltUsr[mediaType];
          usrAudio.audioTrack.play();
          console.log('和学生' + stu['UID'] + '通话开始！');
        }
        let msgObj = {
          'Type': 2, 
          'Message': ''
        };
        let _endNext = function(talkArr){
          if(talkArr && talkArr.length > 0){
            _endTalk(talkArr);
          }
          else{
            that.onTalkArr = [];
            setTimeout(function(){
              msgObj['Message'] = '打开麦克风';
              let uid = 'stua_' + stu['UID'];
              let voiceBarId = '#voiceBar_' + stu['UID'];
              document.querySelector(voiceBarId).style.display = 'inline-block';
              that.onTalkArr.push(stu);
              that.sendPotMsg(uid, chnlStr, msgObj);
            }, 1000);
          }
        };
        let _endTalk = function(talkArr){
          let endStu = talkArr.shift();
          if(endStu && endStu['UID']){
            msgObj['Message'] = '关闭麦克风';
            let edUid = endStu['UID'];
            let uid = 'stua_' + edUid;
            let talkId = '#talk_' + edUid;
            let endTalkId = '#endTalk_' + edUid;
            let voiceBarId = '#voiceBar_' + edUid;
            document.querySelector(talkId).style.display = 'inline-block';
            document.querySelector(endTalkId).style.display = 'none';
            document.querySelector(voiceBarId).style.display = 'none';
            document.querySelector(talkId).classList.remove('vibrate-1');
            that.sendPotMsg(uid, chnlStr, msgObj);
            _endNext(talkArr);
          }
          else{
            _endNext(talkArr);
          }
        };
        let onTalkArrCopy = [];
        _endTalk(onTalkArrCopy);
      }
      else{
        that.$message({type: 'error', message: that.$t('lang.noStuInRoom'), duration: 10000, showClose: true});
      }
    },
    //结束语音通话，关闭麦克风
    endTalkWithStu(stu) {
      if(!(stu && stu['UID'])){
        return ;
      }
      let chnlStr = that.channelName;
      let rtcId = 'rtc_';
      if(stu['房间ID']){
        rtcId += stu['房间ID'] + '_1';
      }
      let stuRtc = rtcObj[rtcId];
      let mediaType = 'audio';
      let sltStuUid = stu['房间ID'] + '_1_' + stu['UID'];
      let sltUsr = allJkUsrs[sltStuUid];
      if(stuRtc){
        if(sltUsr && sltUsr[mediaType]){
          let usrAudio = sltUsr[mediaType];
          usrAudio.audioTrack.stop();
          console.log('和学生' + stu['UID'] + '通话结束！');
        }
        let msgObj = {
          'Type': 2, 
          'Message': '关闭麦克风'
        };
        let uid = 'stua_' + stu['UID'];
        that.sendPotMsg(uid, chnlStr, msgObj);
        that.onTalkArr = Lazy(that.onTalkArr).reject(function(tstu){
          return tstu['UID'] == stu['UID'];
        }).toArray();
        let voiceBarId = '#voiceBar_' + stu['UID'];
        document.querySelector(voiceBarId).style.display = 'none';
      }
      else{
        that.$message({type: 'error', message: that.$t('lang.noStuInRoom'), duration: 10000, showClose: true});
      }
    },
    // 打开扬声器
    openStuHeadset(stu) {
      if(!(stu && stu['UID'])){
        return ;
      }
      let chnlStr = that.channelName;
      let rtcId = 'rtc_';
      if(stu['房间ID']){
        rtcId += stu['房间ID'] + '_1';
      }
      let stuRtc = rtcObj[rtcId];
      if(stuRtc){
        let msgObj = {
          'Type': 2, 
          'Message': ''
        };
        let _endNext = function(talkArr){
          if(talkArr && talkArr.length > 0){
            _endTalk(talkArr);
          }
          else{
            that.onHeadsetArr = [];
            setTimeout(function(){
              msgObj['Message'] = '打开扬声器';
              let uid = 'stua_' + stu['UID'];
              that.sendPotMsg(uid, chnlStr, msgObj);
              that.onHeadsetArr.push(stu);
            }, 1000);
          }
        };
        let _endTalk = function(talkArr){
          let endStu = talkArr.shift();
          if(endStu && endStu['UID']){
            msgObj['Message'] = '关闭扬声器';
            let edUid = endStu['UID'];
            let uid = 'stua_' + edUid;
            let talkId = '#headset_' + edUid;
            let endTalkId = '#endHeadset_' + edUid;
            document.querySelector(talkId).style.display = 'inline-block';
            document.querySelector(endTalkId).style.display = 'none';
            document.querySelector(talkId).classList.remove('vibrate-1');
            that.sendPotMsg(uid, chnlStr, msgObj);
            _endNext(talkArr);
          }
          else{
            _endNext(talkArr);
          }
        };
        let onTalkArrCopy = [];
        _endTalk(onTalkArrCopy);
      }
      else{
        that.$message({type: 'error', message: that.$t('lang.noStuInRoom'), duration: 10000, showClose: true});
      }
    },
    // 关闭扬声器
    closetuHeadset(stu) {
      if(!(stu && stu['UID'])){
        return ;
      }
      let chnlStr = that.channelName;
      let rtcId = 'rtc_';
      if(stu['房间ID']){
        rtcId += stu['房间ID'] + '_1';
      }
      let stuRtc = rtcObj[rtcId];
      if(stuRtc){
        let msgObj = {
          'Type': 2, 
          'Message': '关闭扬声器'
        };
        let uid = 'stua_' + stu['UID'];
        that.sendPotMsg(uid, chnlStr, msgObj);
        that.onHeadsetArr = Lazy(that.onHeadsetArr).reject(function(tstu){
          return tstu['UID'] == stu['UID'];
        }).toArray();
      }
      else{
        that.$message({type: 'error', message: that.$t('lang.noStuInRoom'), duration: 10000, showClose: true});
      }
    },
    //侧机位通话
    backCameraTalk(stu) {
      let rtcId = 'rtc_';
      if(stu['房间ID']){
        rtcId += stu['房间ID'] + '_2'; //侧方位通话
      }
      let chnlStr = that.channelName;
      let stuRtc = rtcObj[rtcId];
      let mediaType = 'audio';
      let sltStuUid = stu['房间ID'] + '_2_' + stu['UID'];
      let sltUsr = allJkUsrs[sltStuUid];
      if(stuRtc && sltUsr && sltUsr[mediaType]){
        let usrAudio = sltUsr[mediaType];
        that.dealHjStyle(stu, 'remove');
        stuRtc.subscribe(usrAudio, mediaType).then(function(){
          usrAudio.audioTrack.play();
          console.log('和学生' + stu['UID'] + '通话开始！');
        });
      }
      let _endNext = function(talkArr){
        if(talkArr && talkArr.length > 0){
          _endTalk(talkArr);
        }
        else{
          Lazy(that.studentData).each(function(stu){
            stu['侧方位通话中'] = false;
          });
          that.sideTalkArr = [];
          setTimeout(function(){
            let msgObj = {
              'Type': 2, 
              'Message': '侧机位通话'
            };
            let uid = 'stub_' + stu['UID'];
            that.sendPotMsg(uid, chnlStr, msgObj);
            stu['侧方位通话中'] = true;
            that.sideTalkArr.push(stu);
            that.forceRerender();
          }, 1000);
        }
      };
      let _endTalk = function(talkArr){
        let endStu = talkArr.shift();
        if(endStu && endStu['UID']){
          let msgObj = {
            'Type': 2, 
            'Message': '关闭侧机位通话'
          };
          let uid = 'stub_' + endStu['UID'];
          that.sendPotMsg(uid, chnlStr, msgObj);
          _endNext(talkArr);
        }
        else{
          _endNext(talkArr);
        }
      };
      let onSideTalkCopy = that.$common.cloneObj(that.sideTalkArr);
      _endTalk(onSideTalkCopy);
    },
    //关闭侧机位通话
    endBackCameraTalk(stu) {
      let rtcId = 'rtc_';
      if(stu['房间ID']){
        rtcId += stu['房间ID'] + '_2'; //侧方位通话
      }
      let chnlStr = that.channelName;
      let stuRtc = rtcObj[rtcId];
      let mediaType = 'audio';
      let sltStuUid = stu['房间ID'] + '_2_' + stu['UID'];
      let sltUsr = allJkUsrs[sltStuUid];
      if(stuRtc && sltUsr && sltUsr[mediaType]){
        let usrAudio = sltUsr[mediaType];
        stuRtc.unsubscribe(usrAudio, mediaType).then(function(){
          console.log('和学生' + stu['UID'] + '通话结束！');
          that.sideTalkArr = Lazy(that.sideTalkArr).reject(function(tstu){
            return tstu['UID'] == stu['UID'];
          }).toArray();
          stu['侧方位通话中'] = false;
          that.forceRerender();
        });
      }
      let msgObj = {
        'Type': 2, 
        'Message': '关闭侧机位通话'
      };
      let uid = 'stub_' + stu['UID'];
      that.sendPotMsg(uid, chnlStr, msgObj);
    }, 
    //接通呼叫
    conncetCall(stu, tp) {
      let jtId = null;
      if(tp && tp == '正面'){
        jtId = '#onejt_' + stu['UID'];
        that.talkWithStu(stu);
        that.openStuHeadset(stu);
      }
      if(tp && tp == '侧面'){
        jtId = '#twojt_' + stu['UID'];
        that.backCameraTalk(stu);
      }
      if(jtId){
        // document.querySelector(jtId).style.display = 'none';
        document.querySelector(jtId).classList.remove('vibrate-1');
      }
    },
    //静音和打开静音
    muteAndUnmuteLocalAudio(tp) {
      if(trackObj){
        Lazy(trackObj).each(function(localTracks){
          if(localTracks && localTracks.audioTrack){
            if(tp && tp == '静音'){
              that.tcherMute = true;
              localTracks.audioTrack.setVolume(0);
              Lazy(that.teacherData).each(function(tch){
                tch['教师静音'] = true;
              });
            }
            else if(tp && tp == '解除静音'){
              that.tcherMute = false;
              localTracks.audioTrack.setVolume(100);
              Lazy(that.teacherData).each(function(tch){
                tch['教师静音'] = false;
              });
            }
          }
        });
      }
    },
    //打开所有麦克风
    openAllMic: function(){
      let msgObj = {
        'Type': 2, 
        'Message': ''
      };
      let chnlStr = that.channelName;
      that.tcherHear = !that.tcherHear;
      if(that.tcherHear){
        //打开频道内所有学生的麦克风，监考听学生说话
        msgObj.Message = '打开麦克风';
        that.onTalkArr = [];
      }
      else{
        //关闭频道内所有学生的麦克风，监考听不到学生说话
        msgObj.Message = '关闭麦克风';
      }
      if(that.studentData && that.studentData.length > 0){
        let _doFun = function(stuArr){
          let stu = stuArr.shift();
          if(stu && stu['UID']){
            if(that.tcherHear){
              that.openOneStuMic(stu);
            }
            else{
              that.closeOneStuMic(stu);
            }
          }
          else{
            if(stuArr && stuArr.length > 0){
              _doFun(stuArr);
            }
            else{
              // that.$message({
              //   showClose: true,
              //   message: '群体监听消息发送成功！',
              //   type: 'success'
              // });
              console.log('群体监听消息发送成功！');
            }
          }
        };
        let studentDataCopy = that.$common.cloneObj(that.studentData);
        _doFun(studentDataCopy);
      }
      that.sendChannelMsg(chnlStr, msgObj);
    },
    //群发消息打开单个学生的mic
    openOneStuMic(sltStu) {
      if(!(sltStu && sltStu['UID'])){
        return ;
      }
      let hearDev = that.jkType == 3 ? 2 : 1;
      (function(stu){
        let rtcId = 'rtc_';
        if(stu['房间ID']){
          rtcId += stu['房间ID'] + '_' + hearDev;
        }
        let stuRtc = rtcObj[rtcId];
        let mediaType = 'audio';
        let sltStuUid = stu['房间ID'] + '_' + hearDev + '_' + stu['UID'];
        let sltUsr = allJkUsrs[sltStuUid];
        if(stuRtc){
          if(sltUsr && sltUsr[mediaType]){
            let usrAudio = sltUsr[mediaType];
            usrAudio.audioTrack.play();
            console.log('和学生' + stu['UID'] + '通话开始！');
          }
          setTimeout(function(){
            that.onTalkArr.push(stu);
          }, 1000);
        }
        else{
          console.log(that.$t('lang.noStuInRoom'));
        }
      })(sltStu);
    },
    //群发消息关闭单个学生的mic
    closeOneStuMic(sltStu) {
      if(!(sltStu && sltStu['UID'])){
        return ;
      }
      let hearDev = that.jkType == 3 ? 2 : 1;
      (function(stu){
        let rtcId = 'rtc_';
        if(stu['房间ID']){
          rtcId += stu['房间ID'] + '_' + hearDev;
        }
        let stuRtc = rtcObj[rtcId];
        let mediaType = 'audio';
        let sltStuUid = stu['房间ID'] + '_' + hearDev + '_' + stu['UID'];
        let sltUsr = allJkUsrs[sltStuUid];
        if(stuRtc){
          if(sltUsr && sltUsr[mediaType]){
            let usrAudio = sltUsr[mediaType];
            usrAudio.audioTrack.stop();
            console.log('和学生' + stu['UID'] + '通话结束！');
          }
          setTimeout(function(){
            that.onTalkArr.push(stu);
            that.onTalkArr = Lazy(that.onTalkArr).reject(function(tstu){
              return tstu['UID'] == stu['UID'];
            }).toArray();
          }, 1000);
        }
        else{
          console.log(that.$t('lang.noStuInRoom'));
        }
      })(sltStu);
    },
    //打开所有扬声器
    openAllHeadset: function(){
      let msgObj = {
        'Type': 2, 
        'Message': ''
      };
      let chnlStr = that.channelName;
      that.tcherTalk = !that.tcherTalk;
      if(that.tcherTalk){
        //打开频道内所有学生的扬声器，学生听到监考说话
        msgObj.Message = '打开扬声器';
      }
      else{
        //关闭频道内所有学生的扬声器，学生听不到到监考说话
        msgObj.Message = '关闭扬声器';
      }
      that.sendChannelMsg(chnlStr, msgObj);
    },
    //发送消息弹出
    sendMsgPop: function(stu, cls){
      that.stuMsgData = [];
      if(cls && cls == 'close'){
        that.msgVal = '';
        that.sltStu = '';
        that.showMsgBox = false;
      }
      else{
        that.msgVal = '';
        that.sltStu = stu;
        that.showMsgBox = true;
        if(stu['消息数组']){
          that.stuMsgData = stu['消息数组'];
          that.markMsgHasRead(stu, 'frmVdo');
          setTimeout(function(){
            that.scrollToBottom('.stuMsgList');
          }, 100);
        }
      }
    },
    //加入频道
    joinChannel(channel) {
      let chnlUsr = that.$ws.getChnlUsr();
      let selfChnlObj = chnlUsr['加入的频道'] || {};
      let selfJoinChnl = selfChnlObj[channel];
      if(!selfJoinChnl){
        that.$ws.join(channel);
      }
    },
    //点对点发送消息, uid 学生UID，msg 消息内容
    sendPotMsg(uid, chnl, msg) {
      if(!uid){
        that.$message({
          showClose: true,
          message: '缺少UID！',
          type: 'warning'
        });
        return ;
      }
      if(chnl && msg){
        let chnlUsr = that.$ws.getChnlUsr();
        if(!chnlUsr){
          that.$message({
            showClose: true,
            message: '缺少加入通道的用户信息！',
            type: 'warning'
          });
          return ;
        }
        let selfChnlObj = chnlUsr['加入的频道'] || {};
        let chnlUsrObj = chnlUsr['频道用户'] || {};
        let selfJoinChnl = selfChnlObj[chnl];
        // if(selfJoinChnl && stuHasInChnl){
        if(selfJoinChnl){
          that.$ws.sendMessageToPeer(uid, chnl, msg);
        }
        else{
          that.$message({
            showClose: true,
            message: '你还未加入频道！',
            type: 'warning'
          });
        }
      }
      else{
        that.$message({
          showClose: true,
          message: '缺少频道或者消息内容！',
          type: 'warning'
        });
      }
    },
    //给所有房间内考生发消息
    sendChannelMsg(chnl, msg) {
      if(chnl && msg){
        let chnlUsr = that.$ws.getChnlUsr();
        if(!chnlUsr){
          that.$message({
            showClose: true,
            message: '缺少加入通道的用户信息！',
            type: 'warning'
          });
          return ;
        }
        let selfChnlObj = chnlUsr['加入的频道'] || {};
        let selfJoinChnl = selfChnlObj[chnl];
        if(selfJoinChnl){
          that.$ws.sendMessageToChannel(chnl, msg);
        }
      }
      else{
        that.$message({
          showClose: true,
          message: '缺少频道或者消息内容！',
          type: 'warning'
        });
      }
    },
    //放大视频
    zoomVideo: function(stu){
      let stuId = '#student_' + stu['UID'];
      let stuDiv = document.querySelector(stuId);
      stuDiv.classList.add('zoomWrap');
      //转化为高清视频流
      let uid = +stu['UID'];
      if(!(stu && stu['UID'])){
        return ;
      }
      //一机位视频
      let rtcId = 'rtc_';
      if(stu['房间ID']){
        rtcId += stu['房间ID'] + '_1';
      }
      let stuRtc = rtcObj[rtcId];
      if(stuRtc){
        stuRtc.client.setRemoteVideoStreamType(uid, 0);
      }
      //二机位视频
      let rtcId2 = 'rtc_';
      if(stu['房间ID']){
        rtcId2 += stu['房间ID'] + '_2';
      }
      let stuRtc2 = rtcObj[rtcId2];
      if(stuRtc2){
        stuRtc2.client.setRemoteVideoStreamType(uid, 0);
      }
    },
    //还原监考视频
    restoreVideo: function(stu){
      let stuId = '#student_' + stu['UID'];
      let stuDiv = document.querySelector(stuId);
      stuDiv.classList.remove('zoomWrap');
      //转化为低清视频流
      let uid = +stu['UID'];
      if(!(stu && stu['UID'])){
        return ;
      }
      //一机位视频
      let rtcId = 'rtc_';
      if(stu['房间ID']){
        rtcId += stu['房间ID'] + '_1';
      }
      let stuRtc = rtcObj[rtcId];
      if(stuRtc){
        stuRtc.client.setRemoteVideoStreamType(uid, 1);
      }
      //二机位视频
      let rtcId2 = 'rtc_';
      if(stu['房间ID']){
        rtcId2 += stu['房间ID'] + '_2';
      }
      let stuRtc2 = rtcObj[rtcId2];
      if(stuRtc2){
        stuRtc2.client.setRemoteVideoStreamType(uid, 1);
      }
    },
    //手动截图
    handShotImg(tp, stu) {
      let msgObj = {
        'Type': 2, 
        'Message': '手动截图'
      };
      let chnlStr = that.channelName;
      if(tp && stu['UID']){
        let uid = tp == '正面' ? 'stua_' + stu['UID'] : 'stub_' + stu['UID'];
        that.sendPotMsg(uid, chnlStr, msgObj);
      }
      else{
        that.$message({
          showClose: true,
          message: '缺少截图参数！',
          type: 'warning'
        });
      }
    },
    //考试情况记录
    kaoShiRecord() {
    },
    //查看学生照片打开和关闭
    seeStuImagePop(stu, tp) {
      if(stu){
        if(tp && tp == 'close'){
          that.showImageBox = false;
          that.sltStu = null;
        }
        else if(tp && tp == 'vdo'){
          that.sltStu = stu;
          that.showImageBox = !that.showImageBox; 
          that.changeImageState(5);
        }
        else{
          that.sltStu = stu;
          that.showImageBox = !that.showImageBox; 
          that.changeImageState(1);
        }
      }
      else{
        that.sltStu = null;
        that.$message({
          showClose: true,
          message: '没有学生信息！',
          type: 'warning'
        });
      }
    },
    //查看学生照片功能
    seeStuImage(lx, stu) {
      stu = stu || that.sltStu;
      if(stu){
        let param = {
          'UID': stu['UID'],
          // '考试ID': '',
          // '记录ID': '',
          '记录类型': '', //1-现场照片 2-现场视频 3-现场录音 4-文字内容
          // '记录状态': '' //待定
        };
        switch(lx){
          case 1:
            param['记录类型'] = 1;
            break;
          case 2:
            param['记录类型'] = 1;
            break;
          case 3:
            param['记录类型'] = 1;
            break;
          case 4:
            param['记录类型'] = 3;
            break;
          case 5:
            param['记录类型'] = 2;
            break;
        }
        if(that.selectRoom){
          // param['考试ID'] = that.selectRoom['考试ID'];
        }
        if(that.imgSltType == 1){
          that.queryUser();
        }
        else{
          getFun(liveRecordUrl, param).then((res) => {
            if (res.result && res.data) {
              let jlData = res.data;
              if(lx == 7){
                that.imageData = [];
                jlData = Lazy(jlData).sortBy('记录ID').reverse().toArray();
                let vdoOne = jlData[0];
                that.evntVideo = vdoOne['记录内容'];
              }
              else{
                that.imageData = jlData;
              }
            }
            else{
              that.imageData = [];
              if(lx == 7){
                that.evntVideo = 'https://jiankao-m.oss-cn-beijing.aliyuncs.com/environment/5/406.mp4';
              }
            }
          });
        }
      }
      else{
        that.$message({
          showClose: true,
          message: '没有学生信息！',
          type: 'warning'
        });
      }
    },
    //切换学生照片内容
    changeImageState(tp) {
      if(tp){
        that.imgSltType = tp;
        that.seeStuImage(tp);
      }
      else{
        that.$message({
          type: 'warning',
          message: '缺少考生照片内容！',
          showClose: true
        });
      }
    },
    //查询用户信息
    queryUser() {
      if(that.sltStu){
        let ssUid = that.sltStu['UID'];
        let param = {
          'UID': ssUid,
          '返回照片': true
        };
        getFun(userUrl, param).then((res) => {
          if (res.result && res.data) {
            let usrZero = res.data[0];
            that.stuInfo['姓名'] = usrZero['姓名'];
            that.stuInfo['证件号'] = usrZero['证件号'];
            that.stuInfo['报名号'] = usrZero['报名号'];
            that.stuInfo['报名密码'] = usrZero['密码'];
            if(usrZero['照片']){
              let newImgArr = [];
              //暂时不清楚照片是数组还是对象，先按照数组处理
              let imgTmp = {
                '照片序号': 1,
                '照片': usrZero['照片']
              };
              newImgArr.push(imgTmp);
              that.imageData = newImgArr;
            }
          }
          else{
            that.imageData =[];
          }
        });
      }
      else{
        that.$message({
          showClose: true,
          message: '没有学生信息！',
          type: 'warning'
        });
      }
    },
    //收到消息后，写入对应的数组
    pushMsgToArr(msg, tp) {
      if(msg){
        let msgChnl = msg.Channel;
        let msgFmUId = '';
        let msgRoomId = that.$common.getId(msgChnl, 'roomid');
        let msgKsId = that.$common.getId(msgChnl, 'ksid');
        let usrType = msg['用户类型'];
        let msgCont = msg.Message;
        if(msg.FromUID){
          msgFmUId = that.$common.getId(msg.FromUID, 'stuid');
        }
        if(msg.MessageID && msgCont){
          let newMsgTemp = {
            '消息ID': msg.MessageID,
            '房间ID': msgRoomId,
            '发送人UID': msgFmUId,
            '接收人UID': null,
            '消息内容': msgCont,
            '消息状态': 0,
            '消息类型': msgCont.Type,
            '消息详情': msgCont.Message,
            '消息频道': msgChnl,
            '用户头像': null,
            // '用户头像': 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
            '用户类型': usrType,
            '考试ID': msgKsId,
            '更新时间': new Date(),
            '发送时间': '',
            'Uniqid': msg.MessageID
          };
          if(msgCont['Uniqid']){
            newMsgTemp['Uniqid'] = msgCont['Uniqid'];
          }
          newMsgTemp['发送时间'] = that.$common.dateMake(newMsgTemp['更新时间']);
          if(tp && tp == 'peer'){
            newMsgTemp['接收人UID'] = loginUid;
          }
          else if(tp && tp == 'channel'){
            newMsgTemp['接收人UID'] = 0;
          }
          //右边消息聊天框消息处理
          if(that.msgUserData && that.msgUserData.length > 0){
            Lazy(that.msgUserData).each(function(stu){
              let stuUid = stu['UID'];
              if(stuUid == msgFmUId){
                stu['未读消息数量'] = 0;
                stu['未读消息'] = [];
                if(stu['消息数组']){
                  stu['消息数组'].push(newMsgTemp);
                  stu['消息数组'] = Lazy(stu['消息数组']).uniq('Uniqid').toArray();
                  stu['消息数量'] = stu['消息数组'].length;
                }
                Lazy(stu['消息数组']).each(function(msg){
                  if(msg['消息状态'] === 0 && msg['发送人UID'] == stuUid && 
                    (msg['接收人UID'] === 0 || msg['接收人UID'] === loginUid)){
                    stu['未读消息'].push(msg);
                    stu['未读消息数量'] ++;
                  }
                });
                if(that.msgSltUser && that.msgSltUser['UID'] == msgFmUId){
                  that.messageData = that.$common.cloneObj(stu['消息数组']);
                }
              }
            });
          }
          //视频页面消息处理
          if(that.selectRoom && that.studentData && that.studentData.length > 0){
            Lazy(that.studentData).each(function(stu){
              let stuUid = stu['UID'];
              if(stuUid == msgFmUId){
                stu['未读消息数量'] = 0;
                stu['未读消息'] = [];
                if(stu['消息数组']){
                  stu['消息数组'].push(newMsgTemp);
                  stu['消息数组'] = Lazy(stu['消息数组']).uniq('Uniqid').toArray();
                  stu['消息数量'] = stu['消息数组'].length;
                }
                Lazy(stu['消息数组']).each(function(msg){
                  if(msg['消息状态'] === 0 && msg['发送人UID'] == stuUid && 
                    (msg['接收人UID'] === 0 || msg['接收人UID'] === loginUid)){
                    stu['未读消息'].push(msg);
                    stu['未读消息数量'] ++;
                  }
                });
                if(that.sltStu && that.sltStu['UID'] == msgFmUId){
                  that.stuMsgData = that.$common.cloneObj(stu['消息数组']);
                }
              }
            });
          }
        }
        setTimeout(function(){
          that.scrollToBottom('.umcrMsgList');
          that.scrollToBottom('.stuMsgList');
        }, 100);
      }
    },
    //发送消息后，写入对应的数组
    pushSndMsgToArr(msg, tp) {
      if(!tp){
        that.$message({
          showClose: true,
          message: '缺少发送消息类型！',
          type: 'error'
        });
        return ;
      }
      if(msg){
        let msgChnl = msg.Channel;
        let msgToUid = '';
        let msgRoomId = that.$common.getId(msgChnl, 'roomid');
        let msgKsId = that.$common.getId(msgChnl, 'ksid');
        let newDate = new Date();
        let msgCont = msg.Message;
        let msgType = null;
        if(msg.ToUID){
          msgToUid = that.$common.getId(msg.ToUID, 'stuid');
        }
        if(msgCont){
          msgType = msgCont.Type;
          if(msgType == 2){
            // 控制消息不写入
            return ;
          }
          let nwDtMil = '' + newDate.getTime();
          let newMsgTemp = {
            '消息ID': nwDtMil,
            '房间ID': msgRoomId,
            '发送人UID': loginUid,
            '消息内容': msgCont,
            '消息状态': 0,
            '消息类型': msgType,
            '消息详情': msgCont.Message,
            '消息频道': msgChnl,
            '用户头像': null,
            // '用户头像': 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
            '用户类型': 1,
            '考试ID': msgKsId,
            '更新时间': newDate,
            '发送时间': '',
            'Uniqid': msgCont['Uniqid'] || nwDtMil
          };
          newMsgTemp['发送时间'] = that.$common.dateMake(newMsgTemp['更新时间']);
          // if(msgType == 1){
          // }
          //右边消息聊天框消息处理
          if(that.msgUserData && that.msgUserData.length > 0){
            Lazy(that.msgUserData).each(function(stu){
              if(tp == 'send_peer'){
                if(stu['UID'] == msgToUid){
                  if(stu['消息数组']){
                    stu['消息数组'].push(newMsgTemp);
                    stu['消息数组'] = Lazy(stu['消息数组']).uniq('Uniqid').toArray();
                    stu['消息数量'] = stu['消息数组'].length;
                  }
                  if(that.msgSltUser && that.msgSltUser['UID'] == msgToUid){
                    that.messageData = that.$common.cloneObj(stu['消息数组']);
                  }
                }
              }
              if(tp == 'send_channel'){
                if(stu['房间ID'] == msgRoomId){
                  if(stu['消息数组']){
                    stu['消息数组'].push(newMsgTemp);
                    stu['消息数组'] = Lazy(stu['消息数组']).uniq('Uniqid').toArray();
                    stu['消息数量'] = stu['消息数组'].length;
                  }
                  if(that.msgSltUser && that.msgSltUser['UID'] == stu['UID']){
                    that.messageData = that.$common.cloneObj(stu['消息数组']);
                  }
                }
              }
            });
          }
          //视频页面消息处理
          if(that.selectRoom && that.studentData && that.studentData.length > 0){
            Lazy(that.studentData).each(function(stu){
              if(tp == 'send_peer'){
                if(stu['UID'] == msgToUid){
                  if(stu['消息数组']){
                    stu['消息数组'].push(newMsgTemp);
                    stu['消息数组'] = Lazy(stu['消息数组']).uniq('Uniqid').toArray();
                    stu['消息数量'] = stu['消息数组'].length;
                  }
                  if(that.sltStu && that.sltStu['UID'] == msgToUid){
                    that.stuMsgData = that.$common.cloneObj(stu['消息数组']);
                  }
                }
              }
              if(tp == 'send_channel'){
                if(stu['房间ID'] == msgRoomId){
                  if(stu['消息数组']){
                    stu['消息数组'].push(newMsgTemp);
                    stu['消息数组'] = Lazy(stu['消息数组']).uniq('Uniqid').toArray();
                    stu['消息数量'] = stu['消息数组'].length;
                  }
                  if(that.sltStu && that.sltStu['UID'] == stu['UID']){
                    that.stuMsgData = that.$common.cloneObj(stu['消息数组']);
                  }
                }
              }
            });
          }
          setTimeout(function(){
            that.scrollToBottom('.umcrMsgList');
            that.scrollToBottom('.stuMsgList');
          }, 100);
        }
      }
    },
    //消息回调函数
    backInfoCb(cmdMsg, parMsg) {
      (function(cmd, par){
        console.log('000cmd', cmd);
        console.log('000par', par);
        let comeUid = null; //谁发送的信息
        let usrType = 2; //默认为学生2, 1教师
        let fndStu = null;
        let msgCont = null;
        let msgType = null;
        let cnlStr = null;
        let sndMsgSuc = null;
        let sndMsgData = null;
        if(par && par.hasOwnProperty('result') && par.data){
          sndMsgSuc = par.result;
          //发送信息
          sndMsgData = par.data;
          if(sndMsgData.Message){
            msgCont = that.$common.toObj(sndMsgData.Message);
          }
          if(sndMsgData.Channel){
            cnlStr = sndMsgData.Channel;
          }
        }
        else{
          //接收的消息
          if(par && par.FromUID){
            usrType = that.$common.getId(par.FromUID, 'usrtp');
            comeUid = that.$common.getId(par.FromUID, 'stuid');
          }
          if(par && par.Message){
            msgCont = that.$common.toObj(par.Message);
            msgType = msgCont.Type;
            if(msgType == 2){
              fndStu = Lazy(that.studentData).find(function(stu){
                return stu['UID'] == comeUid;
              }); 
            }
          }
        }
        //接收消息
        let receiveMsg = function(tp){
          if (!msgCont) return;
          //控制消息
          if(msgType == 2){
            if (!fndStu) return;
            switch(msgCont.Message){
              case '呼叫老师':
                fndStu['呼叫机位'] = 1;
                that.dealHjStyle(fndStu, 'add');
                break;
              case '取消呼叫':
                fndStu['呼叫机位'] = 1;
                that.dealHjStyle(fndStu, 'remove');
                break;
              case '侧机位呼叫老师':
                fndStu['呼叫机位'] = 2;
                that.dealHjStyle(fndStu, 'add');
                break;
              case '侧机位取消呼叫':
                fndStu['呼叫机位'] = 2;
                that.dealHjStyle(fndStu, 'remove');
                break;
              case '打开麦克风成功':
                that.switchMicAndHeadsetIcon('mic', true, fndStu);
                // that.dealHjStyle(fndStu, 'remove'); //新增加
                break;
              case '关闭麦克风成功':
                that.switchMicAndHeadsetIcon('mic', false, fndStu);
                break;
              case '打开扬声器成功':
                that.switchMicAndHeadsetIcon('headset', true, fndStu);
                // that.dealHjStyle(fndStu, 'remove'); //新增加
                break;
              case '关闭扬声器成功':
                that.switchMicAndHeadsetIcon('headset', false, fndStu);
                break;
            }
          }
          else{
            par['用户类型'] = usrType;
            par.Message = that.$common.cloneObj(msgCont);
            that.pushMsgToArr(par, tp);
          }
        };
        //发送消息
        let selfSendMsg = function(tp){
          let tmpMsg = null;
          if(sndMsgSuc){
            if(tp){
              switch(tp){
                case 'send_peer':
                  tmpMsg = '消息发送给用户成功！';
                  break;
                case 'send_channel':
                  tmpMsg = '消息发送给频道成功！';
                  that.msgVal = '';
                  break;
              }
            }
            that.pushSndMsgToArr(sndMsgData, tp);
            that.$message({
              showClose: true,
              message: tmpMsg,
              type: 'success'
            });
          }
          else{
            if(par && par.error){
              that.$message({
                showClose: true,
                message: par.error,
                type: 'error'
              });
            }
          }
        };
        switch(cmd){
          case 'CMD_JOIN':
            // 加入频道
            if(par.result){
              that.isMsgTchJoin = true;
            }
            else{
              if(par.error == '已加入该频道'){
                that.isMsgTchJoin = false;
              }
              else{
                setTimeout(function(){
                  if(cnlStr){
                    that.joinChannel(cnlStr);
                  }
                }, 1000);
              }
            }
            break;
          case 'CMD_SEND_MSG_TO_PEER':
            selfSendMsg('send_peer');
            break;
          case 'CMD_SEND_MSG_TO_CHANNEL':
            selfSendMsg('send_channel');
            break;
          case 'EVT_MSG_FROM_PEER':
            receiveMsg('peer');
            break;
          case 'EVT_MSG_FROM_CHANNEL':
            receiveMsg('channel');
            break;
        }
      })(cmdMsg, parMsg);
    },
    //去除呼叫闪烁
    dealHjStyle(stu, tp) {
      let huJiaoId = stu['呼叫机位'] == 1 ? '#onejt_' + stu['UID'] : '#twojt_' + stu['UID'];
      if(tp == 'add'){
        document.querySelector(huJiaoId).classList.add('vibrate-1');
      }
      else{
        stu['呼叫机位'] = null;
        document.querySelector(huJiaoId).classList.remove('vibrate-1');
      }
    },
    //强制刷新
    forceRerender() {
      that.renderComponent = false; 
      that.$nextTick(function(){
        that.renderComponent = true; 
      });
    },
    //统计在线视频数量
    countOnlineNum() {
      // let bkNum = {
      //   one: 0,
      //   two: 0
      // };
      if($('.stuVideo_1')){
        that.onlineInfo['一机位在线人数'] = $('.stuVideo_1').find('.agora_video_player').length || 0;
      }
      if($('.stuVideo_2')){
        that.onlineInfo['二机位在线人数'] = $('.stuVideo_2').find('.agora_video_player').length || 0;
      }
      // return bkNum;
    },
    //定义滚动函数
    scrollToBottom(slt) {
      const domWrapper = document.querySelector(slt); // 外层容器 出现滚动条的dom
      if(domWrapper){
        (function smoothscroll() {
          const currentScroll = domWrapper.scrollTop;   // 已经被卷掉的高度
          const clientHeight = domWrapper.offsetHeight; // 容器高度
          const scrollHeight = domWrapper.scrollHeight; // 内容总高度
          if (scrollHeight - 10 > currentScroll + clientHeight) {
            window.requestAnimationFrame(smoothscroll);
            domWrapper.scrollTo(0, currentScroll + (scrollHeight - currentScroll - clientHeight) / 2);
          }
        })();
      }
    },
    //刷新考试数据
    refreshData() {
      that.queryExamData(that.sltExamState);
    },
    // 标记消息为已读
    async markMsgHasRead(stu, tp) {
      let stuUid = stu['UID'];
      let msgArr = stu['未读消息'];
      const resltPromises = msgArr.map(async msg => {
        let param = {
          '消息ID': ''
        };
        if(msg['消息ID'] && msg['消息状态'] === 0){
          param['消息ID'] = msg['消息ID'];
          let bkDt = await postFun(touchMessageUrl, param).then((res) => {
            if(res.result) {
              return res;
            }
            else{
              return {
                result: false
              }
            }
          });
          return bkDt;
        }
        else{
          return {
            result: false
          }
        }
      });
      for(const resultPromise of resltPromises){
        let result = await resultPromise;
        // console.log('result000:', result);
      }
      if(stuUid && that.studentData){
        Lazy(that.studentData).each(function(ostu){
          if(ostu['UID'] == stuUid){
            ostu['未读消息'] = [];
            ostu['未读消息数量'] = 0;
          }
        });
      }
      if(stuUid && that.msgUserData){
        Lazy(that.msgUserData).each(function(ostu){
          if(ostu['UID'] == stuUid){
            ostu['未读消息'] = [];
            ostu['未读消息数量'] = 0;
          }
        });
      }
    },
    //测试函数
    testFun(id, cl) {
      // that.scrollToBottom('.umcrMsgList');
      // let param = {
      //   // 'UID': loginUid
      // };
      // getFun('jiankao/role', param).then((res) => {
      //   if (res.result && res.data) {
      //     console.log('888:', res.data);
      //   }
      //   else{
      //     console.log('没有查出数据！');
      //   }
      // });
      // let stuId = 'stua_' + id;
      // let msg = cl ? '侧机位取消呼叫' : '侧机位呼叫老师';
      // let parTmp = {
      //   Channel: 'room_1_8',
      //   FromUID: stuId,
      //   Message: {
      //     'Type': 2,
      //     'Message': msg
      //   },
      //   MessageID: '578393528251239688',
      // };
      // that.backInfoCb('EVT_MSG_FROM_CHANNEL', parTmp);
    }
  },
  created() {
    that = this;
    console.log('user:', that.user);
    if(that.user && that.user['机构ID']){
      jgId = that.user['机构ID'];
      loginUid = that.user['UID'];
      msgTchUid = 'teach_' + loginUid;
      let yhQxArr = that.user['用户权限'];
      if(yhQxArr && yhQxArr.length > 0){
        let jsIds = Lazy(yhQxArr).map(function(js){
          return js['角色ID']
        }).toArray();
        highRole = Lazy(jsIds).max();
      }
    }
    else{
      that.$message({
        showClose: true,
        message: '缺少机构ID！',
        type: 'warning'
      });
    }
  },
  mounted() {
    that.teacherData=[
      {
        'UID': that.user['UID'],
        '姓名': that.user['姓名'],
        '教师静音': false
      }
    ];
    new Promise(function(resolve){
      that.$ws.startWebSocket();
      resolve();
    })
    .then(function(){
      that.$ws.logout(msgTchUid);
      return new Promise(function(resolve){
        setTimeout(function(){
          that.$ws.setOnWsCmd(that.backInfoCb);
          resolve();
        }, 1000);
      });
    })
    .then(function(){
      that.$ws.login(msgTchUid);
    });

    that.queryPiCiData();
    // that.queryExamData(2);
  },
  destroyed() {
    that.$ws.logout(msgTchUid);
    console.log('离开监考房间页面了！');
  }
};
</script>

<style>
.el-main{
  position: relative;
  height: calc(100vh - 60px);
}
.mt10{
  margin-top: 10px;
}
.mt20{
  margin-top: 20px;
}
.mr15{
  margin-right: 15px;
}
.mr30{
  margin-right: 30px;
}
.mr7{
  margin-right: 7px;
}
.ml5{
  margin-left: 5px;
}
.ml15{
  margin-left: 15px;
}
.ml20{
  margin-left: 20px;
}
.ml30{
  margin-left: 30px;
}
.plr15{
  padding-left: 15px;
  padding-right: 15px;
}
.flr{
  float: right;
}
.fz14{
  font-size: 14px;
}
.fz20{
  font-size: 20px;
}
.cl1D394D{
  color: #1D394D;
}
.cl5B95BD{
  color: #5B95BD;
}
.clDD1C1C{
  color: #DD1C1C;
}
.ftwt600{
  font-weight: 600;
}
.csrPt{
  cursor: pointer;
}
.talnRt{
  text-align: right;
}
.dpIb{
  display: inline-block;
}
.piciCont .el-input__inner{
  background-color: #F6F6F6;
  border: none;
  height: 36px;
}
.el-button--grayblue {
  color: #1D394D;
  background-color: #F6F6F6;
  border-color: #F6F6F6;
}
.el-button--grayblue:focus, 
.el-button--grayblue:active, 
.el-button--grayblue:hover {
  background: #5B95BD;
  border-color: #5B95BD;
  color: #fff;
}
.el-button--graywhite {
  color: #1D394D;
  background-color: #fff;
  border-color: #fff;
}
.el-button--graywhite:focus, 
.el-button--graywhite:active, 
.el-button--graywhite:hover {
  background: #5B95BD;
  border-color: #5B95BD;
  color: #fff;
}
.grayblueOn{
  background: #5B95BD;
  border-color: #5B95BD;
  color: #fff;
}
.el-button--sendbutton {
  color: #5B95BD;
  background-color: #ECECEC;
  border-color: #ECECEC;
}
.el-button--sendbutton:focus, 
.el-button--sendbutton:active, 
.el-button--sendbutton:hover {
  background: #5B95BD;
  border-color: #5B95BD;
  color: #fff;
}
.el-button--lightblue {
  color: #5B95BD;
  background-color: #EEF8FF;
  border-color: #EEF8FF;
}
.el-button--lightblue:focus, 
.el-button--lightblue:active, 
.el-button--lightblue:hover {
  background: #d5edff;
  border-color: #d5edff;
  color: #5B95BD;
}
.el-table__header th.el-table__cell{
  background-color: #5B95BD;
  color: #fff;
  font-weight: normal;
}
.schbInput .el-input__inner{
  border: 0;
  background-color: #F6F6F6;
}
.rnSchWrap .el-input__inner{
  height: 30px;
  line-height: 30px;
}
.pageCont .el-pagination{
  display: inline-block;
}
.pageCont .el-pagination.is-background .el-pager li,
.pageCont .el-pagination.is-background .btn-next, 
.pageCont .el-pagination.is-background .btn-prev{
  color: #5B95BD;
  font-size: 14px;
  min-width: 40px;
  height: 40px;
  line-height: 40px;
}
.pageCont .el-pagination.is-background .btn-next, 
.pageCont .el-pagination.is-background .btn-prev{
  min-width: 80px;
}
.pageCont .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #5B95BD;
  color: #FFF;
}
.pageCont .el-pagination button, 
.pageCont .el-pagination span:not([class*=suffix]){
  height: 40px;
  line-height: 40px;
  font-size: 14px;
}
.jumpCont .jumpInput{
  width: 80px;
  height: 40px;
}
.jumpCont .el-input__inner{
  background-color: #DFEEF9;
  border: 0px;
  text-align: center;
}
.jumpCont .jumpBtn{
  color: #EBAE39;
  font-size: 18px;
  margin-left: 20px;
}
.schUser .el-input__inner{
  background-color: #f6f6f6;
  border: 0;
  height: 36px;
  line-height: 36px;
}
.inputBox .el-textarea__inner{
  height: 120px;
  border: 0;
  resize: none;
  line-height: 30px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
.studentCard .el-card__body{
  padding: 10px 0;
}
.zoomWrap .el-card__body{
  background: #ffffff;
  width: 1240px;
  height: 600px;
  margin: 50px auto 0 auto;
  position: relative;
  box-sizing: content-box;
  padding: 0;
}
.imageWrap>.el-dialog{
  width: 70%;
  height: 70%;
}
.imageWrap .el-dialog__body{
  padding: 0;
}
.imageWrap .el-dialog__header{
  padding: 0;
  display: none;
}
.el-col {
  /* 解决没有内容时列消失的问题 */
  min-height: 1px; 
}
.el-icon-hujiao:before{
  content: url('../../assets/image/ic_laidianshi.png');
}
.el-icon-closemic:before{
  content: url('../../assets/image/ic_closemic.png');
}
.el-icon-openmic:before{
  content: url('../../assets/image/ic_openmic.png');
}
.el-icon-closespeaker:before{
  content: url('../../assets/image/ic_yangshengqi1.png');
}
.el-icon-openspeaker:before{
  content: url('../../assets/image/ic_yangshengqi2.png');
}
.el-icon-erhujiao:before{
  content: url('../../assets/image/ic_laidianshi.png');
}
.el-icon-erclose:before{
  content: url('../../assets/image/ic_maikefengyangshengqi.png');
}
.el-icon-eropen:before{
  content: url('../../assets/image/ic_maikefengyangshengqi2.png');
}
</style>

<style scoped>
.rpNav{
  position: fixed;
  top: 0;
  background-color: #fff;
  height: 54px;
  padding-right: 20px;
  line-height: 54px;
  z-index: 2;
  width: 50%;
  margin-left: -5px;
  padding-left: 5px;
}
.backIcon{
  color: #1D394D;
  font-size: 20px;
  margin-left: 15px;
  /* margin-right: 5px; */
  font-weight: 600;
}
.rpWrap{
  display: flex;
  position: absolute;
  left: 20px;
  right: 20px;
  top: 20px;
  bottom: 20px;
}
.rmListCont{
  flex-grow: 1;
  background-color: #fff;
  overflow-y: auto;
  padding: 15px;
}
.zmUnfold{
  position: relative;
  cursor: pointer;
  padding-left: 10px;
  width: 18px;
}
.zoomBtn{
  width: 18px;
  height: 28px;
  display: block;
  background-color: #EEF8FF;
  color: #5B95BD;
  border-radius: 14px 0px 0px 14px;
  line-height: 28px;
  font-size: 18px;
  text-align: center;
  position: absolute;
  top: 50%;
  margin-top: -14px;
}
.zmFold{
  background-color: #fff;
  margin-left: 15px;
  width: 54px;
  text-align: center;
  font-size: 18px;
  position: relative;
  cursor: pointer;
}
.zmFold .zoomBtn{
  margin-left: 16px;
}
.zmFold p{
  margin: 0;
}
.zoomBtnWrap{
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  background-color: #5B95BD;
}
.msgCont{
  /* background-color: #fff; */
  overflow: hidden;
  position: relative;
}
.rpUnfold .msgCont{
  width: 36%;
  min-width: 520px;
}
.piciCont{
  display: inline-block;
}
.stateBtns{
  margin-top: 20px;
}
.examCont{
  margin-top: 20px;
}
.searchBox{
  width: 250px;
  height: 36px;
  background-color: #F6F6F6;
  float: right;
  position: relative;
}
.schbInput{
  width: 214px;
}
.schBtn{
  font-size: 20px;
  height: 36px;
  position: absolute;
  width: 36px;
  line-height: 36px;
  text-align: center;
  cursor: pointer;
  top: 0;
  right: 0;
}
.refreshWarp{
  float: right;
}
.pageCont{
  margin-top: 20px;
  text-align: right;
}
.jumpCont{
  float: right;
  margin-left: 10px;
  margin-top: -2px;
}
.seeMsgBtn{
  font-size: 14px;
}
.goToRoomBtn{
  text-decoration: none;
  color: #5BBD7E;
}
.userMgsCont{
  position: absolute;
  width: 100%;
  top: 50px;
  bottom: 0;
}
.umcLeft{
  width: 30%;
  position: absolute;
  top: 0px;
  bottom: 0px;
  background-color: #F1EFEF;
}
.umcRight{
  width: 70%;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 30%;
  background-color: #fff;
}
.userSchBox{
  background-color: #fff;
  height: 58px;
}
.schUser{
  margin-top: 11px;
}
.roomTitle{
  color: #1D394D;
  background-color: #fff;
  margin-top: 15px;
  height: 30px;
  line-height: 30px;
  width: 94%;
  margin-left: 3%;
}
.rtIcon{
  margin-left: 15px;
}
.rtName{
  margin-left: 10px;
}
.img-avatar{
  width: 18px;
  height: 18px;
  margin-left: 5px;
  float: left;
  margin-top: 7px;
}
.usrName{
  font-size: 12px;
  display: inline-block;
  margin-left: 10px;
}
.img-xxicon{
  position: absolute;
  top: 5px;
  right: 10px;
}
.userListSmry{
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 100px;
  bottom: 50px;
  width: 100%;
  overflow-y: auto;
}
.userListSmry li{
  text-align: center;
  position: relative;
  margin-top: 10px;
}
.userListSmry .img-avatar{
  float: none;
  margin-left: 0;
  width: 24px;
  height: 24px;
}
.userListSmry .usrName{
  float: none;
  margin-left: 0;
  display: block;
}
.userList{
  list-style: none;
  margin: 0 5px 15px 15px;
  padding: 0;
}
.userList li{
  height: 30px;
  line-height: 30px;
  background-color: #fff;
  margin-bottom: 10px;
  cursor: pointer;
}
.userList li:hover{
  background-color: #F2F2F2;
}
.userList .active{
  background-color: #E3E1E1;
}
.userList .el-avatar{
  width: 16px;
  height: 16px;
  margin-left: 5px;
  float: left;
  margin-top: 6px;
}
.msgNum{
  float: right;
  background-color: #DD1C1C;
  color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  margin-top: 5px;
  margin-right: 10px;
}
.userCont{
  position: absolute;
  width: 100%;
  top: 115px;
  bottom: 0px;
  overflow-y: auto;
}
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
.el-textarea ::-webkit-scrollbar,
::-webkit-scrollbar{
  width: 8px;
  height: 8px;
  background-color: #ffffff;
}
/*定义滚动条轨道 内阴影+圆角*/
.el-textarea ::-webkit-scrollbar-track,
::-webkit-scrollbar-track{
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
  border-radius: 10px;
  background-color: #fff;
}
/*定义滑块 内阴影+圆角*/
.el-textarea ::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 4px rgb(217 217 217 / 60%);
  background-color: #dbdbdb;
}
.umcrTitle{
  height: 58px;
  line-height: 58px;
  border-left: 1px solid #EFEFEF;
  border-bottom: 1px solid #EFEFEF;
}
.umcrtName{
  color: #1D394D;
  margin-left: 30px;
}
.umcrtIcon{
  width: 30px;
  height: 30px;
  background-color: #DFEEF9;
  float: right;
  border-radius: 50%;
  margin-top: 14px;
  margin-right: 20px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
}
.umcrtIcon i{
  color: #5B95BD;
}
.umcrMsgList{
  position: absolute;
  top: 58px;
  bottom: 180px;
  width: 100%;
  overflow-y: auto;
  padding-bottom: 10px;
}
.usrMsgBox{
  margin: 20px 10px 0 10px;
}
.msgTime{
  text-align: center;
  font-size: 12px;
  color: #999999;
}
.msgBox{
  margin-top: 15px;
}
.userAvatar{
  float: left;
}
.userAvatar .el-avatar,
.userAvatar>img{
  width: 24px;
  height: 24px;
}
.trglGray{
  display: inline-block;
  border-style: solid;
  border-color: transparent;
  border-width: 5px 15px 5px 0;
  border-right-color: #ECECEC;
  vertical-align: top;
  margin-top: 10px;
}
.msgBg{
  background-color: #ECECEC;
  display: inline-block;
  width: 66%;
  padding: 10px 15px;
  border-radius: 6px;
  color: #000;
}
.myMsg{
  text-align: right;
}
.myMsg .userAvatar{
  float: right;
}
.myMsg .msgBg{
  background-color: #5B95BD;
  color: #fff;
  text-align: left;
}
.myMsg .trglGray{
  border-width: 5px 0 5px 15px;
  border-left-color: #5B95BD;
}
.umcrInput{
  position: absolute;
  bottom: 0px;
  height: 180px;
  width: 100%;
  border-top: 1px solid #ffb8b8;
}
.sendBox{
  margin-top: 10px;
  text-align: right;
}
.sendBtn{
  margin-right: 15px;
  width: 80px;
}
/* 学生视频容器 */
.stuVideoWrap{
  flex-grow: 1;
  overflow-y: auto;
  padding: 15px;
  margin-top: -15px;
  position: relative;
}
.roomNav{
  height: 58px;
  background-color: #fff;
}
.rnSchWrap .searchBox{
  margin-top: 15px;
  width: auto;
  float: none;
  margin-right: 20px;
  height: 30px;
}
.rnSchWrap .schbInput{
  margin-right: 36px;
  /* width: auto; */
  /* width: -moz-available; */
  width: -webkit-fill-available;
  /* width: fill-available; */
}
.rnSchWrap .schBtn{
  height: 30px;
  line-height: 30px;
  font-size: 18px;
}
.rnRoomList{
  list-style: none;
  padding: 0;
  margin: 14px 15px 0 20px;
  white-space: nowrap;
  overflow-x: auto;
}
.rnRoomList li{
  display: inline-block;
  height: 30px;
  background-color: #F6F6F6;
  border-radius: 4px;
  line-height: 30px;
  padding: 0 8px;
  margin-right: 15px;
  white-space: nowrap;
  cursor: pointer;
}
.rnRoomList li i{
  color: #999999;
}
.rnRoomList .rnrlName{
  color: #1D394D;
  margin-left: 5px;
}
.rnRoomList .rnRoomOn{
  background-color: #5B95BD;
  color: #fff;
}
.rnRoomList .rnRoomOn .rnrlName,
.rnRoomList .rnRoomOn i{
  color: #fff;
}
.videoWrap{
  position: absolute;
  top: 83px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
}
.vwRoomOutline{
  margin-top: 10px;
  font-size: 14px;
  color: #1D394D;
}
.vwroInfo {
  margin:0 15px;
}
.videos{
  margin-top: 10px;
}
.studentCard{
  display: inline-block;
  margin: 15px;
  border: 0;
  border-radius: 0;
  position: relative;
  box-shadow: none;
}
.stuVideo{
  position: relative;
  width: 180px;
  height: 135px;
  overflow: hidden;
}
.stuVideo_1, 
.stuVideo_2{
  width: 220px;
  height: 165px;
  position: relative;
  padding: 0 10px;
}
.stuVideo_2{
  margin-top: 5px;
}
.stuName{
  width: 220px;
  padding: 10px 10px 0 10px;
  color: #1D394D;
  font-size: 14px;
  border-bottom: 1px solid #EFEFEF;
  overflow: hidden;
}
.stuName .snTxt{
  margin: 0 0 5px;
}
.jkBtnBox{
  width: 220px;
  padding: 15px 10px 0 10px;
  text-align: center;
  /* box-sizing: border-box; */
}
.jkBtnBoxBig{
  display: none;
}
.jkBtnBoxBig .jkIcon{
  margin-right: 60px;
}
.jkBtnBoxBig .jkIcon img{
  width: 32px;
}
.stuOnline{
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;
}
.stuOnline img{
  width: 100%;
}
.stuOnlineInner{
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;
}
.stuOffline{
  color: red;
  line-height: 136px;
  text-align: center;
  font-size: 14px;
}
.ksLftTime{
  width: 220px;
  margin: 0;
  padding: 10px;
  font-size: 14px;
  border-bottom: 1px solid #EFEFEF;
}
.anomalyNums{
  width: 220px;
  padding: 10px;
  font-size: 14px;
  border-bottom: 1px solid #EFEFEF;
}
.talk, .headset{
  display: inline-block;
}
.endTalk, .endHeadset{
  display: none;
}
.talkTch{
  width: 220px;
  text-align: center;
  position: absolute;
  top: 110px;
  font-size: 28px;
  display: none;
}
.shenHe{
  display: none;
}
.restoreBox{
  width: 100%;
  height: 40px;
  display: none;
  line-height: 40px;
  background-color: #1D394D;
  color: #fff;
  font-size: 14px;
}
.teacherVolume{
  margin-top: 10px;
}
.jkIcon{
  display: inline-block;
  text-align: center;
  text-decoration: none;
  position: relative;
}
.jkIcon .btnName{
  display: block;
  text-decoration: none;
  color: #1D394D;
}
.talkBox{
  position: absolute;
  bottom: 0;
  right: 15px;
  left: 15px;
  z-index: 2;
  display: none; 
}
.talkBox .jkIcon{
  background: none;
}
.tchVideos{
  background-color: #fff;
}
.tchFunBtns{
  border-bottom: 1px solid #EFEFEF;
  padding: 10px 0;
}
.zoomWrap{
  position: fixed;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  background: rgba(0, 0, 0, 0.75);
}
.restoreBtn{
  float: right;
  margin: 5px 20px 0 0;
}
.jieTuBtn{
  display: none;
  margin-left: 10px;
}
.jieTuBtn img{
  width: 60px;
}
.smallIcon{
  display: inline-block;
}
.bigIcon{
  width: 60px;
  height: 60px;
  display: none;
}
.rmBadge{
  position: absolute;
  bottom: 15px;
  left: 15px;
}
.zoomWrap .restoreBox{
  display: block;
}
.zoomWrap .stuVideo_1, 
.zoomWrap .stuVideo_2{
  width: 600px;
  height: 450px;
  display: inline-block;
  margin-top: 10px;
}
.zoomWrap .stuVideo_1{
  border-right: 0;
  border-left: 0;
  float: left;
}
.zoomWrap .stuVideo_2{
  float: right;
  /* margin-top: 0; */
  border-right: 0;
}
.zoomWrap .stuName,
.zoomWrap .ksLftTime,
.zoomWrap .anomalyNums,
.zoomWrap .jkBtnBox{
  display: none;
}
.zoomWrap .jkBtnBoxBig{
  display: block;
  width: 100%;
  margin-top: 20px;
  float: left;
  text-align: center;
}
.zoomWrap .jieTuBtn{
  display: inline-block;
}
.zoomWrap .smallIcon{
  display: none;
}
.zoomWrap .bigIcon{
  display: inline-block;
}
.zoomWrap .rmBadge{
  bottom: 40px;
  left: 35px;
}
.dlgHeader{
  height: 40px;
  line-height: 40px;
  background-color: #1D394D;
  color: #fff;
}
.dlgBody{
  position: absolute;
  top: 40px;
  bottom: 0;
  width: 100%;
  /* overflow-y: auto; */
}
.stuMsgList{
  position: absolute;
  top: 0;
  bottom: 180px;
  overflow-y: auto;
  padding-bottom: 10px;
  width: 100%;
}
.imageType{
  margin-top: 20px;
  height: 35px;
}
.imageCont{
  position: absolute;
  top: 75px;
  right: 20px;
  bottom: 20px;
  left: 20px;
  overflow-y: auto;
}
.imgBox{
  width: 260px;
  height: 195px;
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 15px;
}
.imgBox img{
  width: 100%;
}
.talk, .headset{
  display: inline-block;
}
.endTalk, .endHeadset{
  display: none;
}
.hideBtn,
.huJiao{
  display: none;
}
.noStudent{
  width: 100%;
  background-color: #fff;
  padding: 15px 0;
  margin-bottom: 15px;
  text-align: center;
  font-size: 18px;
  color: #DD1C1C;
  margin-top: 20px;
}
/**
 * ----------------------------------------
 * animation vibrate-1
 * ----------------------------------------
 */
.vibrate-1 {
  display: inline-block;
	-webkit-animation: vibrate-1 0.3s linear infinite both;
	        animation: vibrate-1 0.3s linear infinite both;
}
@-webkit-keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}
@keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}
.heartbeat {
	-webkit-animation: heartbeat 1.5s ease-in-out infinite both;
	        animation: heartbeat 1.5s ease-in-out infinite both;
}
@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.81);
            transform: scale(0.81);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.77);
            transform: scale(0.77);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.81);
            transform: scale(0.81);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.77);
            transform: scale(0.77);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
.infoTable{
  font-size: 18px;
  display: inline-block;
  margin-left: 30px;
}
.tdLabel{
  font-weight: bold;
  padding: 5px 10px 5px 0;
}
.msgBtns{
  background-color: #FFF2F2;
  padding: 10px;
}
.voiceBar {
  width: 6px;
  height: 60px;
  position: absolute;
  top: 15px;
  right: 10px;
  z-index: 2;
}
.vcbrInner {
  position: absolute;
  bottom: 0;
  width: 6px;
  height: 0;
  background-color: #1BE162;
  transition: height .6s ease;
  /* animation: first 0.6s linear 0.5s infinite alternate; */
}
@keyframes first {
  0% {
    height: 20px;
  }

  50% {
    height: 40px;
  }

  100% {
    height: 60px;
  }
}
.voiceBar1{
  display: none;
}
.usrImgBox{
  width: 200px;
  float: left;
  text-align: center;
  font-size: 20px;
}
.usrBox img{
  width: 200px;
}
.noUsrImg{
  width: 200px;
  height: 280px;
  background-color: rgb(207, 207, 207);
  line-height: 248px;
  color: #ff0000;
}
video {
  height: auto;
}


</style> 