import Service from './api'
import commonFun from '../utils'
let that = this

export function getFun(url, param) {
    if (!param) {
        param = {}
    }
    let usrNekot = '';
    param["时间戳"] = new Date().getTime() + commonFun.guid();
    let myTolenStr = commonFun.localStg("get", "myToken");
    if (myTolenStr) {
        usrNekot = myTolenStr;
    }
    if (usrNekot) {
        param["用户令牌"] = usrNekot;
    }
    url += commonFun.urlProcess(param);
    return Service({
        url: url,
        method: 'get',
        params: param
    })
}
export function postFun(url, param, val) {
    let usrNekot = '';
    if (!param) {
        param = {}
    }
    param["时间戳"] = new Date().getTime() + commonFun.guid();
    let myTolenStr = commonFun.localStg("get", "myToken");
    if (myTolenStr) {
        usrNekot = myTolenStr;
    }
    if (usrNekot) {
        param["用户令牌"] = usrNekot;
    }
    url += commonFun.urlProcess(param, val);
    return Service({
        url: url,
        method: 'post',
        data: param
    })
}
export function putFun(url, param) {
    let usrNekot = '';
    param["时间戳"] = new Date().getTime() + commonFun.guid();
    let myTolenStr = commonFun.localStg("get", "myToken");
    if (myTolenStr) {
        usrNekot = myTolenStr;
    }
    if (usrNekot) {
        param["用户令牌"] = usrNekot;
    }
    url += commonFun.urlProcess(param);
    return Service({
        url: url,
        method: 'put',
        data: param
    })
}
export function deleteFun(url, param) {
    let usrNekot = '';
    param["时间戳"] = new Date().getTime() + commonFun.guid();
    let myTolenStr = commonFun.localStg("get", "myToken");
    if (myTolenStr) {
        usrNekot = myTolenStr;
    }
    if (usrNekot) {
        param["用户令牌"] = usrNekot;
    }
    url += commonFun.urlProcess(param);
    return Service({
        url: url,
        method: 'delete',
        // data: param
    })
}