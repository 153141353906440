let lctObj = location;
// websocket通讯地址
// 实际业务代码自行修改为实际地址
// const WS_URL = 'ws://127.0.0.1:8081/tat_message';
// const WS_URL = 'ws://jiankao.zhifz.com:9000/tat_message';
const WS_URL = 'wss://jiankao.zhifz.com/tat_message';

// let WS_URL = lctObj.protocol == 'http:' ? 'ws://' : 'wss://';
// WS_URL += lctObj.host + '/tat_message';

const WS_UNKNOWN = 0;
const WS_CONNECTED = 1;
const WS_DISCONNECTED = 2;

// websocket对象
var __ws = null;

// websocket连接状态
var __wsConnected = WS_UNKNOWN;

// websocket连接事件
var __onWsConnected = null;

// websocket断开事件
var __onDisconnected = null;

// 收到来自用户消息的事件
// 业务代码可以修改该回调函数, 在里面处理收到的用户消息
// 比如这样:
//   __onMessageFromPeer = function(message) {....};
var __onMessageFromPeer = null;

// 收到来自频道消息的事件
// 业务代码可以修改该回调函数, 在里面处理收到的频道消息
// 比如这样:
//   __onMessageFromPeer = function(message) {....};
var __onMessageFromChannel = null;

// 登录状态
var __logined = false;

// 登录UID
var __uid = null;

// 已加入的频道列表
var __channels = {};

//已加入的远端用户
var __remoteUsr = {};

//消息的回调函数
var onWsCmd = null; 

// 心跳检测, 每隔一段时间检测连接状态, 如果处于连接中, 就向server端主动发送消息, 来重置server端与客户端的最大连接时间
var heartCheck = {
    timeout: 55000,
    timeoutObj: null,

    reset: function () {
        clearTimeout(this.timeoutObj);
        return this;
    },

    start: function () {
        var self = this;
        this.timeoutObj = setInterval(function () {
            if (__ws.readyState == 1) {
                console.debug("发送消息保持连接");
                __ws.send("ping");
                heartCheck.reset().start();    // 重置心跳检测
            }
        }, this.timeout)
    }
};

// 发送websocket数据(内部使用)
function _wsReq(cmd, params) {
    var _sendParams = {
        "__CMD__": cmd,
        "__PARAMS__": params
    };
    // __ws.send(JSON.stringify(_sendParams));
    var doFun = function () {
        if (__ws.readyState === 1) {
            __ws.send(JSON.stringify(_sendParams));
        }
        else {
            setTimeout(() => { doFun() }, 1000)
        }
    };
    doFun();
};

// websocket连接成功
function onWsOpen(evt) {
    heartCheck.reset().start();

    console.info("已连接websocket:%s", WS_URL);
    // 当连接从已断开状态变为连接时触发回调
    if (__wsConnected == WS_UNKNOWN || __wsConnected == WS_DISCONNECTED) {
        __wsConnected = WS_CONNECTED;

        // 重连成功后自动重新登录
        if (__uid) {
            _exports.login(__uid);
        }

        if (typeof __onWsConnected === 'function') {
            __onWsConnected();
        }
    }

    __ws.send('HELLO');
}

// 收到websocket消息
function onWsMessage(evt) {
    heartCheck.reset().start();

    var _msg;
    if (typeof evt.data === "string") {
        _msg = JSON.parse(evt.data);
    } else {
        return;
    }

    console.debug("收到websocket数据:", JSON.stringify(_msg));

    // 收到 websocket 推送消息
    if (_msg['__CMD__']) {
        var _cmd = _msg['__CMD__'];
        var _params = _msg['__PARAMS__'];
        if (typeof _params === "string") {
            _params = JSON.parse(_params);
        }
        let ejChnl = null;
        let eUid = null;
        switch (_cmd) {
            case "CMD_LOGIN":
                __logined = _params.result;
                if (__logined) {
                    // 保存登录的uid
                    __uid = _params.data.UID;
                    console.info('登录成功:%s', __uid);

                    // 恢复之前加入的频道
                    if (__channels) {
                        var keys = Object.keys(__channels);
                        keys.forEach(function (key) {
                            console.log('重新加入频道:%s', key);
                            _exports.join(key);
                        });
                    }
                } else {
                    console.error('登录失败,', _params.error);
                }
                break;

            case "CMD_LOGOUT":
                __logined = false;
                __uid = null;
                __channels = {};
                console.log('已登出');
                break;

            case "CMD_JOIN":
                if (_params.result) {
                    __channels[_params.data.Channel] = true;
                    console.info('已加入频道:%s', _params.data.Channel);
                } else {
                    if(_params.error == '已加入该频道'){
                        __channels[_params.data.Channel] = true;
                    }
                    console.error('加入频道:%s 失败, %s', _params.data.Channel, _params.error);
                }
                break;

            case "CMD_LEAVE":
                if (_params.result) {
                    delete __channels[_params.data.Channel];
                    console.log('已离开频道:%s', _params.data.Channel);
                } else {
                    console.error('离开频道:%s 失败, %s', _params.data.Channel, _params.error);
                }
                break;

            case "CMD_SEND_MSG_TO_PEER":
                if (_params.result) {
                    console.info('发送消息给频道:%s 的用户:%s 成功', _params.data.Channel, _params.data.ToUID);
                } else {
                    console.error('发送消息给频道:%s 的用户:%s 失败, %s', _params.data.Channel, _params.data.ToUID, _params.error);
                }
                break;

            case "CMD_SEND_MSG_TO_CHANNEL":
                if (_params.result) {
                    console.info('发送消息给频道:%s 成功', _params.data.Channel);
                } else {
                    console.error('发送消息给频道:%s 失败, %s', _params.data.Channel, _params.error);
                }
                break;

            case "EVT_JOIN":
                ejChnl = _params.Channel;
                eUid = _params.UID;
                if(__remoteUsr[ejChnl]){
                    __remoteUsr[ejChnl].push(eUid);
                    __remoteUsr[ejChnl] = Array.from(new Set(__remoteUsr[ejChnl])); 
                    console.log("__remoteUsr：", __remoteUsr);
                }
                else{
                    __remoteUsr[ejChnl] = [];
                    __remoteUsr[ejChnl].push(eUid);
                    console.log("__remoteUsr：", __remoteUsr);
                }
                console.log('用户:%s 加入频道:%s', _params.UID, _params.Channel);
                break;

            case "EVT_LEAVE":
                ejChnl = _params.Channel;
                eUid = _params.UID;
                if(__remoteUsr[ejChnl]){
                    __remoteUsr[ejChnl] = __remoteUsr[ejChnl].filter((item, index) => item != eUid);
                }
                console.log("__remoteUsr1：", __remoteUsr);
                console.log('用户:%s 离开频道:%s', _params.UID, _params.Channel);
                break;

            case "EVT_MSG_FROM_PEER":
                console.log('收到来自频道:%s 的用户:%s 的消息[%s]:%s', _params.Channel, _params.FromUID, _params.MessageID, _params.Message);
                if (typeof __onMessageFromPeer === 'function') {
                    __onMessageFromPeer(_params);
                }
                break;

            case "EVT_MSG_FROM_CHANNEL":
                console.log('收到来自频道:%s 的消息[%s]:%s', _params.Channel, _params.MessageID, _params.Message);
                if (typeof __onMessageFromChannel === 'function') {
                    __onMessageFromChannel(_params);
                }
                break;
        }
        
        if (onWsCmd) {
            try {
                onWsCmd(_cmd, _params);
            } catch(err) {
                console.error('执行消息回调触发异常:', err);
            }
        }
    }

}

// websocket断开
function onWsClose(evt) {
    console.warn("已断开websocket:", WS_URL);

    // 当连接从已连接状态变为断开时触发回调
    if (__wsConnected == WS_UNKNOWN || __wsConnected == WS_CONNECTED) {
        __wsConnected = WS_DISCONNECTED;

        if (typeof __onDisconnected === 'function') {
            __onDisconnected();
        }
    }
    // 连接断开 1秒后自动重连
    setTimeout(startWs, 1000);
}

// 启动websocket
function startWs() {
    console.info('连接websocket:%s', WS_URL);

    __ws = new WebSocket(WS_URL);
    __ws.onopen = onWsOpen;
    __ws.onmessage = onWsMessage;
    __ws.onclose = onWsClose;
}

const _exports = {
    // 启动websocket
    startWebSocket: () => {
        startWs();
    },

    // 消息格式定义(message参数):
    //   {
    //     "Type": 1,
    //     "Message": "实际消息内容"
    //   }
    // 其中 Type 定义如下:
    //   1 普通消息(用于发送各种文字通知)
    //   2 控制消息(用于控制考生端, 比如强制跳过人脸、强制结束考试等)
    //   3 语音通话(用于考生呼叫老师或者老师呼叫考生)
    // Message定义如下:
    //   当 Type=1 时, 直接在 Message 里写文本消息
    //   当 Type=2 时, 将控制命令写入 Message, 比如: 跳过人脸识别、强制结束考试、打开麦克风、关闭麦克风等等
    //   当 Type=3 时, Message可以留空

    // 发送消息给用户
    //   toUID   接收方uid
    //   channel 频道
    //   message 消息
    sendMessageToPeer: (toUID, channel, message) => {
        _wsReq('CMD_SEND_MSG_TO_PEER', { "ToUID": toUID, "Channel": channel, "Message": message });
    },
    
    // 发送消息给频道(频道中所有用户都会收到)
    //   channel 频道
    //   message 消息
    sendMessageToChannel: (channel, message) => {
        _wsReq('CMD_SEND_MSG_TO_CHANNEL', { "Channel": channel, "Message": message });
    },

    // 登录消息系统
    //   uid 用户id
    //     为了方便后台对消息进行统计归类
    //     uid需要遵循以下格式:
    //       stua_111  学生1机位
    //       stub_111  学生2机位
    //       teach_222 老师
    //     其中 111 和 222 是学生和老师的实际 uid
    login: (uid) => {
        if (__logined) {
            console.warn('已经登录了');
            return;
        }

        // 如果websocket已经连接了, 则直接发出登录请求
        if (__wsConnected == WS_CONNECTED) {
            _wsReq('CMD_LOGIN', { "UID": uid });
        } else {
            // 否则暂时保存要登录的uid, 等待websocket连接后会自动登录
            __uid = uid;
        }
    },

    // 登出消息系统
    logout: () => {
        if (__logined) {
            _wsReq('CMD_LOGOUT', '');
        }
    },

    // 加入消息频道
    //   channel 频道
    //     为了方便后台对消息进行统计归类
    //     channel需要遵循以下格式:
    //        room_111_222
    //     其中 111 是考试id, 222 是房间id
    join: (channel) => {
        // 如果已经登录了, 则直接发出加入频道的命令
        if (__logined) {
            if(!__channels[channel]){
                _wsReq('CMD_JOIN', { "Channel": channel });
            }
            else{
                console.log('已加入频道：', channel);
            }
        } else {
            // 否则暂时保存频道信息, 等待登录成功后会自动加入频道
            __channels[channel] = false;
            console.log('361：用户还未登陆');
        }
    },

    // 离开消息频道
    //   channel 频道
    leave: (channel) => {
        _wsReq('CMD_LEAVE', { "Channel": channel });
    },

    // 设置 WebSocket 回调
    setOnWsCmd: (cb) => {
        onWsCmd = cb;
    },

    //获得远端加入频道的用户
    getChnlUsr: () => {
        let backObg = {
            '加入的频道': __channels,
            '频道用户': __remoteUsr
        }
        return backObg;
    },

};

export default _exports;