<template>
  <div>首页</div>
</template>

<script>
export default {
  name: "FirstPage",
};
</script>

<style scoped>
</style>