module.exports = {
    lang: {
        'noSignal': '无视频信号',
        'leave': '离开',
        'joinIn': '加入',
        'swtCamera': '切换摄像头',
        'resumeCall': '恢复通话',
        'reopenEqu': '重新打开设备',
        'joinSuc': '成功加入监考房间！',
        'leaveRoom': '离开监考房间！',
        'clkUrc': '点击右上角的',
        'usrSafari': '选择Safari浏览器打开',
        'switching': '切换中…',
        'misUsrInfo': '缺少个人信息，请重新扫描二维码！',
        'reClickBtn': '打开麦克风或摄像头失败，请点击‘重新打开设备’按钮。',
        'reOpenFail': '重新打开设备失败',
        'resumeCall': '通话失败，请点击‘恢复通话’按钮。',
        'resumeCallFail': '恢复通话失败',
        'talking': '通话中...',
        'sideTalking': '侧机位通话中...',
        'login': '登录',
        'logout': '退出',
        'inputPsw': '请输入密码',
        'inputUname': '请输入用户名',
        'videoJk': '视频监考',
        'quitSystem': '退出系统',
        'setRoom': '设置系统',
        'jkRoom': '监考房间',
        'examList': '考试列表',
        'errCode': '错误状态码：',
        'logouting': '用户登录状态注销中',
        'sltPici': '选择要操作的考试批次',
        'backToSlt': '返回选择页面',
        'roomStr': '房间',
        'addRoom': '添加监控房间',
        'seeStu': '查看考生',
        'seeTch': '查看教师',
        'download': '下载',
        // 'addFile': '添加导入文件',
        'addFile': '手动导入房间规则',
        'file': '文件',
        'uploading': '上传中',
        'clickUpload': '点击上传',
        'clearRoom': '清空教师-房间关联',
        'deleteRoom': '删除监控房间',
        'backPiCi': '返回批次列表',
        'jkTch': '监考教师',
        'jkAdmin': '技术支持',
        'pcName': '批次名称',
        'operate': '操作',
        'roomName': '房间名',
        'rgstNumber': '报名号',
        'stuName': '考生姓名',
        'jkTchEmail': '监考教师邮箱',
        'jkTchName': '监考教师姓名',
        'jkPower': '监考权限',
        'noPiciData': '没有批次数据！',
        'noRoomData': '没有监控房间数据！', 
        'noData': '暂无数据！', 
        'roomHasClear': '监控房间已清除！', 
        'seeRoom': '查看监控房间',
        'missUid': '缺少UID！',
        'dataQuerying': '数据查询中...',
        'msgChlLgFail': '登录消息通道失败！',
        'msgChlJoinFail': '加入消息通道失败！',
        'openMicFail': '麦克风或摄像头开启失败，请检查权限之后关掉浏览器，再次扫描二维码。',
        'commError': '通信错误，请刷新浏览器后再次扫描二维码。',
        'appNotAllow': '您的应用程序不允许使用麦克风和相机的权限！',
        'error': '错误信息：',
        'addSideMotSuc': '侧方监控加入成功！',
        'misZcid': '缺少注册ID！',
        'batchAddTch': '批量添加教师',
        'misKszid': '缺少考试组ID！',
        'closeRoom': '关闭房间',
        'left': '考试剩余时间',
        'minute': '分钟',
        'unLogin': '未登录',
        'noSignal': '无视频信号',
        'front': '正面',
        'side': '侧面',
        'sideMotConnt': '侧机位通话中...',
        'closeVideo': '关闭视频',
        'snapFrontImg': '截取正面图像',
        'closeSideTalk': '关闭侧机位通话',
        'sideTalk': '侧机位通话',
        'openMic': '打开麦克风',
        'closeMic': '关闭麦克风',
        'openHeadset': '打开扬声器',
        'closeHeadset': '关闭扬声器',
        'sendMsg': '发消息',
        'seePics': '查看照片',
        'passShenHe': '通过审核',
        'zoomVideo': '放大视频',
        'examRecord': '考试情况记录',
        'restoreVideo': '还原视频',
        'abnormal': '异常次数',
        'times': '次',
        'addTime': '加时',
        'quitWebSystem': '强制退出考试端',
        'closeFace': '关闭人脸识别',
        'openFace': '开启人脸识别',
        'jumpScan': '跳过侧机位扫码',
        'loginAgain': '允许再次登录',
        'answerNums': '答题进度',
        'motTch': '监控教师',
        'groupMsg': '这是群发消息',
        'studentID': '学号',
        'name': '姓名',
        'plcMsgAlert': '请输入要信息，可以为空',
        'shortLang': '快捷语言:(点击下面的语言加入到输入框)',
        'sendGrpMsg': '群发消息',
        'send': '发送',
        'abnormalImg': '学生异常行为照片',
        'submit': '确定',
        'close': '关闭',
        'reStuState': '重置学生状态',
        'cfmReSttState': '确定要重置学生状态吗？',
        'addTimeToStu': '给学生加时',
        'allAddTime': '总加时',
        'second': '秒',
        'freeAnswer': '自由作答',
        'tingAddTime': '此大题有听力，小题只能勾选复选框加时',
        'zuoAddTime': '此大题为限时作文题，请分别给描述和作答加时',
        'descPart': '描述部分',
        'answerPart': '作答部分',
        'inputMins': '请输入分钟数',
        'restoreTing': '重置听力题',
        'hasTing': '已听完',
        'unTing': '未听',
        'openVideo': '打开视频',
        'unmute': '解除静音',
        'mute': '静音',
        'refreshState': '刷新',
        'sendRoomMsg': '群发消息',
        'closeRommMic': '关闭监听全体考生',
        'openRoomMic': '监听全体考生',
        'closeRoomHandset': '关闭对全体考生发言',
        'openRoomHandset': '对全体考生发言',
        'noJoinRoom': '还未加入房间！',
        'jkMsgZt_1': '暂停考试！请等待任课老师联系或主动联系任课老师。',
        'jkMsgZt_2': '暂停考试！请检查监控手机屏幕，核对考试时间、是否有自己影像，如果问题请重新扫二维码。',
        'jkMsgZt_3': '暂停考试！请调好监控手机，确保看到自己脸部、上半身、双手、考试手机、桌面、草稿纸等。',
        'jkMsgZt_4': '暂停考试！请把手机放到左上或右上45度、高度离桌面40cm以上，离自己1米。确保看到自己脸部、上半身、双手、考试手机、桌面、草稿纸等。',
        'jkMsg_1': '请各位考生设好双机位后耐心等待， 监考老师将一一进行核对。',
        'jkMsg_2': '请上传手持证件和承诺书的正面清晰照。',
        'jkMsg_3': '你的调试已成功，请保持，等待考试开始。',
        'jkMsg_4': '请保证你的上半身、双手和作答手机都在监控内，请调整。',
        'jkMsg_5': '请保证监控画面是竖屏的，请调整。',
        'jkMsg_6': '请保证桌面物品符合《考场规则》要求，请调整。',
        'jkMsg_7': '请作答完毕一题即刻上传该题答案照片，以免丢失。',
        'jkMsg_8': '请确保拍摄的答案照片清晰，如因照片不清晰影响阅卷，后果自负。',
        'jkMsg_9': '离考试结束还有15分钟，请合理安排时间。如还有试题未上传答案，请及时上传。',
        'itemXh': '题号',
        'currentState': '当前状态',
        'noStuData': '未查到学生数据！',
        'syncServerErr': '同步服务器时间失败！',
        'missComd': '缺少命令！',
        'missParam': '缺少参数！',
        'noStuInRoom': '此考生还未加入监控！',
        'misStuInfo': '缺少学生信息！',
        'sltMsgType': '请选择消息类型！',
        'grpSendDone': '群发完成！',
        'reConnect': '通话失败，请点击学生头像下面的‘恢复通话’按钮。',
        'misTchUid': '缺少教师UID',
        'sendShMsgFail': '给学生发通过审核信息失败！',
        'misServerIp': '缺少服务器地址！',
        'misKaoDian': '缺少考点详情！',
        'reStuState': '学生状态重置中...',
        'reStuStateSuc': '学生状态重置成功！',
        'addingTime': '加时中...',
        'addingTimeSuc': '加时成功！',
        'msgSending': '信息发送中...',
        'msgSendingSuc': '信息发送成功！',
        'jumpScanMsgSend': '跳过侧机位扫码命令已发送',
        'eventPending': '事件待处理',
        'misMachCode': '缺少机器编码！',
        'quitKsdMsgSend': '退出考试端命令已发送成功！',
        'misKsid': '缺少考试ID！',
        'notStarted': '未开考',
        'started': '考试中',
        'stuList': '学生列表',
        'examName': '考试名称',
        'beginDate': '开考时间',
        'endDate': '结束时间',
        'applicantNum': '报考人数',
        'jkkwNum': '监考考务人数',
        'jkjsNum': '监考教师人数',
        'noKeMuInfo': '未查到科目信息！',
        'missXxId': '缺少学校ID！',
        'missKmId': '缺少科目ID！',
        'refreshStuData': '刷新学生数据',
        'refresh': '后可刷新',
        'reset': '重置',
        'addTimeMinute': '加时分钟数',
        'stuImg': '学生照片',
        'keXuHao': '教学班',
        'schedule': '进度',
        'sltKaoShi': '此考试不同场次设定了不同的考点服务器，请选择具体的场次！',
        'enterTimeVal': '请输入加时时长！',
        'state': '状态',
        'allowExam': '继续考试',
        'pauseExam': '暂停考试',
        'forceEnd': '强制交卷',
        'sendMessage': '发送消息',
        'disciplinary': '违纪记录',
        'cancel': '取消',
        'importSuc': '导入成功',
        'seePic': '查看图片',
        'saveVlt': '保存违纪',
        'dataSaveSuc': '数据保存成功！',
        'leftTime': '剩余分钟',
        'khsyMin': '考后上传剩余',
        'logouting': '用户登录状态注销中…',
        'logining': '登录中...',
        'ykNum': '应考人数',
        'skNum': '实考人数',
        'wdlNum': '未登录人数',
        'ydlNum': '已登录人数',
        'yjjNum': '已交卷人数',
        'ksImg': '考生照片',
        'zpImg': '抓拍照片',
        'enrolled': '已报名',
        'answer': '考试中',
        'submitted': '已交卷',
        'onUpload': '正在上传作答',
        'uploaded': '已上传作答',
        'logged': '已登录',
        'ycCutImg': '异常行为截图',
        'handCutImg': '手动截图',
        'dsCutImg': '定时轮询截图',
        'myPhoto': '本人照片',
        'phone': '手机',
        'certNum': '证件号',
        'rgstPwd': '报名密码',
        'serAddr': '服务器地址',
        'total': '共',
        'sheet': '张',
        'postExamJiaShi': '考后上传作答加时',
        'topic': '题',
        'answered': '已作答',
        'unanswered': '未作答',
        'rtcInit': 'TatRTC初始化失败!',
        'leaveMonit': '离开监控',
        'notSelf': '非考生本人',
        'multPeople': '多人入镜',
        'viewLr': '左右查看',
        'dataProc': '数据处理中...',
        'statRlsbz': '人脸识别中',
        'statRlyzsb': '人脸验证失败',
        'statCjwsmz': '侧机位扫码中',
        'statCjwsmcg': '侧机位扫码成功',
        'statCjwsmsb': '侧机位扫码失败',
        'statKsxz': '考试须知页面',
        'statDdsh': '等待审核',
        'statDdjrks': '等待进入考试',
        'statKsz': '考试中',
        'statJj': '交卷',
        'statDkmkfcg': '打开麦克风成功',
        'statGbmkfsb': '关闭麦克风成功',
        'statDkysqcg': '打开扬声器成功',
        'statDkysqsb': '关闭扬声器成功',
        'statJxks': '继续考试',
        'statYdk': '已打开',
        'statRm': '耳麦',
        'statYsq': '扬声器',
        'statKhsczdz': '考后上传作答中',
        'noChkStu': '未检测到考生',
        'selectWjlx': '请选择违纪类型！',
        'addInvlt': '添加监考教师',
        'add': '添加',
        'modRoomName': '修改房间名称',
        'addTo': '添加给',
        'delete': '中删除',
        'from': '从',
        'selectAll': '全选教师',
        'batchAdd': '批量新增',
        'conflictData': '冲突数据',
        'missJkRoom': '缺少监考房间',
        'misPiciId': '缺少批次ID！',
        'modSuccess': '修改成功！',
        'missJkRoomName': '缺少房间名称',
        'roomNotMod': '房间名称相同不需要修改',
        'roomSetSuc': '房间教师设置成功！',
        'noImpData': '没有任何导入数据！',
        'callTeacher': '呼叫老师',
        'calling': '呼叫中...',
        'cancelCall': '取消呼叫',
        'expStu': '导出学生信息',
        'focAndSave': '强制交卷并保存违纪',
        'ksKsSj': '开始时间',
        'kmName': '科目名称',

    }
};