<template>
  <div>
    <div class="btn">
      <div class="btn_no1">
        <el-button size="small" @click="exportFun">导出Excel数据</el-button>
        <el-button size="small" @click="deleteFun">删除老师</el-button>

        <div class="selBox">
          <el-select
            v-model="value"
            @change="selStudent"
            size="small"
            placeholder="请选择"
          >
            <el-option label="全部老师" value="0" size="small"> </el-option>
            <el-option label="未关联房间老师" value="1" size="small">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="seach">
        <el-input
          placeholder="请输入内容"
          suffix-icon="el-icon-search"
          style="width: 200px"
          size="small"
          v-model="input2"
        >
        </el-input>
      </div>
    </div>
    <div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        id="exportTab"
        :header-cell-style="{
          textAlign: 'center',
          background: '#5B95BD',
          fontSize: ' 16px',
          fontFamily: ' PingFang SC',
          fontWeight: 500,
          color: '#FFFFFF',
        }"
        height="600px"
        :cell-style="{ textAlign: 'center' }"
        :row-class-name="tableRowClassName"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="姓名" label="监考名称">
          <template slot-scope="scope">
            <el-input
              size="small"
              v-model="form['姓名']"
              v-show="scope.row.isShow"
            >
            </el-input>
            <span v-show="!scope.row.isShow">{{ scope.row["姓名"] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="用户名" label="监考账号">
          <template slot-scope="scope">
            <el-input
              size="small"
              v-model="form['用户名']"
              v-show="scope.row.isShow"
            >
            </el-input>
            <span v-show="!scope.row.isShow">{{ scope.row["用户名"] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="密码" label="初始密码">
          <template slot-scope="scope">
            <el-input
              size="small"
              v-model="form['密码']"
              v-show="scope.row.isShow"
            >
            </el-input>
            <span v-show="!scope.row.isShow">{{ scope.row["密码"] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="账号权限">
          <template slot-scope="scope">
            <div class="quanxian">
              {{ switchFun(scope.row["用户权限"][0]["角色ID"]) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="监考房间">
          <template slot-scope="scope">
            <el-button
              v-show="scope.row['房间'].length"
              @click="watchClick(scope.row)"
              type="text"
              size="small"
              >查看</el-button
            >
            <span v-show="!scope.row['房间'].length">未设置</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              @click="handleClick(scope.row)"
              v-show="!scope.row.isShow"
              type="text"
              size="small"
              >修改</el-button
            >
            <el-button
              @click="submitFun(scope.row)"
              v-show="scope.row.isShow"
              type="text"
              size="small"
              >确定</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[20, 50, 100]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog title="查看房间" :visible.sync="dialogVisible" width="40%">
      <el-table
        ref="multipleTable"
        :data="watchTable"
        :header-cell-style="{
          textAlign: 'center',
          background: '#5B95BD',
          fontSize: ' 16px',
          fontFamily: ' PingFang SC',
          fontWeight: 500,
          color: '#FFFFFF',
        }"
        max-height="300px"
        :cell-style="{ textAlign: 'center' }"
        style="width: 100%"
      >
        <el-table-column prop="房间名称" label="监考房间名称">
        </el-table-column>
        <!-- <el-table-column prop="用户名" label="监考账号"> </el-table-column>
        <el-table-column prop="密码" label="初始密码"> </el-table-column> -->
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
let that;
let watchUrl = "/jiankao/watch";
let room_watchUrl = "/jiankao/room_watch";
let userUrl = "/jiankao/user";
import {
  getFun,
  postFun,
  deleteFun,
  putFun,
} from "../../../../request/request";
export default {
  data() {
    return {
      form: {
        姓名: "",
        用户名: "",
        密码: "",
      },
      value: "",
      tableData: [],
      currentPage4: 1,
      pagesize: 20,
      total: 100,
      input2: "",
      dialogVisible: false,
      watchTable: [],
      isShow: false,
      copyList: [],
      examListArr: [],
    };
  },
  watch: {
    input2: {
      handler() {
        const realList = that.copyList.filter((order) => {
          return Object.keys(that.input2).reduce((flag, item) => {
            if (!flag) {
              return false;
            } else {
              return that.input2[item].trim()
                ? String(order["姓名"]).indexOf(that.input2[item]) !== -1
                : true;
            }
          }, true);
        });
        that.tableData = realList;
      },
      deep: true,
    },
  },
  methods: {
    /**
     * 删除考生
     */
    deleteFun() {
      if (!that.examListArr.length) {
        this.$message({
          type: "error",
          message: "请选择要删除的老师!",
        });
        return;
      }
      let arr = [];
      that.examListArr.forEach((item) => {
        arr.push(item.UID);
      });
      let param = {
        考试ID: that.$route.query["考试ID"],
        老师: JSON.stringify(arr),
      };
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteFun(room_watchUrl, param).then((res) => {
            if (res.result) {
              that.getTeacher();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch((err) => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSelectionChange(rows) {
      // this.selectedRowIndex = rows;
      that.examListArr = rows;
    },
    selStudent(val) {
      if (val == "0") {
        that.tableData = that.copyList;
      }
      if (val == "1") {
        that.tableData = that.copyList.filter((item) => {
          return !item["房间"];
        });
      }
    },
    /**
     * 导出数据
     */
    exportFun() {
      if (!that.tableData.length) {
        that.$message({
          message: "当前没有可以导出的数据！",
          type: "warning",
        });
        return;
      }
      that.$common.getExcel("老师列表", "teacher");
    },

    /**
     * 查看
     */
    watchClick(val) {
      that.dialogVisible = true;
      that.watchTable = val["房间"];
    },
    switchFun(val) {
      switch (val) {
        case 3:
          return "监考";
        case 4:
          return "巡考";

        default:
          break;
      }
    },
    /**
     * 获取老师数据
     */
    getTeacher() {
      let param = {
        批次ID: that.$route.query["批次ID"],
        机构ID: that.$route.query["机构ID"],
        考试ID: that.$route.query["考试ID"],
        最大记录数: that.pagesize,
        跳过记录数: that.pagesize * (that.currentPage4 - 1),
      };
      that.tableData = [];
      that.copyList = [];
      getFun(watchUrl, param).then((res) => {
        console.log(res);
        if (res.data && res.result) {
          that.total = res.total_records;
          res.data.forEach((item) => {
            let indexValue = that.tableData.findIndex((v) => v.UID == item.UID);
            if (indexValue == -1) {
              item["房间"] = [];
              item["房间"].push(item);
              that.tableData.push(item);
              that.copyList.push(item);
            } 
          });
          console.log(that.tableData);
        }
      });
    },
    /**
     * 修改用户确定按钮
     */
    submitFun(val) {
      val.isShow = false;
      that.$forceUpdate();
      let param = that.form;
      postFun(userUrl, param).then((res) => {
        if (res.result) {
          this.$message({
            type: "success",
            message: "修改成功!",
          });
          that.getTeacher();
        }
      });
    },
    /**
     * 点击修改出现输入框
     */
    handleClick(row) {
      row.isShow = true;
      that.$forceUpdate();
      that.form["姓名"] = row["姓名"];
      that.form["用户名"] = row["用户名"];
      that.form["密码"] = row["密码"];
      that.form.UID = row.UID;
      that.form["用户类型"] = 1;
    },
    handleSizeChange(val) {
      that.pagesize = val;
      that.getTeacher();
    },
    handleCurrentChange(val) {
      that.currentPage4 = val;
      that.getTeacher();
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex; //为每行数据对象添加“索引”属性，在handleSelectionChange方法中会用到
      row = this.tableData;
      if (row[rowIndex].isHighLight === true) {
        return "rowHighLight"; //若isHighLight为true，则使用“rowHighLight”样式
      } else {
        return "";
      }
    },
  },
  created() {
    that = this;
    that.getTeacher();
  },
};
</script>

<style scoped>
.quanxian {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  width: 66px;
  height: 26px;
  background: #8c97cb;
  margin: 0 auto;
}
.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.num {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #5b95bd;
}
/deep/.seach .el-input__inner {
  background: #f6f6f6;
  border: none;
}
.btn_no1 {
  display: flex;
}
.btn {
  display: flex;
  margin: 27px 0 17px 0;
  justify-content: space-between;
}
.btn .el-button {
  height: 30px;
  background: #eef8ff;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #5b95bd;
  border: none !important;
}
.selBox {
  margin-left: 30px;
}
/deep/.selBox .el-input__inner {
  border: none !important;
  background: #eef8ff;
}
/deep/.selBox .el-select {
  width: 100px;
  height: 30px;

  border: none !important;
}
.header {
  width: 100%;
}
</style>