import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue'
import Login from '../views/Login.vue'
import InvigilateRoom from '../components/InvigilateRoom/InvigilateRoom.vue'
import AdminManage from '../components/ManageSystem/AdminManage'
import DataShow from '../components/ManageSystem/DataShow'
import ExamList from '../components/ManageSystem/ExamList'
import PiciManage from '../components/ManageSystem/PiciManage'
import FirstPage from '../components/ManageSystem/FirstPage'
import AddExam from '../components/ManageSystem/ExamListPage/AddExam'
import StudentImport from '../components/ManageSystem/ExamListPage/StudentImport'
import CreateRoom from '../components/ManageSystem/ExamListPage/CreateRoom'
import RelationTeacher from '../components/ManageSystem/ExamListPage/RelationTeacher'
import CreateJiankaoData from '../components/ManageSystem/ExamListPage/CreateJiankaoData'
import StudentList from '../components/ManageSystem/ExamListPage/watchExam/StudentList'
import RoomList from '../components/ManageSystem/ExamListPage/watchExam/RoomList'
import TeacherList from '../components/ManageSystem/ExamListPage/watchExam/TeacherList'
Vue.use(VueRouter)

// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//     return originalPush.call(this, location).catch(err => err)
// }
const routes = [
    // {
    //     path: '/',
    //     redirect: '/login'
    // },
    {
        path: '/login',
        name: 'Login',
        meta: {
            requireAuth: false
        },
        component: Login,
    },
    {
        path: '/main',
        name: 'Main',
        component: Main,
        meta: {
            requireAuth: true,
            title: ''
        },
        children: [{
                path: '/invigilateRoom',
                name: 'InvigilateRoom',
                component: InvigilateRoom,
                meta: {
                    title: '监考房间',
                    requireAuth: true
                }
            },
            {
                path: '/adminManage',
                name: 'AdminManage',
                component: AdminManage,
                meta: {
                    title: 'Admin管理',
                    requireAuth: true
                }
            }, {
                path: '/dataShow',
                name: 'DataShow',
                component: DataShow,
                meta: {
                    title: '数据看板',
                    requireAuth: true
                }
            }, {
                path: '/examList',
                name: 'ExamList',
                component: ExamList,
                meta: {
                    title: '考试列表',
                    requireAuth: true
                },
                children: []
            },
            {
                path: '/addExam',
                name: 'AddExam',
                component: AddExam,
                meta: {
                    title: '考试列表',
                    isAdd: '新增考试',
                    pagename: '考试设置',
                    requireAuth: true,
                    active: '/examList'
                }
            },
            {
                path: '/studentImport',
                name: 'studentImport',
                component: StudentImport,
                meta: {
                    title: '考试列表',
                    isAdd: '新增考试',
                    pagename: '考生名单导入',
                    requireAuth: true,
                    active: '/examList'
                }
            },
            {
                path: '/createRoom',
                name: 'CreateRoom',
                component: CreateRoom,
                meta: {
                    title: '考试列表',
                    isAdd: '新增考试',
                    pagename: '创建监考房间',
                    requireAuth: true,
                    active: '/examList'
                }
            },
            {
                path: '/relationTeacher',
                name: 'RelationTeacher',
                component: RelationTeacher,
                meta: {
                    title: '考试列表',
                    isAdd: '新增考试',
                    pagename: '关联监考老师',
                    requireAuth: true,
                    active: '/examList'
                }
            },
            {
                path: '/createJiankaoData',
                name: 'CreateJiankaoData',
                component: CreateJiankaoData,
                meta: {
                    title: '考试列表',
                    isAdd: '',
                    requireAuth: true,
                    active: '/examList'
                },
                children: [{
                        path: '/studentList',
                        name: 'StudentList',
                        component: StudentList,
                        meta: {
                            title: '考试列表',
                            pagename: '考生名单',
                            isAdd: '',
                            requireAuth: true,
                            active: '/examList'
                        }
                    },
                    {
                        path: '/roomList',
                        name: 'RoomList',
                        component: RoomList,
                        meta: {
                            title: '考试列表',
                            pagename: '监考房间名单',
                            isAdd: '',
                            requireAuth: true,
                            active: '/examList'
                        }
                    },
                    {
                        path: '/teacherList',
                        name: 'TeacherList',
                        component: TeacherList,
                        meta: {
                            title: '考试列表',
                            pagename: '监考老师名单',
                            isAdd: '',
                            requireAuth: true,
                            active: '/examList'
                        }
                    },
                ]
            },

            {
                path: '/piciManage',
                name: 'PiciManage',
                component: PiciManage,
                meta: {
                    title: '批次管理',
                    requireAuth: true
                }
            },
            {
                path: '/firstPage',
                name: 'FirstPage',
                component: FirstPage,
                meta: {
                    title: '首页',
                    requireAuth: true
                }
            },
        ]
    },



]

const router = new VueRouter({
    // mode: 'history',
    // base: process.env.BASE_URL,
    routes
})
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err)
}

export default router