<template>
  <div class="mainbox">
    <el-col :span="isCollapse ? 1 : 3">
      <el-menu
        :default-active="activeMenu"
        class="el-menu-vertical-demo"
        background-color="#1D394D"
        text-color="#fff"
        active-text-color="#ffd04b"
        :collapse-transition="false"
        :collapse="isCollapse"
        router
      >
        <div class="logo-box">LOGO</div>
        <el-menu-item
          v-for="(item, index) in menuArr"
          :key="index"
          :index="item.path"
        >
          <img
            :src="activeMenu == item.path ? item.meta.iconing : item.meta.icon"
          />
          <!-- {{ routerName }} -->
          <span slot="title">{{ item.meta.title }}</span>
          <div v-show="activeMenu == item.path">
            <div class="circle"></div>
            <div class="square"></div>
            <div class="circletop"></div>
            <div class="squaretop"></div>
          </div>
        </el-menu-item>
        <span class="zhankai" @click="open"
          >{{ isCollapse ? "展开" : "收起" }}
          <img :src="isCollapse ? zhankai : shouqi" alt=""
        /></span>
      </el-menu>
    </el-col>
    <el-col :span="isCollapse ? 23 : 21">
      <el-header>
        <div class="header">
          <div>
            <i
              v-if="$route.meta.isAdd"
              class="el-icon-arrow-left"
              @click="back"
            >
            </i>
            {{ $route.meta.title }}
            <span v-if="$route.meta.isAdd">/{{ $route.meta.isAdd }} </span>
          </div>
          <div class="userMsg" @click.stop="userManage">
            <div class="dateBox">
              <div class="date">{{ date }}</div>
              <div class="time">{{ time }}</div>
            </div>
            <img src="../assets/image/ic_laoshi.png" alt="" />{{
              user["用户权限"][0]["机构名称"]
            }}
            - {{ user["用户权限"][0]["角色名称"] }} - {{ user["姓名"]
            }}<i
              :class="isshow ? 'el-icon-caret-top' : 'el-icon-caret-bottom'"
            ></i>
            <!-- <i class="el-icon-caret-top" style="display: none"></i> -->
          </div>
          <div class="tuichu" v-show="isshow" id="menu_arr">
            <div @click="updatePassword">修改密码</div>
            <div @click="logout">退出</div>
          </div>
        </div>
      </el-header>
      <el-main>
        <div class="liuchengBox" v-if="$route.meta.isAdd == '新增考试'">
          <div class="addExamliucheng">
            <div
              class="xuhaobox"
              v-for="(item, index) in liuchengArr"
              :key="index"
            >
              <div
                :class="
                  $route.meta.pagename == item.label ? 'addno1' : 'addno2'
                "
              >
                <div class="xuhao">{{ item.index }}</div>
                <div style="margin-top: 20px">{{ item.label }}</div>
              </div>
              <div class="jiantou" v-show="index != liuchengArr.length - 1">
                <img
                  v-show="$route.meta.pagename == item.label"
                  src="../assets/image/ic_jiantou1.png"
                  alt=""
                />
                <img
                  v-show="$route.meta.pagename != item.label"
                  src="../assets/image/ic_jiantou2.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <router-view v-if="$route.meta.isAdd == '新增考试'"></router-view>
        </div>
        <router-view v-if="$route.meta.isAdd != '新增考试'"></router-view>
      </el-main>
    </el-col>
    <el-dialog title="修改密码" :visible.sync="dialogVisible" width="25%">
      <div class="dialogBtn">
        <el-button size="small" class="cancel" @click="cancel()"
          >取消</el-button
        >
        <el-button
          size="small"
          :disabled="iserror"
          class="addbtn"
          @click="submit()"
          >确定</el-button
        >
      </div>
      <div class="dilogTop">
        <div class="dialbox">
          <div style="width: 110px">新密码</div>
          <el-input
            show-password
            placeholder="请输入内容"
            size="small"
            v-model="password"
          >
          </el-input>
        </div>
        <div class="dialbox">
          <div style="width: 110px">确认新密码</div>
          <el-input
            show-password
            placeholder="请输入内容"
            size="small"
            v-model="newPassword"
          >
          </el-input>
          <span class="pcdm" v-show="iserror">该批次代码已存在</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
let that;
import { mapState, mapMutations } from "vuex";
let logoutUrl = "/jiankao/logout";
let userUrl = "/jiankao/user";
import TeacherListVue from "../components/ManageSystem/ExamListPage/watchExam/TeacherList.vue";
import { postFun } from "../request/request";

export default {
  name: "Main",
  data() {
    return {
      iserror: false,
      password: "",
      newPassword: "",
      liuchengArr: [
        {
          index: "01",
          label: "考试设置",
        },
        {
          index: "02",
          label: "考生名单导入",
        },
        {
          index: "03",
          label: "创建监考房间",
        },
        {
          index: "04",
          label: "关联监考老师",
        },
      ],
      menuArr: [],
      isshow: false,
      date: null,
      time: null,
      dialogVisible: false,
      isCollapse: true,
      zhankai: require("../assets/image/ic_examzhankai.png"),
      shouqi: require("../assets/image/ic_examshouqi .png"),
    };
  },
  methods: {
    ...mapMutations(["changeRouterName"]),
    open() {
      that.isCollapse = !that.isCollapse;
    },
    /**
     * 修改密码取消
     */
    cancel() {
      that.dialogVisible = false;
      that.password = "";
      that.newPassword = "";
    },
    /**
     * 修改密码确认
     */
    submit() {
      if (that.password != that.newPassword) {
        that.$message({
          message: "两次输入密码不一致，请重新输入！",
          type: "error",
        });
        return;
      }
      let param = {};
      param["密码"] = that.newPassword;
      param.UID = that.user.UID;
      param["用户名"] = that.user["用户名"];
      param["姓名"] = that.user["姓名"];
      param["机构ID"] = that.user["机构ID"];
      param["用户名"] = that.user["用户名"];

      param["用户类型"] = that.user["用户类型"];
      // return;
      postFun(userUrl, param).then((res) => {
        if (res.result) {
          that.$message({
            message: "密码修改成功！",
            type: "success",
          });
          that.cancel();
        }
      });
    },
    /**
     * 修改密码
     */
    updatePassword() {
      that.dialogVisible = true;
    },
    getToday() {
      var datas = new Date();
      var on1 = "-";
      var on2 = ":";
      var onY = datas.getFullYear(); //年
      var onM = datas.getMonth() + 1; //月
      var onD = datas.getDate(); //日
      var onS = datas.getHours(); //时
      var onF = datas.getMinutes(); //分
      var onN = datas.getSeconds(); //秒

      if (onM >= 1 && onM <= 9) {
        //月
        onM = "0" + onM;
      }
      if (onD >= 1 && onD <= 9) {
        //日
        onD = "0" + onD;
      }
      if (onS >= 0 && onS <= 9) {
        //时
        onS = "0" + onS;
      }
      if (onF >= 0 && onF <= 9) {
        //分
        onF = "0" + onF;
      }
      if (onN >= 0 && onN <= 9) {
        //秒
        onN = "0" + onN;
      }

      return {
        date: onY + on1 + onM + on1 + onD,
        time: onS + on2 + onF + on2 + onN,
      };
    },
    logout() {
      postFun(logoutUrl).then((res) => {
        if (res.result) {
          this.$message({
            type: "success",
            message: "已退出登录",
          });
          that.$common.localStg("remove", "myToken");
          that.$router.push({ path: "/login" });
        }
      });
    },
    userManage() {
      that.isshow = !that.isshow;
      that.$forceUpdate();
    },
    back() {
      that.$router.push({ path: "/examList" });
    },
  },
  // computed: mapState({
  //   // 箭头函数可使代码更简练
  //   user: (state) => state.user,
  //   routerName: (state) => state.routerName,
  // }),
  computed: {
    ...mapState(["user", "routerName"]),
    activeMenu() {
      // 获取path地址
      var path = this.$route.path;
      if (this.$route.meta.active) {
        path = this.$route.meta.active;
      }

      // 判断path地址是否是以/xxx开头的(/xxx 为对应显示的一级路由的导航的路由地址)
      if (path.startsWith("/tools")) {
        // 如果是则返回一级路由的导航的路由地址
        return "/tools";
      }
      return path;
    },
  },
  mounted() {
    const clock = setInterval(() => {
      this.date = that.getToday().date;
      this.time = that.getToday().time;
    }, 1000);
    // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
    this.$once("hook:beforeDestroy", () => {
      clearInterval(clock);
    });
    if (that.user["用户权限"] && that.user["用户权限"].length) {
      that.user["用户权限"].forEach((item) => {
        if (item["角色ID"] == 1 && that.user["姓名"] == "admin") {
          that.menuArr = [
            {
              path: "/adminManage",
              name: "AdminManage",
              meta: {
                title: "Admin管理",
                icon: require("../assets/image/ic_guanli1.png"),
                iconing: require("../assets/image/ic_guanli1(1).png"),
              },
            },
          ];
        }
        if (item["角色ID"] == 1 && that.user["姓名"] != "admin") {
          that.menuArr = [
            {
              path: "/firstPage",
              name: "FirstPage",
              meta: {
                title: "首页",
                icon: require("../assets/image/ic_shouye1.png"),
                iconing: require("../assets/image/ic_shouye2.png"),
              },
            },

            {
              path: "/examList",
              name: "ExamList",
              meta: {
                title: "考试列表",
                icon: require("../assets/image/ic_kaoshiliebiao1.png"),
                iconing: require("../assets/image/ic_kaoshiliebiao2 .png"),
              },
            },
            {
              path: "/invigilateRoom",
              name: "InvigilateRoom",
              meta: {
                title: "监考房间",
                icon: require("../assets/image/ic_jiankaofangjian1 .png"),
                iconing: require("../assets/image/ic_jiankaofangjian2.png"),
              },
            },
            {
              path: "/dataShow",
              name: "DataShow",
              meta: {
                title: "数据看板",
                icon: require("../assets/image/ic_shujukanban1 .png"),
                iconing: require("../assets/image/ic_shujukanban2 .png"),
              },
            },
            {
              path: "/piciManage",
              name: "PiciManage",
              meta: {
                title: "批次管理",
                icon: require("../assets/image/ic_piciguanli 1.png"),
                iconing: require("../assets/image/ic_piciguanli 2.png"),
              },
            },
          ];
        }
        if (item["角色ID"] == 2) {
          that.menuArr = [
            {
              path: "/firstPage",
              name: "FirstPage",
              meta: {
                title: "首页",
                icon: require("../assets/image/ic_shouye1.png"),
                iconing: require("../assets/image/ic_shouye2.png"),
              },
            },

            {
              path: "/invigilateRoom",
              name: "InvigilateRoom",
              meta: {
                title: "监考房间",
                icon: require("../assets/image/ic_jiankaofangjian1 .png"),
                iconing: require("../assets/image/ic_jiankaofangjian2.png"),
              },
            },
            {
              path: "/dataShow",
              name: "DataShow",
              meta: {
                title: "数据看板",
                icon: require("../assets/image/ic_shujukanban1 .png"),
                iconing: require("../assets/image/ic_shujukanban2 .png"),
              },
            },
          ];
        }
        if (item["角色ID"] == 3) {
          that.menuArr = [
            {
              path: "/firstPage",
              name: "FirstPage",
              meta: {
                title: "首页",
                icon: require("../assets/image/ic_shouye1.png"),
                iconing: require("../assets/image/ic_shouye2.png"),
              },
            },

            {
              path: "/invigilateRoom",
              name: "InvigilateRoom",
              meta: {
                title: "监考房间",
                icon: require("../assets/image/ic_jiankaofangjian1 .png"),
                iconing: require("../assets/image/ic_jiankaofangjian2.png"),
              },
            },
            {
              path: "/dataShow",
              name: "DataShow",
              meta: {
                title: "数据看板",
                icon: require("../assets/image/ic_shujukanban1 .png"),
                iconing: require("../assets/image/ic_shujukanban2 .png"),
              },
            },
          ];
        }
        if (item["角色ID"] == 4) {
          that.menuArr = [
            {
              path: "/firstPage",
              name: "FirstPage",
              meta: {
                title: "首页",
                icon: require("../assets/image/ic_shouye1.png"),
                iconing: require("../assets/image/ic_shouye2.png"),
              },
            },

            {
              path: "/invigilateRoom",
              name: "InvigilateRoom",
              meta: {
                title: "监考房间",
                icon: require("../assets/image/ic_jiankaofangjian1 .png"),
                iconing: require("../assets/image/ic_jiankaofangjian2.png"),
              },
            },
            {
              path: "/dataShow",
              name: "DataShow",
              meta: {
                title: "数据看板",
                icon: require("../assets/image/ic_shujukanban1 .png"),
                iconing: require("../assets/image/ic_shujukanban2 .png"),
              },
            },
          ];
        }
      });
    } else {
      that.menuArr = [
        {
          path: "/firstPage",
          name: "FirstPage",
          meta: {
            title: "首页",
            icon: require("../assets/image/ic_shouye1.png"),
            iconing: require("../assets/image/ic_shouye2.png"),
          },
        },
        {
          path: "/examList",
          name: "ExamList",
          meta: {
            title: "考试列表",
            icon: require("../assets/image/ic_kaoshiliebiao1.png"),
            iconing: require("../assets/image/ic_kaoshiliebiao2 .png"),
          },
        },
        {
          path: "/invigilateRoom",
          name: "InvigilateRoom",
          meta: {
            title: "监考房间",
            icon: require("../assets/image/ic_jiankaofangjian1 .png"),
            iconing: require("../assets/image/ic_jiankaofangjian2.png"),
          },
        },
        {
          path: "/dataShow",
          name: "DataShow",
          meta: {
            title: "数据看板",
            icon: require("../assets/image/ic_shujukanban1 .png"),
            iconing: require("../assets/image/ic_shujukanban2 .png"),
          },
        },
        {
          path: "/piciManage",
          name: "PiciManage",
          meta: {
            title: "批次管理",
            icon: require("../assets/image/ic_piciguanli 1.png"),
            iconing: require("../assets/image/ic_piciguanli 1.png"),
          },
        },
      ];
    }
  },
  created() {
    that = this;
    document.addEventListener("click", (e) => {
      let list = document.getElementById("menu_arr");

      if (list.contains(e.target)) {
      } else {
        this.isshow = false;
      }
    });
  },
};
</script>

<style scoped>
.el-menu--collapse {
  width: 100%;
}
/deep/.el-dialog__body {
  /* width: 100% ; */
}
/deep/.dialbox .el-input__inner {
  width: 280px;
  background-color: #f6f6f6;
  border: none;
}
.dialbox > div {
  margin-right: 15px;
  margin-left: 20px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #1d394d;
}
.dialbox {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
  position: relative;
}
.cancel {
  width: 64px;
  height: 30px;
  background: #dd1c1c;
  border-radius: 4px;
  color: #fff;
  border: none;
}
.addbtn {
  width: 64px;
  height: 30px;
  background: #5b95bd;
  border-radius: 4px;
  color: #fff;
  border: none;
}
.dialogBtn {
  position: absolute;
  top: 5px;
  right: 5px;
}
.el-dialog {
  position: relative;
}
/deep/.el-dialog__headerbtn {
  top: 15px;
}
/deep/.el-dialog__title {
  color: #fff !important;
}
/deep/.el-dialog__header {
  background: #1d394d;
  height: 40px;
  padding: 0 20px;
  display: flex;
  align-items: center;
}
.dateBox {
  display: flex;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #1d394d;
}

.time {
  margin-left: 10px;
  margin-right: 15px;
}

.userMsg {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.tuichu > div {
  padding-left: 15px;
  cursor: pointer;
}
.tuichu {
  width: 125px;
  height: 90px;
  background: #ffffff;
  box-shadow: 0px 3px 12px 1px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  position: absolute;
  bottom: -90px;
  right: 0;
  z-index: 1;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #1d394d;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.xuhaobox {
  display: flex;
  align-items: center;
}
.xuhao {
  position: absolute;
  top: 15px;
  color: #fff;
}
.jiantou {
  margin: 0 30px;
}
.addExamliucheng {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.liuchengBox {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.addno1,
.addno2 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #1d394d;
  flex-direction: column;
  position: relative;
}
.addno2 {
  width: 150px;
  height: 120px;
  background: url("../assets/image/ic_liucheng2.png");
  background-size: 100% 100%;
}
.addno1 {
  width: 150px;
  height: 120px;
  background: url("../assets/image/ic_liucheng1.png");
  background-size: 100% 100%;
}
.el-icon-arrow-left {
  cursor: pointer;
}
.el-main {
  /* padding: 18px; */
}
.squaretop {
  position: absolute;
  border: 5px solid #f7f7f7;
  right: 0px;
  top: -10px;
  z-index: 5;
}
.circletop {
  position: absolute;
  border-radius: 50%;
  border: 10px solid #1d394d;
  right: 0px;
  top: -20px;
  z-index: 20;
}
.square {
  position: absolute;
  border: 5px solid #fff;
  right: 0px;
  bottom: -10px;
  z-index: 5;
}
.circle {
  position: absolute;
  border-radius: 50%;
  border: 10px solid #1d394d;
  right: 0px;
  bottom: -20px;
  z-index: 20;
}
/deep/.is-active {
  background-color: #f7f7f7 !important;
  color: #000 !important;
}
img {
  margin-right: 10px;
}
.el-menu-item {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  position: relative;
}
.el-icon-user-solid,
.el-icon-caret-bottom,
.el-icon-caret-top {
  font-size: 20px;
}
.header span {
  color: #5b95bd;
}
.el-header {
  padding: 0;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.header {
  height: 54px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 18px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #1d394d;
  position: relative;
}
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 8px;
  height: 12px;
}

/* 后期添加的滚动条 */
/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}

.logo-box {
  height: 100px;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1d394d;
  color: #fff;
}
.el-menu {
  height: 100%;
  position: relative;
}
.zhankai {
  position: absolute;
  color: #fff;
  bottom: 10px;
  right: 5px;
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
}
.zhankai img {
  width: 17px;
  margin-left: 3px;
}
.el-col {
  height: 100%;
}
::v-deep .el-container {
  height: 100% !important;
}
.mainbox {
  height: 100%;
  width: 100%;
}
</style>
