<template>
  <div>
    <div class="bigbox">
      <div class="import">
        <div class="text">手动导入监考信息</div>
        <div class="content">
          <div class="roomNum">
            监考老师
            <span style="font-size: 16px; font-weight: bold; color: #5b95bd"
              >{{ jiankaoNum }}个</span
            >
            巡考老师
            <span style="font-size: 16px; font-weight: bold; color: #5b95bd"
              >{{ xunkaoNum }}个</span
            >
          </div>
          <div style="margin-top: 50px; margin-bottom: 90px">
            <div class="uploadBtn">
              <span style="margin-right: 20px">监考老师导入</span>
              <el-upload
                class="upload-demo"
                action="#"
                :before-upload="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                multiple
                :limit="1"
                :on-exceed="handleExceed"
                :file-list="fileList"
              >
                <el-button class="shangchuan" size="small">导入</el-button>
              </el-upload>
              <span
                v-show="tableData.length"
                class="watchStudent"
                @click="watchStudent"
                >查看老师详情</span
              >
            </div>
          </div>
          <div class="zhuyi">
            <div>注：</div>
            <div class="point">
              <div>1、手动导入监考信息是将已经编排好的数据导入到系统中；</div>
              <div>2、导入时用Excel格式文件进行导入；</div>
              <div>3、可以在导入的文件中对监考和巡考进行账号角色的区分；</div>
              <div>4、请按照需求选择其中一种方式进行监考信息创建。</div>
            </div>
          </div>
          <div class="upload">
            <a href="监考导入模板.xlsx" download="监考导入模板.xlsx">
              下载导入模板
            </a>
          </div>
        </div>
      </div>
      <div class="import">
        <div class="text" style="background-color: #46aebd">
          自动创建监考信息
        </div>
        <div class="content">
          <!-- <div class="roomNum">
            监考房间
            <span style="font-size: 16px; font-weight: bold; color: #5b95bd"
              >20个</span
            >
          </div> -->
          <div style="margin-top: 50px">
            <div class="chuangjian">
              <div class="chuangjian_1">
                <el-col :span="4">
                  <el-input size="small" v-model="form['监考房间数']"></el-input
                ></el-col>
                <span style="margin: 0 10px">房间搭配</span>
                <el-col :span="4">
                  <el-input size="small" v-model="form['监考老师数']"></el-input
                ></el-col>
                <span style="margin-left: 10px">监考老师数量</span>
              </div>
              <div class="chuangjian_1">
                <el-col :span="4">
                  <el-input size="small" v-model="form['巡考房间数']"></el-input
                ></el-col>
                <span style="margin: 0 10px">房间搭配</span>
                <el-col :span="4">
                  <el-input size="small" v-model="form['巡考老师数']"></el-input
                ></el-col>
                <span style="margin-left: 10px">巡考老师数量</span>
              </div>
              <div class="chuangjian_1">
                <span style="margin-right: 20px">监考老师账号前缀</span>
                <el-col :span="13">
                  <el-input
                    size="small"
                    v-model="form['监考老师前缀']"
                  ></el-input
                ></el-col>
              </div>
              <div class="chuangjian_1">
                <span style="margin-right: 20px">巡考老师账号前缀</span>
                <el-col :span="13">
                  <el-input
                    size="small"
                    v-model="form['巡考老师前缀']"
                  ></el-input
                ></el-col>
              </div>
              <el-button
                :disabled="tableData.length > 0 ? true : false"
                class="shangchuan"
                size="small"
                @click="yijiansc"
                >一键生成</el-button
              >
            </div>
          </div>
          <div class="zhuyi">
            <div>注：</div>
            <div class="point">
              <div>
                1、自动创建监考信息是通过系统自动生成监考老师账号，并将监考老师
                与监考房间按照所需的数量进行分配；
              </div>
              <div>
                2、监考老师账号前缀可以对自动创建的监考账号增加前缀文字，可按需
                设置（如输入的前缀文字为JK，则监考老师名称为：JKXXX)
              </div>
              <div>3、请按照需求选择其中一种方式进行监考信息创建。</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="foot">
      <el-button class="creatExam" @click="createExam('top')">上一步</el-button>
      <el-button class="creatExam" @click="createExam('bottom')"
        >完成创建</el-button
      >
    </div>
    <el-dialog title="房间导入详情" :visible.sync="dialogVisible" width="80%">
      <div class="dialogTop">
        <div class="search">
          <el-input
            placeholder="请输入内容"
            suffix-icon="el-icon-search"
            style="width: 200px"
            size="small"
            v-model="input2"
          >
          </el-input>
        </div>
      </div>
      <div class="table">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :header-cell-style="{
            textAlign: 'center',
            background: '#5B95BD',
            fontSize: ' 16px',
            fontFamily: ' PingFang SC',
            fontWeight: 500,
            color: '#FFFFFF',
          }"
          height="600px"
          :cell-style="{ textAlign: 'center' }"
          :row-class-name="tableRowClassName"
          style="width: 100%"
        >
          <el-table-column prop="姓名" label="监考名称">
            <template slot-scope="scope">
              <el-input
                size="small"
                v-model="form['姓名']"
                v-show="scope.row.isShow"
              >
              </el-input>
              <span v-show="!scope.row.isShow">{{ scope.row["姓名"] }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="用户名" label="监考账号">
            <template slot-scope="scope">
              <el-input
                size="small"
                v-model="form['用户名']"
                v-show="scope.row.isShow"
              >
              </el-input>
              <span v-show="!scope.row.isShow">{{ scope.row["用户名"] }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="密码" label="初始密码">
            <template slot-scope="scope">
              <el-input
                size="small"
                v-model="form['密码']"
                v-show="scope.row.isShow"
              >
              </el-input>
              <span v-show="!scope.row.isShow">{{ scope.row["密码"] }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="账号权限">
            <template slot-scope="scope">
              <div class="quanxian">
                {{ switchFun(scope.row["用户权限"][0]["角色ID"]) }}
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="footer">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[20, 50, 100]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
let that;
let import_watchUrl = "/jiankao/import_watch";
let watchUrl = "/jiankao/watch";
let auto_make_room_watchUrl = "/jiankao/auto_make_room_watch";
import { getFun, postFun, deleteFun, putFun } from "../../../request/request";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      form: {
        监考房间数: "",
        巡考房间数: "",
        监考老师数: "",
        巡考老师数: "",
        监考老师前缀: "",
        巡考老师前缀: "",
      },
      currentPage4: 1,
      pagesize: 20,
      total: 0,
      fileList: [],
      dialogVisible: false,
      createDialog: false,
      tableData: [],
      input2: "",
      value: "",
      jiankaoNum: 0, //自动导入老师信息
      xunkaoNum: 0, //手动导入老师信息
    };
  },
  methods: {
    ...mapMutations(["changeRouterName"]),
    watchStudent() {
      that.dialogVisible = true;
    },
    switchFun(val) {
      switch (val) {
        case 3:
          return "监考";
        case 4:
          return "巡考";

        default:
          break;
      }
    },
    /**
     * 查看
     */
    watchClick(val) {
      that.dialogVisible = true;
      that.watchTable = val["房间"];
    },
    /**
     * 获取老师数据
     */
    getTeacher() {
      let param = {
        批次ID: that.$route.query["批次ID"],
        机构ID: that.$route.query["机构ID"],
        考试ID: that.$route.query["考试ID"],
        最大记录数: that.pagesize,
        跳过记录数: that.pagesize * (that.currentPage4 - 1),
      };
      that.jiankaoNum = 0;
      that.xunkaoNum = 0;
      getFun(watchUrl, param).then((res) => {
        if (res.data && res.result) {
          that.total = res.total_records;
          res.data.forEach((item) => {
            let indexValue = that.tableData.findIndex((v) => v.UID == item.UID);
            if (indexValue == -1) {
              item["房间"] = [];
              item["房间"].push(item);
              that.tableData.push(item);
            } else {
              that.tableData[indexValue]["房间"].push(item);
            }
            item["用户权限"].forEach((user) => {
              if (user["角色ID"] == 3) {
                that.jiankaoNum += 1;
              }
              if (user["角色ID"] == 4) {
                that.xunkaoNum += 1;
              }
            });
          });
        }
      });
    },
    /**
     * 一键生成
     */
    yijiansc() {
      let param = {
        批次ID: that.$route.query["批次ID"],
        机构ID: that.$route.query["机构ID"],
        考试ID: that.$route.query["考试ID"],
      };
      if (
        !that.form["监考老师前缀"] ||
        !that.form["监考房间数"] ||
        !that.form["监考老师数"]
      ) {
        this.$message({
          type: "error",
          message: "请补全监考老师信息",
        });
        return;
      }
      param["监考老师"] = {};
      param["巡考老师"] = {};
      param["监考老师"]["前缀"] = that.form["监考老师前缀"];
      param["巡考老师"]["前缀"] = that.form["巡考老师前缀"];
      param["监考老师"]["密码"] = "123456";
      param["巡考老师"]["密码"] = "123456";
      param["监考老师"]["分配规则"] = {};
      param["巡考老师"]["分配规则"] = {};
      param["监考老师"]["分配规则"]["房间数"] = that.form["监考房间数"];
      param["巡考老师"]["分配规则"]["房间数"] = that.form["巡考房间数"];
      param["监考老师"]["分配规则"]["老师数"] = that.form["监考老师数"];
      param["巡考老师"]["分配规则"]["老师数"] = that.form["巡考老师数"];
      param["监考老师"] = JSON.stringify(param["监考老师"]);
      param["巡考老师"] = JSON.stringify(param["巡考老师"]);
      if (!that.form["巡考老师前缀"]) {
        delete param["巡考老师"];
      }

      console.log(param);

      // return;
      postFun(auto_make_room_watchUrl, param).then((res) => {
        console.log(res);
        if (res.result) {
          that.form = {
            每房间考生数: "",
            房间前缀: "",
            监考房间数: "",
            巡考房间数: "",
            监考老师数: "",
            巡考老师数: "",
            监考老师前缀: "",
            巡考老师前缀: "",
          };
          let url = "https://jiankao.zhifz.com" + res.data["任务URL"];
          let interval = setInterval(() => {
            getFun(url).then((data) => {
              console.log(data);
              if (data.data["已结束"] && data.result) {
                that.getTeacher();
                this.$message({
                  type: "success",
                  message: "创建成功",
                });
                clearInterval(interval);
              }
            });
          }, 3000);
        }
      });
    },
    watchXq(val) {
      if (val == "export") {
        that.createDialog = true;
      } else {
        that.dialogVisible = true;
      }
    },
    handleSizeChange(val) {
      that.pagesize = val;
      that.getTeacher();
    },
    handleCurrentChange(val) {
      that.currentPage4 = val;
      that.getTeacher();
    },
    handleClick(row) {
      console.log(row);
    },

    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex; //为每行数据对象添加“索引”属性，在handleSelectionChange方法中会用到
      row = this.tableData;
      if (row[rowIndex].isHighLight === true) {
        return "rowHighLight"; //若isHighLight为true，则使用“rowHighLight”样式
      } else {
        return "";
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      if (!that.$route.query["批次ID"]) {
        this.$message({
          type: "error",
          message: "无批次信息，请先选择批次",
        });
        return;
      }
      let formDt = new FormData();
      formDt.append("批次ID", that.$route.query["批次ID"]);
      formDt.append("机构ID", that.$route.query["机构ID"]);
      formDt.append("考试ID", that.$route.query["考试ID"]);
      formDt.append("监考老师数据文件", file);
      formDt.append("调试模式", "ZDFGsdxcvsdf");

      postFun(import_watchUrl, formDt).then((res) => {
        if (res.result && res.data) {
          let url = "https://jiankao.zhifz.com" + res.data["任务URL"];
          let interval = setInterval(() => {
            getFun(url).then((data) => {
              console.log(data);
              if (data.data["已结束"] && data.result) {
                that.getTeacher();
                this.$message({
                  type: "success",
                  message: "导入监考老师成功",
                });
                clearInterval(interval);
              }
            });
          }, 3000);
        }
      });
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    /**
     * 导入名单
     */
    importList() {
      that.dialogVisible = true;
    },
    // 路由跳转
    createExam(val) {
      if (val == "top") {
        that.$router.push({ path: "/createRoom", query: that.$route.query });
      } else {
        this.changeRouterName(null);
        that.$router.push({ path: "/examList" });
      }
    },
  },
  created() {
    that = this;
    that.getTeacher();
  },
};
</script>

<style scoped>
a {
  color: #5b95bd;
}
/deep/.el-icon-upload-success {
  display: none !important;
}
/deep/.el-upload-list__item .el-icon-close {
  display: none !important;
}
.watchStudent:hover {
  color: #5b95bd;
}
.watchStudent {
  cursor: pointer;
  margin-left: 20px;
  font-size: 12px;
}
/deep/.chuangjian .el-input__inner {
  background: #f6f6f6;
}
.footer {
  display: flex;
  margin-top: 15px;
  align-items: center;
  justify-content: flex-end;
}
.dialogText span {
  font-weight: bold;
}
.dialogText {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #1d394d;
}
.dialogTop {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.shuju {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #5b95bd;
  width: 90px;
  height: 30px;
  background: #eef8ff;
  border-radius: 4px;
}
.roomNum {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #1d394d;
  position: absolute;
  top: 60px;
  left: 15px;
}
.chuangjian_1 {
  display: flex;
  align-items: center;
  padding-left: 80px;
  margin-bottom: 20px;
}
.chuangjian {
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  text-align: center;
  margin: 20px 0 30px 20px;
}
.num {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
}
.shangchuan {
  width: 170px;
  height: 30px;
  background: #eef8ff;
  border-radius: 4px;
  text-align: center;
}
.creatExam {
  width: 300px;
  height: 44px;
  background: #5b95bd;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.text {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #1d394d;
  height: 50px;
  background: #5b95bd;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 50px;
  padding-left: 10px;
}
.uploadBtn {
  display: flex;
  align-items: center;
  height: 80px;
  overflow-y: hidden;
  margin-top: 100px;
}
.upload {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #5b95bd;
  position: absolute;
  bottom: 10px;
  right: 20px;
  cursor: pointer;
}
.point div {
  margin-bottom: 5px;
}
.zhuyi {
  width: 490px;
  /* height: 126px; */
  background: #fff8f8;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #dd1c1c;
  padding: 20px 20px 20px 20px;
  display: flex;
}
.export {
  width: 170px;
  height: 30px;
  background: #eef8ff;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #5b95bd;
  margin-left: 15px;
}
.foot {
  margin-top: 50px;
  text-align: center;
}
.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.import {
  width: 564px;
  height: 564px;
  background: #ffffff;
  border-radius: 4px;
  margin-right: 76px;
  position: relative;
}
.bigbox {
  display: flex;
}
/deep/.el-dialog__title {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin-left: 20px;
}
/deep/.el-dialog__headerbtn {
  top: 15px;
}
/deep/.el-dialog__header {
  background: #1d394d;
  height: 40px;
  padding: 0 20px;
  display: flex;
  align-items: center;
}
</style>