var ipaNekot = '5dd41a348baed11245ae0449f19a70807dbb6a12';
import Lazy from 'lazy.js';
import crypto from '../../public/crypto/index';
const rtsHsah = 'HEhfYCQQscDDDUbyXrmAzNNPVXvr3e6t4JQvpFBQSkFcMTA6yyza6SNpB6s2TBGM';
import QS from 'qs';
import RtmClient from './rtm-client';
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
// 智夫子应用ID
const APPID_ZHIFZI = 'a690bce67b7144338933fa33e287f174';
const rtm = new RtmClient();
rtm.init(APPID_ZHIFZI);
window.rtm = rtm;
var onWsCmd = null;

//状态改变
rtm.on('ConnectionStateChanged', (newState, reason) => {
    var cmd = '状态改变';
    var par = {
        '状态': newState,
        '原因': reason
    };
    if (newState === 'ABORTED') {
        if (reason === 'REMOTE_LOGIN') {
            par['原因'] = 'You have already been kicked off!';
        }
    }
    if (onWsCmd) {
        // onWsCmd(cmd, par);
    }
});

//点对点收到信息
rtm.on('MessageFromPeer', async(message, peerId) => {
    var cmd = '点对点信息';
    var par = {
        'usrId': peerId,
        'message': message.text
    };
    if (onWsCmd) {
        onWsCmd(cmd, par);
    }
});

//成员加入
rtm.on('MemberJoined', ({ channelName, args }) => {
    const memberId = args[0];
    var cmd = '成员加入';
    var par = {
        'usrId': memberId,
        'channelName': channelName
    };
    if (onWsCmd) {
        onWsCmd(cmd, par);
    }
});

//成员离开
rtm.on('MemberLeft', ({ channelName, args }) => {
    const memberId = args[0];
    var cmd = '成员离开';
    var par = {
        'usrId': memberId,
        'channelName': channelName
    };
    if (onWsCmd) {
        onWsCmd(cmd, par);
    }
});

//群发信息
rtm.on('ChannelMessage', async({ channelName, args }) => {
    const [message, memberId] = args;
    var cmd = '群发信息';
    var par = {
        'usrId': memberId,
        'message': message.text,
        'channelName': channelName
    };
    if (onWsCmd) {
        onWsCmd(cmd, par);
    }
});

let qsStringify = function(objPar) {
    if (objPar === null) {
        return null;
    }
    let arr = [];
    for (let key in objPar) {
        arr.push(key + '=' + objPar[key]);
    }
    return arr.join('&');
};
const commonFun = {
    getExcel(name, val) {
        if (!name || name == '') {
            name = '数据表';
        }
        /* 从表生成工作簿对象 */
        //var wb = XLSX.utils.table_to_book(document.querySelector("#exportTab"));
        let tableDom = document.querySelector("#exportTab").cloneNode(true);
        let tableHeader = tableDom.querySelector('.el-table__header-wrapper');
        let tableBody = tableDom.querySelector('.el-table__body');
        tableHeader.childNodes[0].append(tableBody.childNodes[1]);
        let headerDom = tableHeader.childNodes[0].querySelectorAll('th');
        // 移除左侧checkbox的节点
        if (headerDom[0].querySelectorAll('.el-checkbox').length > 0) {
            headerDom[0].remove();
        }
        for (let key in headerDom) {
            if (headerDom[key].innerText === '操作') {
                headerDom[key].remove();
            }
            if (headerDom[key].innerText === '考生照片') {
                headerDom[key].remove();
            }
            if (val == 'teacher') {
                if (headerDom[key].innerText === '监考房间') {
                    headerDom[key].remove();
                }
            }
        }
        // 清理掉checkbox 和操作的button
        let tableList = tableHeader.childNodes[0].childNodes[2].querySelectorAll('td');
        for (let key = 0; key < tableList.length; key++) {
            if (tableList[key].querySelectorAll('.el-checkbox').length > 0 || tableList[key].querySelectorAll('.el-button').length > 0) {
                tableList[key].remove();
            }
        }
        /* 转换成excel时，使用原始的格式，解决数字列自动转科学计数法问题 */
        let xlsxParam = {
            raw: true
        }; //如果不加这个，会被自动转化格式，如身份证号/手机号，会被转成数字，展示为1.6697E+10

        // 获取web的节点
        let webBook = XLSX.utils.table_to_book(tableHeader, xlsxParam);
        // 把当前的book节点写入XLSX中
        let webOut = XLSX.write(webBook, { bookType: 'xlsx', bookSST: true, type: 'array' });
        try {
            FileSaver.saveAs(new Blob([webOut], { type: 'application/octet-stream' }), name + '.xlsx');
        } catch (e) {
            if (typeof console !== 'undefined') console.log(e, webOut)
        }
    },
    localStg(tp, key, val) {
        if (typeof(Storage) !== 'undefined') {
            if (tp == 'set') {
                localStorage.setItem(key, val);
            } else if (tp == 'get') {
                return localStorage.getItem(key);
            } else if (tp == 'remove') {
                localStorage.removeItem(key);
            } else if (tp == 'clear') {
                localStorage.clear();
            }
        } else {
            alert('你的设备不知道HTML5本地存储！');
        }
    },
    toObj(dt) {
        // let bcDt = str;
        // if(str && Object.prototype.toString.call(str) == '[object String]'){
        //   bcDt = JSON.parse(str);
        // }
        // return bcDt;
        if (dt && typeof(dt) == 'string') {
            try {
                dt = unescape(dt);
                let contZkhZb = dt.indexOf('[');
                let contZkhYb = dt.indexOf(']');
                let contDkhZb = dt.indexOf('{');
                let contDkhYb = dt.indexOf('}');
                if ((contZkhZb >= 0 && contZkhYb >= 0) || (contDkhZb >= 0 && contDkhYb >= 0)) {
                    return JSON.parse(dt);
                } else {
                    return dt;
                }
            } catch (err) {
                console.log(err);
            }
        } else {
            return dt;
        }
    },
    urlProcess(urlParam, val) {
        let keyArr = [];
        let keySortArr = '';
        let sha1 = crypto.createHash('sha1');
        let originStr = '';
        let sortArr = [];
        urlParam['接口令牌'] = ipaNekot;
        Lazy(urlParam).each(function(v, k, l) {
            if (k) {
                keyArr.push(k);
            }
        });
        keySortArr = keyArr.sort();
        Lazy(keySortArr).each(function(pName) {
            let newStr = pName + '=' + urlParam[pName];
            sortArr.push(newStr);
        });
        originStr = sortArr.join('&');
        originStr += rtsHsah;
        sha1.update(originStr, 'utf8');
        urlParam['签名'] = sha1.digest('hex');
        let backStr
        if (val) {
            let headObj = {}
            headObj['接口令牌'] = urlParam['接口令牌']
                // headObj['签名'] = urlParam['签名']
            headObj['用户令牌'] = urlParam['用户令牌']
            headObj['时间戳'] = urlParam['时间戳']
            headObj["调试模式"] = "ZDFGsdxcvsdf";
            backStr = '?' + qsStringify(headObj);
        } else {
            backStr = '?' + qsStringify(urlParam);

        }

        // console.log(urlParam)
        return backStr;
    },
    guid() {

        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {

            var r = Math.random() * 16 | 0,

                v = c == 'x' ? r : (r & 0x3 | 0x8);

            return v.toString(16);

        });
    },
    //声网发送消息的代码
    //声网login
    agLogin(params) {
        var data = {
            result: true
        };
        if (rtm._logined) {
            return new Promise(resolve => {
                resolve(data);
            });
        } else {
            return rtm.login(params.accountName, params.token).then(function() {
                rtm._logined = true;
                return data;
            });
            // .catch(function(err){
            //   data.result = false;
            //   data.error = err;
            //   return data;
            // });
        }
    },
    //声网logout
    agLogout(params) {
        var data = {
            result: true
        };
        if (rtm._logined) {
            return rtm.logout().then(function() {
                rtm._logined = false;
                return data;
            });
            // .catch(function(err){
            //   data.result = false;
            //   data.error = err;
            //   return data;
            // });
        } else {
            return new Promise(resolve => {
                resolve(data);
            });
        }
    },
    //声网join
    agJoin(params) {
        var data = {
            result: true
        };

        return new Promise((resolve, reject) => {
            if (!rtm._logined) {
                reject(new Error('Please Login First'));
                return;
            }

            if (rtm.channels[params.channelName] && rtm.channels[params.channelName].joined) {
                resolve(data);
                return;
            }

            return rtm.joinChannel(params.channelName).then(() => {
                    rtm.channels[params.channelName].joined = true;
                    data.data = rtm.accountName;
                    resolve(data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    //声网leave
    agLeave(params) {
        var data = {
            result: true
        };

        return new Promise((resolve, reject) => {
            if (!rtm._logined) {
                reject(new Error('Please Login First'));
                return;
            } else if (!rtm.channels[params.channelName] || (rtm.channels[params.channelName] && !rtm.channels[params.channelName].joined)) {
                resolve(data);
                return;
            }

            return rtm.leaveChannel(params.channelName).then(() => {
                    if (rtm.channels[params.channelName]) {
                        rtm.channels[params.channelName].joined = false;
                        rtm.channels[params.channelName] = null;
                    }
                    data.data = rtm.accountName;
                    resolve(data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    //发送房间消息
    agSendChnlMsg(params) {
        var result = true;
        var resultTxt = '';
        var data = {
            result: true
        };
        if (!rtm._logined) {
            result = false;
            resultTxt = 'Please Login First';
        } else if (!rtm.channels[params.channelName] || (rtm.channels[params.channelName] && !rtm.channels[params.channelName].joined)) {
            result = false;
            resultTxt = 'Please Join first';
        }
        if (result) {
            return rtm.sendChannelMessage(params.channelMessage, params.channelName).then(() => {
                    return data;
                })
                .catch(function(err) {
                    data.result = false;
                    data.error = err;
                    return data;
                });
        } else {
            return new Promise(resolve => {
                data.result = false;
                data.error = resultTxt;
                resolve(data);
            });
        }
    },
    //给个人发送信息
    agSendPeerMsg(params) {
        var result = true;
        var resultTxt = '';
        var data = {
            result: true
        };
        if (!rtm._logined) {
            result = false;
            resultTxt = 'Please Login First';
        }
        // else if (!rtm.channels[params.channelName] || (rtm.channels[params.channelName] && !rtm.channels[params.channelName].joined)){
        //   result = false;
        //   resultTxt = 'Please Join first';
        // }
        if (result) {
            return rtm.sendPeerMessage(params.peerMessage, params.peerId).then(() => {
                    return data;
                })
                .catch(function(err) {
                    data.result = false;
                    data.error = err;
                    return data;
                });
        } else {
            return new Promise(resolve => {
                data.result = false;
                data.error = resultTxt;
                resolve(data);
            });
        }
    },
    //查询在线用户状态
    agQueryPeer(params) {
        var result = true;
        var resultTxt = '';
        var data = {
            result: true
        };
        if (!rtm._logined) {
            result = false;
            resultTxt = 'Please Login First';
        }
        if (result) {
            return rtm.queryPeersOnlineStatus(params.memberId).then((res) => {
                    data.data = res[params.memberId];
                    return data;
                })
                .catch(function(err) {
                    data.result = false;
                    data.error = err;
                    return data;
                });
        } else {
            return new Promise(resolve => {
                data.result = false;
                data.error = resultTxt;
                resolve(data);
            });
        }
    },
    //声网消息获得UID
    getUid(str) {
        var rtnMsg = '';
        var uid = '';
        var rtnObj = {
            rst: true,
            msg: ''
        };
        if (str) {
            var uidArr = str.split('_');
            if (uidArr && uidArr.length >= 2) {
                var uidStr = uidArr[1];
                if (uidStr) {
                    rtnMsg = +uidStr;
                    uid = +uidStr;
                } else {
                    rtnObj.result = false;
                    rtnMsg = 'UID不存在';
                }
            } else {
                rtnObj.result = false;
                rtnMsg = 'accountName不符合要求';
            }
        } else {
            rtnObj.result = false;
            rtnMsg = '缺少accountName';
        }
        rtnObj.msg = rtnMsg;
        return uid;
    },
    //复制对象
    cloneObj(obj) {
        if (obj || obj === 0) {
            let newObj = [];
            newObj = JSON.parse(JSON.stringify(obj));
            return newObj;
        } else {
            return '缺少需要复制的对象！';
        }
    },
    //生成时间段
    ksDate(bd, ed, mt) {
        let bgd = new Date(bd);
        let bgdy = bgd.getFullYear();
        let bgdm = bgd.getMonth() + 1;
        let bgdd = bgd.getDate();
        let bgdh = bgd.getHours();
        let bgdf = bgd.getMinutes();
        let bgds = bgd.getSeconds();
        let edd = new Date(ed);
        let eddy = edd.getFullYear();
        let eddm = edd.getMonth() + 1;
        let eddd = edd.getDate();
        let eddh = edd.getHours();
        let eddf = edd.getMinutes();
        let edds = edd.getSeconds();
        let zbh = null;
        let zbf = null;
        if (bgdm < 10) {
            bgdm = '0' + bgdm;
        }
        if (bgdd < 10) {
            bgdd = '0' + bgdd;
        }
        if (bgdh < 10) {
            bgdh = '0' + bgdh;
        }
        if (bgdf < 10) {
            bgdf = '0' + bgdf;
        }
        if (bgds < 10) {
            bgds = '0' + bgds;
        }
        if (eddm < 10) {
            eddm = '0' + eddm;
        }
        if (eddd < 10) {
            eddd = '0' + eddd;
        }
        if (eddh < 10) {
            eddh = '0' + eddh;
        }
        if (eddf < 10) {
            eddf = '0' + eddf;
        }
        if (edds < 10) {
            edds = '0' + edds;
        }
        let backObj = {
            '准备时间': '',
            '考试时间': ''
        };
        if (mt) {
            let zbDate = new Date(bgd.getTime() - mt * 60 * 1000);
            zbh = zbDate.getHours();
            zbf = zbDate.getMinutes();
            if (zbh < 10) {
                zbh = '0' + zbh;
            }
            if (zbf < 10) {
                zbf = '0' + zbf;
            }
            let zhubei = bgdy + '/' + bgdm + '/' + bgdd + ' ' + zbh + ':' + zbf + '-' + bgdh + ':' + bgdf;
            backObj['准备时间'] = zhubei;
        }
        let kaoshi = bgdy + '/' + bgdm + '/' + bgdd + ' ' + bgdh + ':' + bgdf + '-' + eddh + ':' + eddf;
        backObj['考试时间'] = kaoshi;
        return backObj;
    },
    //整理日期
    dateMake(bd, tp) {
        let bgd = new Date(bd);
        let bgdy = bgd.getFullYear();
        let bgdm = bgd.getMonth() + 1;
        let bgdd = bgd.getDate();
        let bgdh = bgd.getHours();
        let bgdf = bgd.getMinutes();
        let bgds = bgd.getSeconds();
        if (bgdm < 10) {
            bgdm = '0' + bgdm;
        }
        if (bgdd < 10) {
            bgdd = '0' + bgdd;
        }
        if (bgdh < 10) {
            bgdh = '0' + bgdh;
        }
        if (bgdf < 10) {
            bgdf = '0' + bgdf;
        }
        if (bgds < 10) {
            bgds = '0' + bgds;
        }
        let backStr = bgdy + '-' + bgdm + '-' + bgdd + ' ' + bgdh + ':' + bgdf;
        return backStr;
    },
    //获得ID
    getId(str, idtp) {
        let id = null;
        if (str && idtp) {
            let uidArr = str.split('_');
            switch (idtp) {
                case 'stuid':
                    if (uidArr[1]) {
                        id = +uidArr[1];
                    }
                    break;
                case 'ksid':
                    if (uidArr[1]) {
                        id = +uidArr[1];
                    }
                    break;
                case 'roomid':
                    if (uidArr[2]) {
                        id = +uidArr[2];
                    }
                    break;
                case 'usrtp':
                    if (uidArr[0]) {
                        let tpStr = uidArr[0];
                        if (tpStr) {
                            if (tpStr == 'teach') {
                                id = 1;
                            } else {
                                id = 2;
                            }
                        } else {
                            id = null;
                        }

                    }
                    break;
            }
        }
        return id;
    }
}

export default commonFun