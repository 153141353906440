<template>
  <div>
    <div class="bigbox">
      <div class="import">
        <div class="text">手动创建监考房间</div>
        <div class="content">
          <div class="roomNum">
            监考房间
            <span style="font-size: 16px; font-weight: bold; color: #5b95bd"
              >{{ total }}个</span
            >
          </div>
          <div style="margin-top: 50px">
            <div class="uploadBtn">
              <span style="margin-right: 20px">监考房间导入</span>
              <el-upload
                class="upload-demo"
                action="#"
                :before-upload="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                multiple
                :limit="1"
                :on-exceed="handleExceed"
                :file-list="fileList"
              >
                <el-button class="shangchuan" size="small">导入</el-button>
              </el-upload>
              <span
                class="watchStudent"
                v-show="tableData.length"
                @click="watchStudent"
                >查看房间详情</span
              >
            </div>
          </div>
          <div class="zhuyi">
            <div>注：</div>
            <div class="point">
              <div>1、手动创建监考房间是将已经编排好的数据导入到系统中；</div>
              <div>2、可以将监考房间、监考老师编排一起导入；</div>
              <div>
                3、导入时如果只有一个名单文件，则可以只导入Excel文件，需要导入
                多个文件时则需放到一个ZIP包中导入；
              </div>
              <div>4、请按照需求选择其中一种方式进行监考房间创建。</div>
            </div>
          </div>
          <div class="upload">
            <a href="监考房间导入模板.xlsx" download="监考房间导入模板.xlsx">
              下载导入模板
            </a>
          </div>
        </div>
      </div>
      <div class="import">
        <div class="text" style="background-color: #46aebd">
          自动创建监考房间
        </div>
        <div class="content">
          <div style="margin-top: 50px">
            <div class="chuangjian">
              <div class="chuangjian_1">
                <span style="margin-right: 20px">每房间考生数量</span>
                <el-col :span="4">
                  <el-input
                    size="small"
                    v-model="form['每房间考生数']"
                  ></el-input
                ></el-col>
                人
                <span class="num">（每房间最多可分配XX人）</span>
              </div>
              <div class="chuangjian_1">
                <span style="margin-right: 20px">监考房间名称前缀</span>
                <el-col :span="13">
                  <el-input size="small" v-model="form['房间前缀']"></el-input
                ></el-col>
              </div>
              <el-button
                :disabled="tableData.length > 0 ? true : false"
                class="shangchuan"
                size="small"
                @click="yjCreate"
                >一键生成</el-button
              >
            </div>
          </div>
          <div class="zhuyi">
            <div>注：</div>
            <div class="point">
              <div>
                1、自动创建监考房间是通过系统自动生成监考房间，并将考生与监考房
                间按照所需的数量进行分配；
              </div>
              <div>
                2、监考房间名称前缀可以对自动创建的监考名称增加前缀文字，可按需
                设置（如输入的前缀文字为JK，则监考房间名称为：JKXXX)
              </div>
              <div>3、请按照需求选择其中一种方式进行监考房间创建。</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="foot">
      <el-button class="creatExam" @click="createExam('top')">上一步</el-button>
      <el-button
        class="creatExam"
        @click="createExam('bottom')"
        :disabled="total ? false : true"
        >下一步</el-button
      >
    </div>
    <el-dialog title="房间导入详情" :visible.sync="dialogVisible" width="80%">
      <div class="dialogTop">
        <div class="search">
          <el-input
            placeholder="请输入内容"
            suffix-icon="el-icon-search"
            style="width: 200px"
            size="small"
            v-model="input2"
          >
          </el-input>
        </div>
      </div>
      <div class="table">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :header-cell-style="{
            textAlign: 'center',
            background: '#5B95BD',
            fontSize: ' 16px',
            fontFamily: ' PingFang SC',
            fontWeight: 500,
            color: '#FFFFFF',
          }"
          :cell-style="{ textAlign: 'center' }"
          :row-class-name="tableRowClassName"
          style="width: 100%"
        >
          <el-table-column prop="房间名称" label="监考房间" width="120">
          </el-table-column>
          <el-table-column prop="考生人数" label="房间考生人数">
            <template slot-scope="scope">
              <span v-show="scope.row['考生人数']">{{
                scope.row["考生人数"]
              }}</span>
              <span class="notSet" v-show="!scope.row['考生人数']">未设置</span>
            </template>
          </el-table-column>
          <el-table-column prop="监考老师人数" label="房间监考人数">
            <template slot-scope="scope">
              <span v-show="scope.row['监考老师人数']">{{
                scope.row["监考老师人数"]
              }}</span>
              <span class="notSet" v-show="!scope.row['监考老师人数']"
                >未添加</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="巡考老师人数" label="房间巡考人数">
            <template slot-scope="scope">
              <span v-show="scope.row['巡考老师人数']">{{
                scope.row["巡考老师人数"]
              }}</span>
              <span class="notSet" v-show="!scope.row['巡考老师人数']"
                >未添加</span
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="footer">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[20, 50, 100]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
let that;
let import_watchUrl = "/jiankao/import_watch";
let roomUrl = "/jiankao/room";
let auto_make_room_watchUrl = "/jiankao/auto_make_room_watch";
import { getFun, postFun, deleteFun, putFun } from "../../../request/request";
export default {
  data() {
    return {
      form: {
        每房间考生数: "",
        房间前缀: "",
      },
      teacherobj: {}, //自动导入房间信息
      fileList: [],
      dialogVisible: false,
      currentPage4: 1,
      pagesize: 20,
      total: 0,
      tableData: [],
      input2: "",
      studentObj: {}, //手动导入房间信息
    };
  },
  methods: {
    watchStudent() {
      that.dialogVisible = true;
    },
    getRoomList() {
      let param = {
        批次ID: that.$route.query["批次ID"],
        机构ID: that.$route.query["机构ID"],
        考试ID: that.$route.query["考试ID"],
        状态: 1,
        最大记录数: that.pagesize,
        跳过记录数: that.pagesize * (that.currentPage4 - 1),
      };
      getFun(roomUrl, param).then((res) => {
        if (res.result && res.data) {
          that.total = res.total_records;
          that.tableData = res.data;
        }
      });
    },
    /**
     * 生成监考房间
     */
    yjCreate() {
      let param = {
        批次ID: that.$route.query["批次ID"],
        机构ID: that.$route.query["机构ID"],
        考试ID: that.$route.query["考试ID"],
        每房间考生数: that.form["每房间考生数"],
        房间前缀: that.form["房间前缀"],
      };
      // return
      postFun(auto_make_room_watchUrl, param).then((res) => {
        if (res.result && res.data) {
          let url = "https://jiankao.zhifz.com" + res.data["任务URL"];
          getFun(url).then((data) => {
            if (data.data && data.result) {
              let interval = setInterval(() => {
                getFun(url).then((data) => {
                  console.log(data);
                  if (data.data["已结束"] && data.result) {
                    that.form = {
                      每房间考生数: "",
                      房间前缀: "",
                    };
                    that.getRoomList();
                    this.$message({
                      type: "success",
                      message: "创建监考房间成功",
                    });
                    clearInterval(interval);
                  }
                });
              }, 3000);
            }
          });
        }
      });
    },
    watchXq() {
      that.dialogVisible = true;
    },
    handleSizeChange(val) {
      that.pagesize = val;
      that.getRoomList();
    },
    handleCurrentChange(val) {
      that.currentPage4 = val;
      that.getRoomList();
    },
    handleClick(row) {
      console.log(row);
    },

    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex; //为每行数据对象添加“索引”属性，在handleSelectionChange方法中会用到
      row = this.tableData;
      if (row[rowIndex].isHighLight === true) {
        return "rowHighLight"; //若isHighLight为true，则使用“rowHighLight”样式
      } else {
        return "";
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      if (!that.$route.query["批次ID"]) {
        this.$message({
          type: "error",
          message: "无批次信息，请先选择批次",
        });
        return;
      }
      let formDt = new FormData();
      formDt.append("批次ID", that.$route.query["批次ID"]);
      formDt.append("机构ID", that.$route.query["机构ID"]);
      formDt.append("考试ID", that.$route.query["考试ID"]);
      formDt.append("监考老师数据文件", file);
      formDt.append("调试模式", "ZDFGsdxcvsdf");

      postFun(import_watchUrl, formDt).then((res) => {
        if (res.result && res.data) {
          let url = "https://jiankao.zhifz.com" + res.data["任务URL"];
          let interval = setInterval(() => {
            getFun(url).then((data) => {
              console.log(data);
              if (data.data["已结束"] && data.result) {
                that.getRoomList();
                this.$message({
                  type: "success",
                  message: "导入监考房间成功",
                });
                clearInterval(interval);
              }
            });
          }, 3000);
        }
      });
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    /**
     * 导入名单
     */
    importList() {
      that.dialogVisible = true;
    },
    // 路由跳转
    createExam(val) {
      if (val == "top") {
        that.$router.push({ path: "/studentImport", query: that.$route.query });
      } else {
        that.$router.push({
          path: "/relationTeacher",
          query: that.$route.query,
        });
      }
    },
  },
  created() {
    that = this;
    that.getRoomList();
  },
};
</script>

<style scoped>
a {
  color: #5b95bd;
}
/deep/.el-icon-upload-success {
  display: none !important;
}
/deep/.el-upload-list__item .el-icon-close {
  display: none !important;
}
.notSet {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #dd1c1c;
}
.watchStudent:hover {
  color: #5b95bd;
}
.watchStudent {
  cursor: pointer;
  margin-left: 20px;
  font-size: 12px;
}
.footer {
  display: flex;
  margin-top: 15px;
  align-items: center;
  justify-content: flex-end;
}
.dialogText span {
  font-weight: bold;
}
.dialogText {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #1d394d;
}
.dialogTop {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.shuju {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #5b95bd;
  width: 90px;
  height: 30px;
  background: #eef8ff;
  border-radius: 4px;
}
.roomNum {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #1d394d;
  position: absolute;
  top: 60px;
  left: 15px;
}
.chuangjian_1 {
  display: flex;
  align-items: center;
  padding-left: 80px;
  margin-bottom: 20px;
}
.chuangjian {
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  text-align: center;
  margin: 20px 0 96px 20px;
}
.num {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
}
.shangchuan {
  width: 170px;
  height: 30px;
  background: #eef8ff;
  border-radius: 4px;
}
.creatExam {
  width: 300px;
  height: 44px;
  background: #5b95bd;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.text {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #1d394d;
  height: 50px;
  background: #5b95bd;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 50px;
  padding-left: 10px;
}
.uploadBtn {
  display: flex;
  /* align-items: center; */
  /* height: 170px; */
  line-height: 30px;
  overflow-y: hidden;
  margin-top: 80px;
  margin-bottom: 140px;
}
.upload {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #5b95bd;
  position: absolute;
  bottom: 10px;
  right: 20px;
  cursor: pointer;
}
.point div {
  margin-bottom: 5px;
}
.zhuyi {
  width: 490px;
  height: 126px;
  background: #fff8f8;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #dd1c1c;
  padding: 20px 20px 20px 20px;
  display: flex;
}
.export {
  width: 170px;
  height: 30px;
  background: #eef8ff;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #5b95bd;
  margin-left: 15px;
}
.foot {
  margin-top: 50px;
  text-align: center;
}
.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.import {
  width: 564px;
  height: 564px;
  background: #ffffff;
  border-radius: 4px;
  margin-right: 76px;
  position: relative;
}
.bigbox {
  display: flex;
}
/deep/.el-dialog__title {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin-left: 20px;
}
/deep/.el-dialog__headerbtn {
  top: 15px;
}
/deep/.el-dialog__header {
  background: #1d394d;
  height: 40px;
  padding: 0 20px;
  display: flex;
  align-items: center;
}
</style>