<template>
  <div>
    <div class="btn">
      <div class="btn_no1">
        <el-button size="small" @click="exportFun">导出Excel数据</el-button>
        <el-button size="small" @click="deleteFun">删除考生</el-button>
        <div class="selBox">
          <el-select
            v-model="value"
            @change="selStudent"
            size="small"
            placeholder="请选择"
          >
            <el-option label="全部考生" value="0" size="small"> </el-option>
            <el-option label="未关联房间考生" value="1" size="small">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="seach">
        <el-input
          placeholder="请输入内容"
          suffix-icon="el-icon-search"
          style="width: 200px"
          size="small"
          v-model="input2"
        >
        </el-input>
      </div>
    </div>
    <div>
      <el-table
        ref="multipleTable"
        id="exportTab"
        :data="tableData"
        :header-cell-style="{
          textAlign: 'center',
          background: '#5B95BD',
          fontSize: ' 16px',
          fontFamily: ' PingFang SC',
          fontWeight: 500,
          color: '#FFFFFF',
        }"
        height="600px"
        :cell-style="{ textAlign: 'center' }"
        :row-class-name="tableRowClassName"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="姓名" label="考生姓名" width="120">
          <template slot-scope="scope">
            <el-input
              size="small"
              v-model="form['姓名']"
              v-show="scope.row.isShow"
            >
            </el-input>
            <span v-show="!scope.row.isShow">{{ scope.row["姓名"] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="报名号" label="考生准考证号">
          <template slot-scope="scope">
            <el-input
              size="small"
              v-model="form['报名号']"
              v-show="scope.row.isShow"
            >
            </el-input>
            <span v-show="!scope.row.isShow">{{ scope.row["报名号"] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="证件号" label="考生身份证号">
          <template slot-scope="scope">
            <el-input
              size="small"
              v-model="form['证件号']"
              v-show="scope.row.isShow"
            >
            </el-input>
            <span v-show="!scope.row.isShow">{{ scope.row["证件号"] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="考生照片">
          <template slot-scope="scope">
            <el-button
              v-show="scope.row['照片']"
              @click="watchFun(scope.row)"
              type="text"
              size="small"
              >查看</el-button
            >
            <span v-show="!scope.row['照片']" style="color: red">未上传</span>
          </template>
        </el-table-column>
        <el-table-column prop="密码" label="考生初始密码">
          <template slot-scope="scope">
            <el-input
              size="small"
              v-model="form['密码']"
              v-show="scope.row.isShow"
            >
            </el-input>
            <span v-show="!scope.row.isShow">{{ scope.row["密码"] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="房间名称" label="监考房间"> </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button
              @click="handleClick(scope.row)"
              v-show="!scope.row.isShow"
              type="text"
              size="small"
              >修改</el-button
            >
            <el-button
              @click="submitFun(scope.row)"
              v-show="scope.row.isShow"
              type="text"
              size="small"
              >确定</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <span class="num">考生人数：{{ tableData.length }}人 </span>
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[20, 50, 100]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="25%">
      <img :src="imgUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
let that;
import { mapState, mapMutations } from "vuex";
let examineeUrl = "/jiankao/examinee";
let userUrl = "/jiankao/user";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {
  getFun,
  postFun,
  deleteFun,
  putFun,
} from "../../../../request/request";
export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        姓名: "",
        报名号: "",
        证件号: "",
        密码: "",
      },
      value: "",
      tableData: [],
      currentPage4: 1,
      pagesize: 20,
      total: 100,
      input2: "",
      isShow: false,
      imgUrl: "",
      copyList: [],
      examListArr: [],
    };
  },
  computed: {
    ...mapState(["routerName", "user"]),
  },
  watch: {
    input2: {
      handler() {
        const realList = that.copyList.filter((order) => {
          return Object.keys(that.input2).reduce((flag, item) => {
            if (!flag) {
              return false;
            } else {
              return that.input2[item].trim()
                ? String(order["姓名"]).indexOf(that.input2[item]) !== -1
                : true;
            }
          }, true);
        });
        that.tableData = realList;
      },
      deep: true,
    },
  },
  methods: {
    selStudent(val) {
      that.tableData = that.copyList.filter((item) => {
        if (val == "0") {
          return item;
        }
        if (val == "1") {
          return !item["房间ID"];
        }
      });
    },
    /**
     * 查看考生照片
     */
    watchFun(val) {
      that.dialogVisible = true;
      that.imgUrl = `https://jiankao.zhifz.com/file/show/${val["照片"]}`;
    },
    /**
     * 导出数据
     */
    exportFun() {
      if (!that.tableData.length) {
        that.$message({
          message: "当前没有可以导出的数据！",
          type: "warning",
        });
        return;
      }
      that.$common.getExcel("考生列表");
    },
    /**
     * 删除考生
     */
    deleteFun() {
      if (!that.examListArr.length) {
        this.$message({
          type: "error",
          message: "请选择要删除的考生!",
        });
        return;
      }
      let arr = [];
      that.examListArr.forEach((item) => {
        arr.push(item.UID);
      });
      let param = {
        考试ID: that.$route.query["考试ID"],
        考生: JSON.stringify(arr),
      };
      this.$confirm("此操作将删除考生, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteFun(examineeUrl, param).then((res) => {
            if (res.result) {
              that.getStudent();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    /**
     * 查询考生数据
     */
    getStudent() {
      let param = {
        考试ID: that.$route.query["考试ID"],
        批次ID: that.$route.query["批次ID"],
        机构ID: that.$route.query["机构ID"],
        最大记录数: that.pagesize,
        跳过记录数: that.pagesize * (that.currentPage4 - 1),
      };
      that.tableData = [];
      getFun(examineeUrl, param).then((res) => {
        if (res.data && res.result) {
          that.total = res.total_records;
          that.tableData = res.data;
          let arr = [];
          that.tableData.forEach((item) => {
            arr.push(item.UID);
          });
          let param = {
            UID: JSON.stringify(arr),
            用户类型: 2,
            返回照片: true,
          };
          getFun(userUrl, param).then((user) => {
            if (user.result && user.data) {
              that.tableData.forEach((item) => {
                user.data.forEach((items) => {
                  if (item.UID == items.UID) {
                    item["照片"] = items["照片"];
                  }
                });
              });
              that.$forceUpdate();
              that.copyList = JSON.parse(JSON.stringify(that.tableData));
            }
          });
        }
      });
    },
    /**
     * 修改用户确定按钮
     */
    submitFun(val) {
      val.isShow = false;
      that.$forceUpdate();
      let param = that.form;
      param['调试模式'] = 'ZDFGsdxcvsdf'
      postFun(userUrl, param).then((res) => {
        if (res.result) {
          that.getStudent();
          this.$message({
            type: "success",
            message: "修改成功!",
          });
        }
      });
    },
    /**
     * 点击修改出现输入框
     */
    handleClick(row) {
      row.isShow = true;
      that.$forceUpdate();
      that.form["姓名"] = row["姓名"];
      that.form["报名号"] = row["报名号"];
      that.form["证件号"] = row["证件号"];
      that.form["密码"] = row["密码"];
      that.form.UID = row.UID;
      that.form["用户类型"] = 2;
    },
    handleSizeChange(val) {
      that.pagesize = val;
      that.getStudent();
    },
    handleCurrentChange(val) {
      that.currentPage4 = val;
      that.getStudent();
    },
    handleSelectionChange(rows) {
      // this.selectedRowIndex = rows;
      that.examListArr = rows;
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex; //为每行数据对象添加“索引”属性，在handleSelectionChange方法中会用到
      row = this.tableData;
      if (row[rowIndex].isHighLight === true) {
        return "rowHighLight"; //若isHighLight为true，则使用“rowHighLight”样式
      } else {
        return "";
      }
    },
  },
  created() {
    that = this;
    that.getStudent();
  },
};
</script>

<style scoped>
.el-dialog__body img {
  width: 240px;
}
/deep/.el-dialog__body {
  text-align: center;
}
.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.num {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #5b95bd;
}
/deep/.seach .el-input__inner {
  background: #f6f6f6;
  border: none;
}
.btn_no1 {
  display: flex;
}
.btn {
  display: flex;
  margin: 27px 0 17px 0;
  justify-content: space-between;
}
.btn .el-button {
  height: 30px;
  background: #eef8ff;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #5b95bd;
  border: none !important;
}
.selBox {
  margin-left: 10px;
}
/deep/.selBox .el-input__inner {
  border: none !important;
  background: #eef8ff;
}
/deep/.selBox .el-select {
  width: 100px;
  height: 30px;

  border: none !important;
}
.header {
  width: 100%;
}
</style>